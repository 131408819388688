import React from 'react';

class MenuItemRazdComponent extends React.Component
{
    render()
    {
        return (
            <li>
                <hr className="dropdown-divider" />
            </li>
        );
    }
}

export default MenuItemRazdComponent;