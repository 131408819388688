import React from 'react';
import SelectComponent from '../components/selectComponent';
import Obertka from './Obertka';
import OtkrComponent from '../components/otkrComponent';
import Pole from '../class/Pole';
import SpisokComponent from '../components/spisokComponent';
import SsylkaComponent from '../components/ssylkaComponent';
import preobr_url from '../func/preobr_url';
import { Building, Buildings } from 'react-bootstrap-icons';

class PoleCorp extends Pole
{
    static pole = "corp";

    static defaultValue = {
        status: "null",
        links: []
    };

    static getValidError(db, object)
    {
        if (object[this.pole].links.some(x => x.link.trim() === ""))
        {
            return true;
        }
        return false;
    }

    render()
    {
        let spisok_znach = [
            ["null", "Нет данных (скрыто)"],
            ["prod", "Корпоративные продажи"],
            ["ogr", "Ограниченные поставки"],
            ["snyato", "Снято с производства"],
        ];
        let pole = PoleCorp.pole;
        if (this.props.redSost)
        {
            let zagolovok = <small className="fst-italic text-muted">Нет ссылок</small>;
            if (this.props.object[pole].links.length > 0)
            {
                zagolovok = <React.Fragment>Ссылок: {this.props.object[pole].links.length}</React.Fragment>;
            }
            return (
                <Obertka
                text="Корпоративная доступность"
                icon={<Buildings />}
                validError={this.constructor.getValidError(this.props.db, this.props.object)}
                {...this.props}
                >
                    <OtkrComponent
                        zagolovok={spisok_znach.find(x => x[0] === this.props.object[pole].status)[1]}
                    >
                        <SelectComponent
                            items={spisok_znach}
                            getText={x => x[1]}
                            getID={x => x[0]}
                            isSelected={x => this.props.object[pole].status === x[0]}
                            onClick={x =>
                            {
                                let spisok = this.props.object[pole];
                                spisok.status = x[0];
                                this.props.onChangePole(pole, spisok);
                                return "zakr";
                            }}
                            multiSelect={false}
                            validError={false}
                            napr="column"
                        />
                    </OtkrComponent>
                    <Obertka
                        text={"Корпоративные предложения"}
                        redStyle={true}
                        validError={this.constructor.getValidError(this.props.db, this.props.object)}
                    >
                        <OtkrComponent
                            zagolovok={zagolovok}
                            validError={this.constructor.getValidError(this.props.db, this.props.object)}
                        >
                            <SpisokComponent
                                plusClick={() =>
                                {
                                    let spisok = this.props.object[pole];
                                    spisok.links.unshift({
                                        time: Date.now(),
                                        link: "",
                                        price: "",
                                        pod_zakaz: true,
                                    });
                                    this.props.onChangePole(pole, spisok);
                                }}
                                plusText="Добавить ссылку"
                                items={this.props.object[pole].links}
                                otrisovka={(item2) =>
                                    <SsylkaComponent
                                        item={item2}
                                        key={item2.time}
                                        mode="corp"
                                        onChange={(pole2, value) =>
                                        {
                                            let spisok = this.props.object[pole];
                                            let ssylka = spisok.links.find(x => x.time === item2.time);
                                            ssylka[pole2] = value;
                                            if (pole2 === "price")
                                            {
                                                if (value === "")
                                                {
                                                    ssylka["pod_zakaz"] = true;
                                                }
                                                else
                                                {
                                                    ssylka["pod_zakaz"] = false;
                                                }
                                            }
                                            this.props.onChangePole(pole, spisok);
                                        }}
                                        onDelete={() =>
                                        {
                                            let spisok = this.props.object[pole];
                                            let ssylkaIndex = spisok.links.findIndex(x => x.time === item2.time);
                                            spisok.links.splice(ssylkaIndex, 1);
                                            this.props.onChangePole(pole, spisok);
                                        }}
                                        {...this.props}
                                    />
                                }
                                getID={(item) => item.time}
                                getText={(item) => item.link}
                            />
                        </OtkrComponent>
                    </Obertka>
                </Obertka>
            );
        }
        else
        {
            if (this.props.object[pole].status === "null")
            {
                return null;
            }
            let sortLinks = (array) => array.sort((a, b) => preobr_url(a.link).localeCompare(preobr_url(b.link)));
            let sortPrice = (array) => array.sort((a, b) => a.price - b.price);
            let sortFunction = (array) =>
            {
                return [...sortPrice(sortLinks(array.filter(x => !x.pod_zakaz))), ...sortLinks(array.filter(x => x.pod_zakaz))];
            }
            let links_array = null;
            if (this.props.object[pole].links.length > 0)
            {
                links_array = this.props.object[pole].links;
                links_array = sortFunction(links_array);
                links_array = links_array.map((item, index) =>
                    <SsylkaComponent
                        item={item}
                        key={item.time}
                        mode="corp"
                        {...this.props}
                    />
                );
            }
            let spisok_znach_prosm = {
                "prod": { "text": "Корпоративные продажи", "color": "primary" },
                "ogr": { "text": "Ограниченные поставки", "color": "warning" },
                "snyato": { "text": "Снято с производства", "color": "secondary" },
            }
            let plashka = <div className="d-flex align-items-center gap-1">
                <span className={"badge rounded-circle bg-" + (spisok_znach_prosm[this.props.object[pole].status].color)} style={{ "width": "1rem", "height": "1rem" }}>
                    <span className="visually-hidden">1</span>
                </span>
                <span className={"fs-6 fw-normal "}>
                    {spisok_znach_prosm[this.props.object[pole].status].text}
                </span>
            </div>;
            return (
                <Obertka text="Корпоративная доступность" {...this.props}>
                    {plashka}
                    {links_array}
                </Obertka>
            );
        }

    }
}

export default PoleCorp;