import React from 'react';

class TableRowComponent extends React.Component 
{
    render()
    {
        return (
            <div className="d-flex flex-column flex-md-row gap-0 col-12">
                <div
                    className="d-md-none d-flex flex-column gap-0 align-items-start col-12 p-1"
                >
                    <div
                        className="d-flex flex-row gap-1 align-items-center p-0"
                    >
                        {this.props.icon}
                        {this.props.zagolovok}
                    </div>
                    <small
                        className="d-flex flex-row gap-1 align-items-center p-0 text-muted"
                    >
                        {this.props.podpis}
                    </small>
                </div>
                <div
                    className="d-none d-md-flex flex-column gap-0 align-items-start col-4 border-end p-1"
                >
                    <div
                        className="d-flex flex-row gap-1 align-items-center p-0"
                    >
                        {this.props.icon}
                        {this.props.zagolovok}
                    </div>
                    <small
                        className="d-flex flex-row gap-1 align-items-center p-0 text-muted"
                    >
                        {this.props.podpis}
                    </small>
                </div>
                <div
                    className="d-flex col-md-8 col-12 align-items-center p-1"
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default TableRowComponent;