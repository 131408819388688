import React from "react";
import { Archive, Bag, CaretDownFill, CaretUpFill, Icon123, Eye, EyeSlash, Link45deg, Trash3, UiChecks, UiChecksGrid, UiRadios, Fonts, DashLg, PlusLg } from "react-bootstrap-icons";
import SpisokComponent from "./components/spisokComponent";
import RedVariant from "./red_variant";
import OtkrComponent from "./components/otkrComponent";
import Obertka from "./polya/Obertka";
import WarningComponent from "./components/warningComponent";
import MenuComponent from "./components/menuComponent";
import MenuItemComponent from "./components/menuItemComponent";
import MenuItemRazdComponent from "./components/menuItemRazdComponent";
import SelectComponent from "./components/selectComponent";

class RedPole extends React.Component
{
    static preobr_db(pole, group, db)
    {
        for (let i in pole.array)
        {
            let variant = pole.array[i];
            db = RedVariant.preobr_db(variant, pole, group, db);
        }
        if (pole.edizm_array === undefined)
        {
            pole.edizm_array = [];
        }
        return db;
    }

    static getDefaultEdizmValue()
    {
        return {
            name: "",
            koef: "100",
            float_numbers: "0",
            time: Date.now(),
        };
    }

    static getDefaultValue()
    {
        return {
            name: "",
            time: Date.now().toString(),
            type: "text",
            array: [],
            edizm: "",
            edizm_array: [],
            active: true,
            visible: true,
            multiSelect: false,
            float_numbers: 0,
        }
    }

    static getPoleTypeIcon(typeName)
    {
        let typeObject = this.poleTypes.find(x => x.name === typeName);
        if (typeObject === undefined)
        {
            return null;
        }
        return typeObject.icon;
    }

    static getValidError(item)
    {
        if (item.name === "")
        {
            return true;
        }
        if (this.poleTypes.find(x => x.name === item.type).getValidError(item))
        {
            return true;
        }
        return false;
    }

    static poleTypes = [
        {
            name: "array",
            text: "Список",
            icon: <UiChecksGrid />,
            edizm: false,
            float_numbers: false,
            variants: true,
            getValidError: (item) =>
            {
                if (!item.array.some(x => x.active))
                {
                    return true;
                }
                if (item.array.some(x => RedVariant.getValidError(x)))
                {
                    return true;
                }
                return false;
            },
        },
        {
            name: "number",
            text: "Число",
            icon: <Icon123 />,
            edizm: true,
            float_numbers: true,
            variants: false,
            getValidError: (item) =>
            {
                if (item.float_numbers === "")
                {
                    return true;
                }
                if (item.edizm_array.some(x => x.float_numbers === "" || x.name.trim() === "" || x.koef === ""))
                {
                    return true;
                }
                return false;
            },
        },
        {
            name: "text",
            text: "Текст",
            icon: <Fonts />,
            edizm: false,
            float_numbers: false,
            variants: false,
            getValidError: (item) =>
            {
                return false
            },
        },
        {
            name: "link",
            text: "Ссылка",
            icon: <Link45deg />,
            edizm: false,
            float_numbers: false,
            variants: false,
            getValidError: (item) =>
            {
                return false
            },
        },
        {
            name: "tovar",
            text: "Товар",
            icon: <Bag />,
            edizm: false,
            float_numbers: false,
            variants: false,
            getValidError: (item) =>
            {
                return false
            },
        },
    ];

    constructor(props)
    {
        super(props);
        this.state = {
            otkr: this.props.pole.name === "",
            showVarians: (this.props.pole.type === "array_one" || this.props.pole.type === "array_many"),
            showArchiveVarians: false,
        }
        this.onArchive = this.onArchive.bind(this);
        this.onUnArchive = this.onUnArchive.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.toggleVisible = this.toggleVisible.bind(this);
        this.onUp = this.onUp.bind(this);
        this.onDown = this.onDown.bind(this);
        this.getTypesWarn = this.getTypesWarn.bind(this);
        this.handleWheel = this.handleWheel.bind(this);
        this.inputRef = React.createRef();
    }

    componentDidMount()
    {
        if (this.inputRef && this.inputRef.current)
        {
            this.inputRef.current.addEventListener('wheel', this.handleWheel, { passive: false });
        }
    }

    componentWillUnmount()
    {
        if (this.inputRef && this.inputRef.current)
        {
            this.inputRef.current.removeEventListener('wheel', this.handleWheel);
        }
    }

    handleWheel = (e) =>
    {
        e.preventDefault(); // Отключаем изменение значения при прокрутке колесика
    }

    onUp()
    {
        let data = this.props.data;
        let v = data[this.props.group_index].polya[this.props.pole_index];
        data[this.props.group_index].polya.splice(this.props.pole_index, 1);
        data[this.props.group_index].polya.splice(this.props.pole_index - 1, 0, v);
        this.props.setData(data);
    }

    onDown()
    {
        let data = this.props.data;
        let v = data[this.props.group_index].polya[this.props.pole_index];
        data[this.props.group_index].polya.splice(this.props.pole_index, 1);
        data[this.props.group_index].polya.splice(this.props.pole_index + 1, 0, v);
        this.props.setData(data);
    }

    onArchive()
    {
        let data = this.props.data;
        let v = data[this.props.group_index].polya[this.props.pole_index];
        v.active = false;
        v.visible = true;
        data[this.props.group_index].polya.splice(this.props.pole_index, 1);
        data[this.props.group_index].polya.push(v);
        this.props.setData(data);
    }

    onDelete()
    {
        let data = this.props.data;
        data[this.props.group_index].polya.splice(this.props.pole_index, 1);
        this.props.setData(data);
    }

    onUnArchive()
    {
        let data = this.props.data;
        let v = data[this.props.group_index].polya[this.props.pole_index];
        v.active = true;
        v.visible = true;
        data[this.props.group_index].polya.splice(this.props.pole_index, 1);
        data[this.props.group_index].polya.splice(0, 0, v);
        this.props.setData(data);
    }

    toggleVisible()
    {
        let data = this.props.data;
        data[this.props.group_index].polya[this.props.pole_index].visible = !data[this.props.group_index].polya[this.props.pole_index].visible;
        this.props.setData(data);
    }

    getTypesWarn(oldPole, currentPole)
    {
        let oldType = oldPole.type;
        let currentType = currentPole.type;
        if (currentType === "text")
        {
            if (oldType === "text")
            {
                return null;
            }
            else if (oldType === "number")
            {
                return "Число будет конвертировано в текст";
            }
            else if (oldType === "array")
            {
                return "Выбранные варианты будут перечислены через запятую";
            }
            else if (oldType === "link")
            {
                return "Адрес ссылки будет указан в скобках"
            }
            else if (oldType === "tovar")
            {
                return "Останется только название"
            }
        }
        else if (currentType === "number")
        {
            if (oldType === "text")
            {
                return "Текст будет конвертирован в число при возможности";
            }
            else if (oldType === "array")
            {
                return "Выбранные варианты будут конвертированы в число при возможности";
            }
            else if (oldType === "link")
            {
                return "Адрес ссылки будет скрыт, текст будет конвертирован в число при возможности";
            }
            else if (oldType === "tovar")
            {
                return "Ссылка на товар будет скрыта, название будет конвертировано в число при возможности";
            }
            else if (oldType === "number")
            {
                if (oldPole.float_numbers === "" || currentPole.float_numbers === "")
                {
                    return null;
                }
                if (currentPole.float_numbers * 1 < oldPole.float_numbers * 1)
                {
                    return "Число будет округлено до новой точности"
                }
                return null;
            }
        }
        else if (currentType === "array")
        {
            if (oldType === "text" || oldType === "link" || oldType === "tovar")
            {
                if (currentPole.multiSelect)
                {
                    return "Из текста будут выбраны варианты при возможности";
                }
                else
                {
                    return "Текст будет конвертирован в вариант при возможности";
                }
            }
            else if (oldType === "number")
            {
                return "Число будет конвертировано в вариант при возможности";
            }
            else if (oldType === "array")
            {
                if (!currentPole.multiSelect && oldPole.multiSelect)
                {
                    return "Будет оставлен только первый выбранный вариант";
                }
                else
                {
                    return null;
                }
            }
        }
        else if (currentType === "link")
        {
            if (oldType === "tovar")
            {
                return "Связь с товаром будет конвертирована в обычную ссылку";
            }
            else if (oldType === "link")
            {
                return null;
            }
            else
            {
                return "Значение будет скрыто, потребуется корректировка";
            }
        }
        else if (currentType === "tovar")
        {
            if (oldType === "link")
            {
                return "Ссылка будет конвертирована в связь с товаром при возможности";
            }
            else if (oldType === "tovar")
            {
                return null;
            }
            else
            {
                return "Товар будет найден по названию/идентификатору при возможности";
            }
        }
        return null;
    }

    render()
    {
        let warnArchive = null;
        let warnDelete = null;
        let pole = this.props.pole;
        let oldPole = this.props.oldData.find(x => x.time === this.props.group.time) !== undefined && this.props.oldData.find(x => x.time === this.props.group.time).polya.find(x => x.time === pole.time);
        if (oldPole !== undefined)
        {
            pole.array.forEach(variant =>
            {
                let oldVariant = this.props.oldData.find(x => x.time === this.props.group.time);
                if (oldVariant !== undefined)
                {
                    oldVariant = oldVariant.polya.find(x => x.time === pole.time).array.find(x => x.time === variant.time);
                }

                if (oldVariant !== undefined)
                {
                    if (variant.active === false && oldVariant.active === true)
                    {
                        warnArchive = <WarningComponent
                            zagolovok="Вариант архивирован!"
                            text="Он будет недоступен для выбора"
                        />;
                    }
                }
            });
        };
        let typesWarn = null;
        if (this.props.oldData.find(x => x.time === this.props.group.time) !== undefined)
        {
            if (this.props.oldData.find(x => x.time === this.props.group.time).polya.find(x => x.time === this.props.pole.time) !== undefined)
            {
                let oldPole = this.props.oldData.find(x => x.time === this.props.group.time).polya.find(x => x.time === this.props.pole.time);
                let currenPole = this.props.pole;
                typesWarn = this.getTypesWarn(oldPole, currenPole);
            }
        }
        if (typesWarn !== null)
        {
            typesWarn = <WarningComponent
                zagolovok="Тип изменён, значения будут конвертированы!"
                text={typesWarn}
            />;
        }
        if (this.props.oldData.find(x => x.time === this.props.group.time) !== undefined
            && this.props.oldData.find(x => x.time === this.props.group.time).polya.find(x => x.time === this.props.pole.time) !== undefined
            && this.props.oldData.find(x => x.time === this.props.group.time).polya.find(x => x.time === this.props.pole.time).array.some(x => !this.props.pole.array.some(y => y.time === x.time)))
        {
            warnDelete = <WarningComponent
                zagolovok="Вариант удалён!"
                text="Данные будут утеряны"
            />;

        }
        let menu_active = <MenuComponent>
            <MenuItemComponent
                disabled={this.props.pole_index === 0}
                onClick={this.onUp}
            >
                <CaretUpFill /> Выше
            </MenuItemComponent>
            <MenuItemComponent
                disabled={this.props.pole_index === this.props.data[this.props.group_index].polya.filter(x => x.active).length - 1}
                onClick={this.onDown}
            >
                <CaretDownFill /> Ниже
            </MenuItemComponent>
            <MenuItemRazdComponent />
            <MenuItemComponent
                className="text-danger"
                onClick={this.onArchive}
            >
                <Archive /> Архивировать поле
            </MenuItemComponent>
        </MenuComponent>;
        let menu_archive = <MenuComponent>
            <MenuItemComponent
                onClick={this.toggleVisible}
            >
                {this.props.data[this.props.group_index].polya[this.props.pole_index].visible
                    ? <React.Fragment>
                        <EyeSlash /> Скрыть поле
                    </React.Fragment>
                    : <React.Fragment>
                        <Eye /> Показать поле
                    </React.Fragment>
                }
            </MenuItemComponent>
            <MenuItemComponent
                className="text-primary"
                onClick={this.onUnArchive}
            >
                <Archive /> Восстановить поле
            </MenuItemComponent>
            <MenuItemComponent
                className="text-danger"
                onClick={this.onDelete}
            >
                <Trash3 /> Удалить поле
            </MenuItemComponent>
        </MenuComponent>;
        let poleType = this.constructor.poleTypes.find(x => x.name === this.props.pole.type);
        let multiSelect_spisok = [
            [false, "Один вариант", <UiRadios />],
            [true, "Несколько вариантов", <UiChecks />],
        ];
        return (
            <OtkrComponent
                zagolovok={this.props.pole.name}
                icon={this.constructor.getPoleTypeIcon(this.props.pole.type)}
                icon2={this.props.pole.active ? null : (this.props.pole.visible ? <Eye /> : <EyeSlash />)}
                menu={this.props.pole.active ? menu_active : menu_archive}
                validError={this.constructor.getValidError(this.props.pole)}
            >
                {typesWarn}
                {warnDelete}
                {warnArchive}
                <Obertka
                    text="Название"
                    validError={this.props.pole.name === ""}
                    redStyle={true}
                >
                    <input type="text" className="form-control" placeholder="Название" value={this.props.pole.name} onChange={(e) =>
                    {
                        let data = this.props.data;
                        data[this.props.group_index].polya[this.props.pole_index].name = e.target.value;
                        this.props.setData(data);
                    }} />
                </Obertka>
                <Obertka
                    text="Тип"
                    redStyle={true}
                >
                    <OtkrComponent
                        zagolovok={poleType.text}
                        icon={this.constructor.getPoleTypeIcon(this.props.pole.type)}
                    >
                        <SelectComponent
                            items={this.constructor.poleTypes}
                            getText={(x) => x.text}
                            getID={(x) => x.name}
                            isSelected={(x) => x.name === this.props.pole.type}
                            multiSelect={false}
                            onClick={(item) =>
                            {
                                let data = this.props.data;
                                data[this.props.group_index].polya[this.props.pole_index].type = item.name;
                                this.props.setData(data);
                            }}
                            napr="column"
                            getIcon={(item) => this.constructor.getPoleTypeIcon(item.name)}
                        />
                    </OtkrComponent>
                </Obertka>
                {poleType.float_numbers &&
                    <Obertka
                        text="Знаков после запятой"
                        redStyle={true}
                        validError={this.props.pole.float_numbers === ""}
                    >
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Знаков после запятой"
                            value={this.props.pole.float_numbers}
                            onChange={(e) =>
                            {
                                let data = this.props.data;
                                data[this.props.group_index].polya[this.props.pole_index].float_numbers = e.target.value === "" ? e.target.value : e.target.value * 1;
                                this.props.setData(data);
                            }}
                            ref={this.inputRef}
                        />
                    </Obertka>
                }
                {poleType.edizm &&
                    <Obertka
                        text="Единица измерения"
                        redStyle={true}
                    >
                        <input type="text" className="form-control" placeholder="Единица измерения" value={this.props.pole.edizm} onChange={(e) =>
                        {
                            let data = this.props.data;
                            data[this.props.group_index].polya[this.props.pole_index].edizm = e.target.value;
                            this.props.setData(data);
                        }} />
                    </Obertka>
                }
                {poleType.edizm &&
                    <Obertka
                        text="Единицы измерения"
                        redStyle={true}
                        validError={this.props.pole.edizm_array.some(x => x.float_numbers === "" || x.name.trim() === "" || x.koef === "")}
                    >
                        <SpisokComponent
                            items={this.props.pole.edizm_array}
                            getText={edizm => edizm.name}
                            getID={edizm => edizm.time}
                            plusText="Добавить ЕИ"
                            plusClick={() =>
                            {
                                let data = this.props.data;
                                data[this.props.group_index].polya[this.props.pole_index].edizm_array.unshift(this.constructor.getDefaultEdizmValue());
                                this.props.setData(data);
                            }}
                            otrisovka={edizm => (
                                <OtkrComponent
                                    key={edizm.time}
                                    zagolovok={edizm.name + " " + edizm.koef}
                                    validError={edizm.float_numbers === "" || edizm.name.trim() === "" || edizm.koef === ""}
                                    menu={
                                        <MenuComponent>
                                            <MenuItemComponent
                                                className="text-danger"
                                                onClick={() =>
                                                {
                                                    let data = this.props.data;
                                                    data[this.props.group_index].polya[this.props.pole_index].edizm_array = data[this.props.group_index].polya[this.props.pole_index].edizm_array.filter(x => x.time !== edizm.time);
                                                    this.props.setData(data);
                                                }}
                                            >
                                                <Trash3 /> Удалить ЕИ
                                            </MenuItemComponent>
                                        </MenuComponent>
                                    }
                                >
                                    <Obertka
                                        text="Название"
                                        redStyle={true}
                                        validError={edizm.name.trim() === ""}
                                    >
                                        <input type="text" className="form-control" placeholder="Название" value={edizm.name} onChange={(e) =>
                                        {
                                            let data = this.props.data;
                                            let t_ei = data[this.props.group_index].polya[this.props.pole_index].edizm_array.find(x => x.time === edizm.time);
                                            t_ei.name = e.target.value;
                                            this.props.setData(data);
                                        }} />
                                    </Obertka>
                                    <Obertka
                                        text="Множитель"
                                        redStyle={true}
                                        validError={edizm.koef === ""}
                                    >
                                        <input
                                            type="number"
                                            step="0.0000001"
                                            className="form-control"
                                            placeholder="Множитель"
                                            value={edizm.koef}
                                            onChange={(e) =>
                                            {
                                                let data = this.props.data;
                                                let t_ei = data[this.props.group_index].polya[this.props.pole_index].edizm_array.find(x => x.time === edizm.time);
                                                t_ei.koef = e.target.value;
                                                this.props.setData(data);
                                            }}
                                            onWheel={e => e.preventDefault()}
                                        />
                                    </Obertka>
                                    <Obertka
                                        text="Знаков после запятой"
                                        redStyle={true}
                                        validError={edizm.float_numbers === ""}
                                    >
                                        <input
                                            type="number"
                                            step="1"
                                            className="form-control"
                                            placeholder="Знаков после запятой"
                                            value={edizm.float_numbers}
                                            onChange={(e) =>
                                            {
                                                let data = this.props.data;
                                                let t_ei = data[this.props.group_index].polya[this.props.pole_index].edizm_array.find(x => x.time === edizm.time);
                                                t_ei.float_numbers = e.target.value;
                                                this.props.setData(data);
                                            }}
                                            onWheel={e => e.preventDefault()}
                                        />
                                    </Obertka>
                                </OtkrComponent>
                            )}
                        />
                    </Obertka>
                }
                {poleType.variants &&
                    <React.Fragment>
                        <Obertka
                            text="Выбор"
                            redStyle={true}
                        >
                            <OtkrComponent
                                zagolovok={multiSelect_spisok.find(x => x[0] === this.props.pole.multiSelect)[1]}
                                icon={multiSelect_spisok.find(x => x[0] === this.props.pole.multiSelect)[2]}
                            >
                                <SelectComponent
                                    items={multiSelect_spisok}
                                    getText={(x) => x[1]}
                                    getID={(x) => x[0]}
                                    multiSelect={false}
                                    isSelected={x => this.props.pole.multiSelect === x[0]}
                                    onClick={(item) =>
                                    {
                                        let data = this.props.data;
                                        data[this.props.group_index].polya[this.props.pole_index].multiSelect = item[0];
                                        this.props.setData(data);
                                    }}
                                    napr="column"
                                    getIcon={(item) => item[2]}
                                />
                            </OtkrComponent>
                        </Obertka>
                        <Obertka
                            text="Варианты"
                            redStyle={true}
                            validError={this.props.pole.array.filter(x => x.active).length === 0 || this.props.pole.array.filter(x => x.active).some(x => RedVariant.getValidError(x))}
                        >
                            <OtkrComponent
                                zagolovok={"Варианты: " + this.props.pole.array.filter(x => x.active).length}
                                validError={this.props.pole.array.filter(x => x.active).length === 0 || this.props.pole.array.filter(x => x.active).some(x => RedVariant.getValidError(x))}
                            >
                                <SpisokComponent
                                    items={this.props.pole.array.filter(x => x.active)}
                                    getText={x => x.name}
                                    getID={x => x.time}
                                    plusText="Добавить вариант"
                                    plusClick={() =>
                                    {
                                        let data = this.props.data;
                                        data[this.props.group_index].polya[this.props.pole_index].array.unshift(RedVariant.getDefaultValue());
                                        this.props.setData(data);
                                    }}
                                    otrisovka={(item) =>
                                        <RedVariant
                                            key={item.time}
                                            item={item}
                                            setData={this.props.setData}
                                            data={this.props.data}
                                            group_index={this.props.group_index}
                                            pole_index={this.props.pole_index}
                                            array_index={this.props.pole.array.findIndex(x => x.time === item.time)}
                                            pole={this.props.pole}
                                            oldData={this.props.oldData}
                                            group={this.props.group}
                                            catalog={this.props.catalog}
                                        />
                                    }
                                />
                            </OtkrComponent>
                        </Obertka>
                        <OtkrComponent
                            zagolovok={this.props.pole.array.filter(x => !x.active).length === 0
                                ? <small className="text-muted fst-italic">Архивные варианты отсутствуют</small>
                                : <React.Fragment>Архивные варианты: {this.props.pole.array.filter(x => !x.active).length}</React.Fragment>
                            }
                            pusto={this.props.pole.array.every(x => x.active)}
                            validError={this.props.pole.array.filter(x => !x.active).some(x => RedVariant.getValidError(x))}
                            icon={<Archive />}
                        >
                            <SpisokComponent
                                items={this.props.pole.array.filter(x => !x.active)}
                                getText={x => x.name}
                                getID={x => x.time}
                                otrisovka={(item) =>
                                    <RedVariant
                                        key={item.time}
                                        item={item}
                                        setData={this.props.setData}
                                        data={this.props.data}
                                        group_index={this.props.group_index}
                                        pole_index={this.props.pole_index}
                                        array_index={this.props.pole.array.findIndex(x => x.time === item.time)}
                                        pole={this.props.pole}
                                        oldData={this.props.oldData}
                                        group={this.props.group}
                                        catalog={this.props.catalog}
                                    />
                                }
                            />
                        </OtkrComponent>
                    </React.Fragment>
                }
            </OtkrComponent>
        );
    }
}

export default RedPole;