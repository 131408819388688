import React from "react";
import PoleID from "../polya/PoleID";
import PoleNazv from "../polya/PoleNazv";
import PoleTimeAct from "../polya/PoleTimeAct";
import PoleShow from "../polya/PoleShow";
import PoleOpis from "../polya/PoleOpis";
import OsnovaKart from "../class/OsnovaKart";
import PoleLink from "../polya/PoleLink";
import PoleLinks from "../polya/PoleLinks";
import PoleFiles from "../polya/PoleFiles";
import PoleHar from "../polya/PoleHar";
import PoleCategory from "../polya/PoleCategory";
import PolePhoto from "../polya/PolePhoto";
import PoleFileManager from "../polya/PoleFileManager";
import PolePloshProizv from "../polya/PolePloshProizv";
import PoleProizvType from "../polya/PoleProizvType";
import PoleBrandsProizv from "../polya/PoleBrandsProizv";
import PoleIst from "../polya/PoleIst";
import PoleKomp from "../polya/PoleKomp";

class ProizvComponent extends OsnovaKart
{
    static classArray = [
        PoleID,
        PoleTimeAct,
        PoleShow,
        PoleNazv,
        PoleOpis,
        PoleLink,
        PoleLinks,
        PoleFiles,
        PoleHar,
        PoleCategory,
        PolePhoto,
        PolePloshProizv,
        PoleProizvType,
        PoleBrandsProizv,
        PoleIst,
        PoleKomp,
    ];

    render()
    {
        let props = {
            ...this.props,
            objectState: this.state,
            setObjectState: this.setObjectState
        }
        return (
            <div className="d-flex col-12 gap-1 flex-column">
                <div className="col-12 d-flex flex-row flex-wrap">
                    <div className={"d-flex col-12 col-sm-6 flex-column gap-1 px-1" + (this.props.redSost ? "" : " col-md-4")}>
                        {this.props.redSost && <PoleFileManager proizvMode={true} {...this.props} />}
                        <PolePhoto {...this.props} />
                        {this.props.redSost && <PoleFiles {...this.props} />}
                    </div>
                    <div className={"d-flex col-12 col-sm-6 flex-column gap-1 px-1" + (this.props.redSost ? "" : " col-md-8")}>
                        <PoleID {...this.props} />
                        <PoleTimeAct {...this.props} />
                        <PoleShow {...this.props} />
                        <PoleNazv {...this.props} />
                        <PoleBrandsProizv {...this.props} />
                        <PoleProizvType {...this.props} />
                        <PolePloshProizv {...this.props} />
                        <PoleLink {...this.props} />
                        <PoleLinks {...this.props} />
                        {!this.props.redSost && <PoleFiles {...this.props} />}
                    </div>
                </div>
                <div className="d-flex flex-column gap-1 col-12 px-1">
                    <PoleOpis {...this.props} />
                    <PoleCategory {...this.props} />
                    <PoleHar {...props} />
                    <PoleIst {...this.props} />
                </div>
            </div>
        );
    }

}

export default ProizvComponent;