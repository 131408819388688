import React from "react";
import * as FileSaver from 'file-saver';

class RedDB extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            file: null,
            zagr: false,
        };
        this.otpr_file = this.otpr_file.bind(this);
        this.gen_file = this.gen_file.bind(this);
    }

    async gen_file()
    {
        let a = await this.props.zapros({ "type": "gen_db_file" });
        console.log(a);
        const blob = new Blob([JSON.stringify(a)], { type: 'application/json;charset=utf-8' });
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const fileName = "База данных " + day + "." + month + "." + year + " " + hours + "." + minutes + ".бд";
        FileSaver.saveAs(blob, fileName);
    }

    async otpr_file(e)
    {
        e.preventDefault();
        async function readFile(file)
        {
            const fileReader = new FileReader();
            const arrayBuffer = await new Promise((resolve, reject) =>
            {
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.onerror = () => reject(new Error('Error reading file'));
                fileReader.readAsText(file);
            });
            return arrayBuffer;
        }
        let jsonObject = await readFile(this.state.file);
        this.setState({zagr: true });
        await this.props.zapros({ "type": "zagr_db", "object": jsonObject });
        window.location.reload();
    }

    render()
    {
        if (this.state.zagr)
        {
            return (
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Загрузка...</span>
                </div>
            );
        }
        return (
            <div className="d-flex flex-column gap-2">
                <button className="btn btn-outline-primary" onClick={this.gen_file}>
                    Скачать базу данных
                </button>
                Обновление базы данных из файла (.бд)
                <form onSubmit={this.otpr_file} className="d-flex flex-row gap-2">
                    <input type="file" accept=".бд" className="form-control" onChange={(e) => this.setState({ file: e.target.files[0] })} />
                    <button type="submit" className="btn btn-warning" disabled={this.state.file === null}>Заменить</button>
                </form>
            </div>
        );
    }
}

export default RedDB;