import React from 'react';
import Obertka from './Obertka';
import Pole from '../class/Pole';
import { CaretDownFill, CaretUpFill, Image, Images, Star, StarFill, Type, XLg } from 'react-bootstrap-icons';
import OtkrComponent from '../components/otkrComponent';
import SpisokComponent from '../components/spisokComponent';
import Photo from '../photo';
import MenuItemComponent from '../components/menuItemComponent';
import MenuComponent from '../components/menuComponent';

class PhotoInSpisokPhoto extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            otkr: false
        };
    }

    render()
    {
        if (this.props.redSost)
        {
            let photo_nazv = this.props.serverFiles[this.props.item.file].split(".").slice(1).join(".");
            if (this.props.blockUp)
            {
                photo_nazv = <React.Fragment><b>Основное</b> {photo_nazv}</React.Fragment>;
            }
            let menu =
                <MenuComponent>
                    <MenuItemComponent
                        onClick={this.props.onSuperUp}
                        disabled={this.props.blockUp}
                    >
                        <StarFill /> Сделать основным
                    </MenuItemComponent>
                    <MenuItemComponent
                        onClick={this.props.onUp}
                        disabled={this.props.blockUp}
                    >
                        <CaretUpFill /> Выше
                    </MenuItemComponent>
                    <MenuItemComponent
                        onClick={this.props.onDown}
                        disabled={this.props.blockDown}
                    >
                        <CaretDownFill /> Ниже
                    </MenuItemComponent>
                    <MenuItemComponent
                        className="text-danger"
                        onClick={this.props.onDelete}
                    >
                        <XLg /> Убрать фото
                    </MenuItemComponent>
                </MenuComponent>;
            return (
                <OtkrComponent
                    zagolovok={photo_nazv}
                    validError={false}
                    menu={menu}
                    iconPhoto={<Photo mode="brand" file={this.props.serverFiles[this.props.item.file]} adress={this.props.adress} />}
                >
                    <div className="d-flex flex-row col-12 flex-wrap">
                        <button
                            className={"d-flex btn p-0 " + (this.state.otkr ? "col-12" : "col-3")}
                            onClick={() => this.setState({ otkr: !this.state.otkr })}
                        >
                            <Photo
                                mode="brand"
                                file={this.props.serverFiles[this.props.item.file]}
                                adress={this.props.adress}
                                full={this.state.otkr === true}
                            />
                        </button>
                        <div className={"d-flex flex-column" + (this.state.otkr ? " col-12" : " col-9")}>
                            <Obertka text="Файл" redStyle={true}>
                                {this.props.serverFiles[this.props.item.file].split(".").slice(1).join(".")}
                            </Obertka>
                            <Obertka text="Подпись" redStyle={true}>
                                <input type="text" placeholder="Подпись" value={this.props.item.podpis} className="form-control" onChange={(e) => this.props.onChange("podpis", e.target.value)} />
                            </Obertka>
                            <Obertka text="Источник" redStyle={true}>
                                <input type="text" placeholder="Источник" value={this.props.item.ist} className="form-control" onChange={(e) => this.props.onChange("ist", e.target.value)} />
                            </Obertka>
                        </div>
                    </div>
                </OtkrComponent>
            );
        }
        else
        {
            return null;
        }
    }

}

class PolePhoto extends Pole
{
    static pole = "photo";

    static defaultValue = [];

    static getDefaultPhotoValue(fileID)
    {
        return {
            file: fileID,
            podpis: "",
            ist: "",
        }
    }

    static preobr_object(db, object)
    {
        object[this.pole] = object[this.pole].map(
                photo => photo.ist === undefined
                    ? {
                        ...photo,
                        ist: "",
                    }
                    : photo
            );

        return object;
    }

    render()
    {
        let pole = PolePhoto.pole;
        if ((this.props.object[pole] === undefined || this.props.object[pole].length === 0) && !this.props.redSost)
        {
            return null;
        }
        let photo = this.props.object[pole];
        if (this.props.redSost)
        {
            let zagolovok = "Фотографий: " + photo.length;
            if (photo.length === 0)
            {
                zagolovok = <small className="fst-italic text-muted">Нет фотографий</small>;
            }
            return (
                <Obertka text="Фотографии" icon={<Images />} validError={false} {...this.props}>
                    <OtkrComponent
                        zagolovok={zagolovok}
                        pusto={photo.length === 0}
                    >
                        <SpisokComponent
                            items={photo}
                            otrisovka={photoItem =>
                                <PhotoInSpisokPhoto
                                    key={photoItem.file}
                                    item={photoItem}
                                    onChange={(pole2, value) =>
                                    {
                                        let spisok = photo;
                                        let ssylka = spisok.find(x => x.file === photoItem.file);
                                        ssylka[pole2] = value;
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    onDelete={() =>
                                    {
                                        let spisok = photo;
                                        let ssylkaIndex = spisok.findIndex(x => x.file === photoItem.file);
                                        spisok.splice(ssylkaIndex, 1);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    blockDown={photo.findIndex(x => x.file === photoItem.file) === photo.length - 1}
                                    blockUp={photo.findIndex(x => x.file === photoItem.file) === 0}
                                    onUp={() =>
                                    {
                                        let spisok = photo;
                                        let ssylkaIndex = spisok.findIndex(x => x.file === photoItem.file);
                                        let file = spisok.find(x => x.file === photoItem.file);
                                        spisok.splice(ssylkaIndex, 1);
                                        spisok.splice(ssylkaIndex - 1, 0, file);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    onSuperUp={() =>
                                    {
                                        let spisok = photo;
                                        let ssylkaIndex = spisok.findIndex(x => x.file === photoItem.file);
                                        let file = spisok.find(x => x.file === photoItem.file);
                                        spisok.splice(ssylkaIndex, 1);
                                        spisok.splice(0, 0, file);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    onDown={() =>
                                    {
                                        let spisok = photo;
                                        let ssylkaIndex = spisok.findIndex(x => x.file === photoItem.file);
                                        let file = spisok.find(x => x.file === photoItem.file);
                                        spisok.splice(ssylkaIndex, 1);
                                        spisok.splice(ssylkaIndex + 1, 0, file);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    {...this.props}
                                />
                            }
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            let glav_photo = null;
            let spisok_photo = null;
            let knopka_photo = null;
            let min_kvo = photo.length > 4 ? 3 : photo.length;
            let max_kvo = photo.length;
            if (photo.length > 0)
            {
                glav_photo = <Photo mode="gallery" file={this.props.serverFiles[photo[0].file]} adress={this.props.adress} />
            }
            if (photo.length > 1)
            {
                spisok_photo = photo.slice(1, this.props.gallerySost === "all" ? max_kvo : min_kvo).map((photo, index) =>
                    <div key={index} className="p-1 col-4" style={{ "aspectRatio": "1/1" }}>
                        <Photo mode="gallery" file={this.props.serverFiles[photo.file]} adress={this.props.adress} />
                    </div>);
                if (min_kvo !== max_kvo)
                {
                    knopka_photo =
                        <div className="p-1 col-4" style={{ "aspectRatio": "1/1" }}>
                            <a href="#" className={"d-flex align-items-center justify-content-center btn fw-medium fs-1 btn " + (this.props.gallerySost === "all" ? "btn-outline-secondary" : "btn-secondary")} style={{ "width": "100%", "height": "100%" }} onClick={() => this.props.galleryToggle()}>
                                {this.props.gallerySost === "all" ? "-" : "+"}{photo.length - 3}
                            </a>
                        </div>
                }
            }
            return (
                <React.Fragment>
                    <div className="p-1 justify-content-center col-12">
                        {glav_photo}
                    </div>
                    <div className="row img-fluid m-0 col-12">
                        {spisok_photo}
                        {knopka_photo}
                    </div>
                </React.Fragment>
            );
        }

    }
}

export default PolePhoto;