import React from "react";
import MenuComponent from "../components/menuComponent";
import MenuItemComponent from "../components/menuItemComponent";
import { XLg } from "react-bootstrap-icons";
import OtkrComponent from "../components/otkrComponent";
import RedPole from "../red_pole";
import PoleHar from "./PoleHar";
import PoleTypeText from "./PoleTypeText";
import PoleTypeNumber from "./PoleTypeNumber";
import PoleTypeArray from "./PoleTypeArray";
import PoleTypeLink from "./PoleTypeLink";
import PoleTypeTovar from "./PoleTypeTovar";
import PoleKomp from "./PoleKomp";
import TableRowComponent from "../components/tableRowComponent";
import { renderToString } from "react-dom/server";

class PoleHarPole extends React.Component
{

    static getTypeClass(type)
    {
        if (type === "text")
        {
            return PoleTypeText;
        }
        else if (type === "number")
        {
            return PoleTypeNumber;
        }
        else if (type === "array")
        {
            return PoleTypeArray;
        }
        else if (type === "link")
        {
            return PoleTypeLink;
        }
        else if (type === "tovar")
        {
            return PoleTypeTovar;
        }
        else
        {
            return null;
        }
    }

    static getValidError(pole)
    {
        let poleClass = this.getTypeClass(pole.type);
        if (poleClass.getValidError(pole))
        {
            return true;
        }
        return false;
    }

    static preobr_object(db, object)
    {
        let classArray = [PoleTypeNumber, PoleTypeText, PoleTypeArray, PoleTypeLink, PoleTypeTovar];
        classArray.forEach(poleClass => object = poleClass.preobr_object(db, object));

        return object;
    }

    constructor(props)
    {
        super(props);
        this.addPole = this.addPole.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.poleClass = null;
        this.poleType = null;
        let groupID = this.props.groupID;
        let poleID = this.props.poleID;
        let pole = this.props.db.groups
            .find(group => group.time === groupID).polya
            .find(pole => pole.time === poleID);
        if (pole)
        {
            this.poleType = pole.type;
        }
        else
        {
            let poleCurrentValue = this.props.currentHar[groupID][poleID];
            this.poleType = poleCurrentValue.type
        }
        this.poleClass = this.constructor.getTypeClass(this.poleType);
        this.redRender = this.redRender.bind(this);
        this.prosmRender = this.prosmRender.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onDelete()
    {
        if (this.props.objectState.currentKomp === -1)
        {
            let data = this.props.object[PoleHar.pole];
            delete data[this.props.itemGroup.group.time][this.props.pole.time];
            this.props.onChangePole(PoleHar.pole, data);
        }
        else
        {
            let data = this.props.object[PoleKomp.pole];
            delete data.find(x => x.time === this.props.objectState.currentKomp).har[this.props.itemGroup.group.time][this.props.pole.time];
            this.props.onChangePole(PoleKomp.pole, data);
        }
    }

    addPole()
    {
        if (this.props.objectState.currentKomp === -1)
        {
            let data = this.props.object[PoleHar.pole];
            data[this.props.itemGroup.group.time][this.props.pole.time] = { value: this.poleClass.getDefaultValue(), type: this.props.pole.type };
            this.props.onChangePole(PoleHar.pole, data);
        }
        else
        {
            let data = this.props.object[PoleKomp.pole];
            data.find(x => x.time === this.props.objectState.currentKomp).har[this.props.itemGroup.group.time][this.props.pole.time] = { value: this.poleClass.getDefaultValue(), type: this.props.pole.type };
            this.props.onChangePole(PoleKomp.pole, data);
        }
    }

    onChange(value)
    {
        let data = null;
        let har = null;
        if (this.props.objectState.currentKomp === -1)
        {
            data = this.props.object[PoleHar.pole];
            har = data;
        }
        else
        {
            data = this.props.object[PoleKomp.pole];
            har = data.find(komp => komp.time === this.props.objectState.currentKomp).har;
        }
        if (value !== undefined)
        {
            if (!har[this.props.groupID])
            {
                har[this.props.groupID] = {};
            }
            if (!har[this.props.groupID][this.props.poleID])
            {
                har[this.props.groupID][this.props.poleID] = {
                    type: this.poleType,
                }
            }
            har[this.props.groupID][this.props.poleID].value = value;
        }
        else
        {
            delete har[this.props.groupID][this.props.poleID];
            if (Object.keys(har[this.props.groupID]).length === 0)
            {
                delete har[this.props.groupID];
            }
        }
        if (this.props.objectState.currentKomp === -1)
        {
            this.props.onChangePole(PoleHar.pole, data);
        }
        else
        {
            this.props.onChangePole(PoleKomp.pole, data);
        }
    }

    redRender()
    {
        let poleID = this.props.poleID;
        let groupID = this.props.groupID;
        let group = this.props.group;
        let pole = group.polya.find(pole => pole.time === poleID);
        if (!pole)
        {
            let poleType = this.props.currentHar[groupID][poleID].type;
            pole = {
                name: "Поле #" + poleID,
                time: poleID,
                type: poleType,
                visible: true,
                active: true,
                deleted: true,
                array: [],
                edizm: "",
                edizm_array: [],
                multiSelect: true,
                float_numbers: 4,
            };
        }
        else
        {
            pole.deleted = false;
        }
        let znach = undefined;
        if (this.props.currentHar[groupID])
        {
            if (this.props.currentHar[groupID][poleID])
            {
                znach = this.props.currentHar[groupID][poleID].value;
            }
        }
        let icon = RedPole.getPoleTypeIcon(pole.type);
        return (
            <TableRowComponent
                icon={icon}
                zagolovok={pole.name}
                podpis={this.poleClass.getRedPodpis(pole)}
            >
                <this.poleClass
                    pole={pole}
                    znach={znach}
                    onChange={this.onChange}
                    {...this.props}
                />
            </TableRowComponent>
        );
    }

    prosmRender()
    {
        let groupID = this.props.groupID;
        let poleID = this.props.poleID;
        let pole = this.props.db.groups
            .find(group => group.time === groupID).polya
            .find(pole => pole.time === poleID);
        if (!pole)
        {
            return null;
        }
        if (!pole.visible)
        {
            return null;
        }
        let currentHarArray = this.props.currentHarArray;
        let znachArray = currentHarArray
            .map(groupHar => groupHar[groupID])
            .filter(groupHar => groupHar !== undefined)
            .map(groupHar => groupHar[poleID])
            .filter(poleZnach => poleZnach !== undefined);
        znachArray = znachArray
            .filter((poleZnach, index) => znachArray
                .findIndex(x => JSON.stringify(x) === JSON.stringify(poleZnach)) === index
            );
        let otrisovkaZnach = <this.poleClass
            {...this.props}
            znachArray={znachArray}
            pole={pole}
        />
        if (renderToString(otrisovkaZnach).trim() === "")
        {
            return null;
        }
        return (
            <div className="d-flex flex-row p-0 col-12 col-md-6 text-break">
                <div className="d-flex flex-column col-6 p-1">
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column p-0">
                            {pole.name}
                        </div>
                        <div className="d-flex flex-grow-1 p-1">
                            <div className="d-flex border-bottom col-12">
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                    </div>
                </div>
                {otrisovkaZnach}
            </div>
        );
    }

    render()
    {
        if (!this.poleClass)
        {
            return null;
        }
        if (this.props.redSost)
        {
            return this.redRender();
        }
        else
        {
            return this.prosmRender();
        }
    }
}

export default PoleHarPole;