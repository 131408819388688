import React from "react";
import Obertka from "./Obertka";
import SelectComponent from "../components/selectComponent";
import BrandComponent from "../components/brandComponent";
import OtkrComponent from "../components/otkrComponent";
import Pole from "../class/Pole";
import PoleZavod from "./PoleZavod";
import { GeoAlt } from "react-bootstrap-icons";
import id_en_to_rus from "../func/id_en_to_rus";

class PolePlosh extends Pole
{
    static pole = "plosh";

    static defaultValue = null;

    static preobr_object(db, object)
    {
        if (object[this.pole] !== this.defaultValue)
        {
            if (object[PoleZavod.pole] !== PoleZavod.defaultValue)
            {
                let proizv = db.proizv.find(x => x._id === object[PoleZavod.pole] && x.show === true && x.proizv_type.includes("zavod"));
                if (proizv === undefined)
                {
                    object[this.pole] = this.defaultValue;
                }
                else
                {
                    let plosh = proizv.proizv_plosh.find(x => x.time === object[this.pole]);
                    if (plosh === undefined)
                    {
                        object[this.pole] = this.defaultValue;
                    }
                }
            }
            else
            {
                object[this.pole] = this.defaultValue;
            }
        }
        return object;
    }

    render()
    {
        let pole = PolePlosh.pole;
        let pole_proizv = PoleZavod.pole;
        let proizv_id = this.props.object[pole_proizv];
        let proizv = this.props.db.proizv.filter(x => x.show && x.proizv_type.includes("zavod")).find(x => x._id === proizv_id);
        let plosh_id = this.props.object[pole];
        let plosh = null;
        if (proizv !== undefined)
        {
            if (proizv.proizv_plosh.some(x => x.time === plosh_id))
            {
                plosh = proizv.proizv_plosh.find(x => x.time === plosh_id);
            }
        }
        if (plosh === null && !this.props.redSost)
        {
            return null;
        }
        if (this.props.redSost)
        {
            let zagolovok = <small className="fst-italic text-muted">Не выбрана</small>;
            if (proizv === undefined)
            {
                zagolovok = <small className="fst-italic text-muted">Не выбран производитель</small>;
            }
            if (plosh !== null)
            {
                zagolovok = plosh.nazv;
            }
            let plosh_array = [];
            if (proizv !== undefined)
            {
                plosh_array = proizv.proizv_plosh;
            }
            if (plosh_array.length === 0 && proizv !== undefined)
            {
                zagolovok = <small className="fst-italic text-muted">Нет площадок</small>;
            }
            return (
                <Obertka text="Площадка" icon={<GeoAlt />} {...this.props}>
                    <OtkrComponent
                        zagolovok={zagolovok}
                        pusto={proizv === undefined || plosh_array.length === 0}
                    >
                        <SelectComponent
                            items={plosh_array}
                            getText={(item) => item.nazv}
                            getID={(item) => item.time}
                            isSelected={((item) => this.props.object[pole] === item.time)}
                            onClick={(item) =>
                            {
                                if (this.props.object[pole] === item.time)
                                {
                                    this.props.onChangePole(pole, null);
                                }
                                else
                                {
                                    this.props.onChangePole(pole, item.time);
                                }
                            }}
                            multiSelect={false}
                            validError={false}
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            if (plosh === null)
            {
                return null;
            }
            return (
                <Obertka text="Площадка" {...this.props}>
                    <BrandComponent brand_text={plosh.nazv} link={"/производители/" + id_en_to_rus(proizv._id)} {...this.props} />
                </Obertka>
            )
        }

    }
}

export default PolePlosh;