import React from "react";
import Obertka from "./Obertka";
import PoleType from "../class/PoleType";
import PoleZnachObertka from "./PoleZnachObertka";
import InputComponent from "../components/inputComponent";

class PoleTypeText extends PoleType
{
    static getRedPodpis(pole)
    {
        return "Текст";
    }

    /*static getTextValue(pole, db)
    {
        return pole.dataInKart.value;
    }

    static getHTML(pole, db)
    {
        let rez = pole.dataInKomp
            .map(komp => komp.value);
        rez = [...new Set(rez)];
        rez = rez.toSorted((a, b) => a.localeCompare(b));
        return rez;
    }*/

    static getDefaultValue = () => "";

    static getValidError(pole)
    {
        if (pole.deleted)
        {
            return true;
        }
        if (pole.dataInKart !== undefined && pole.dataInKart !== null)
        {
            if (pole.dataInKart.value.trim() === "")
            {
                return true;
            }
        }
        else
        {
            if (pole.dataInKomp.some(x => x.value.trim() === ""))
            {
                return true;
            }
        }
        return false;
    }

    static preobr_object(db, object)
    {
        return super.preobr_object(db, object, (dbPole, objectPole) =>
        {
            if (dbPole.type === "text")
            {
                if (objectPole.type === "number")
                {
                    objectPole.value = objectPole.value.toString();
                }
                else if (objectPole.type === "array")
                {
                    objectPole.value = objectPole.value.filter(time => dbPole.array.some(x => x.time === time));
                    let currentVariantsID = objectPole.value;
                    let serverVariants = dbPole.array;
                    function variantToText(variant)
                    {
                        if (variant.type === "text")
                        {
                            return variant.name;
                        }
                        else if (variant.type === "link")
                        {
                            return variant.name + " (" + variant.link + ")";
                        }
                        else if (variant.type === "tovar")
                        {
                            let tovar = db.catalog.find(x => x._id === variant.tovar && x.show);
                            if (tovar === undefined)
                            {
                                return "";
                            }
                            else
                            {
                                return tovar.nazv;
                            }
                        }
                        else
                        {
                            return "";
                        }
                    }
                    let textVariants = currentVariantsID.map(time => variantToText(serverVariants.find(variant => variant.time === time)));
                    objectPole.value = textVariants.join(", ");
                }
                else if (objectPole.type === "link")
                {
                    objectPole.value = objectPole.value.text + " (" + objectPole.value.link + ")";
                }
                else if (objectPole.type === "tovar")
                {
                    let tovar = db.catalog.find(x => x._id === objectPole.value);
                    if (tovar === undefined)
                    {
                        objectPole.value = "";
                    }
                    else
                    {
                        objectPole.value = tovar.nazv;
                    }
                }
                objectPole.type = "text";
            }
            return object;
        });
    }

    prosmRender()
    {
        let znachArray = this.props.znachArray;
        return (
            <PoleZnachObertka
                poleClass={this.constructor}
            >
                {znachArray.map(znach => znach.value)}
            </PoleZnachObertka>
        );
    }

    redRender()
    {
        let znach = this.constructor.getDefaultValue();
        if (this.props.znach !== undefined)
        {
            znach = this.props.znach;
        }
        return (
            <div className="d-flex flex-column col-12">
                <Obertka
                    redStyle={true}
                >
                    <InputComponent
                        value={znach}
                        onChange={(value) => value === ""
                            ? this.props.onChange(undefined)
                            : this.props.onChange(value)
                        }
                        mode="text"
                    />
                </Obertka>

            </div>
        );
    }

    /*render()
    {
        return (
            <Obertka
                redStyle={true}
                validError={this.constructor.getValidError(this.props.pole)}
                text={"Текст"}
            >
                <input
                    type="text"
                    className="form-control"
                    value={this.props.currentValue}
                    onChange={e => this.props.changeValue(e.target.value)}
                />
            </Obertka>
        );
    }*/
}

export default PoleTypeText;