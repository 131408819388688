import React from "react";
import Obertka from "./Obertka";
import PoleType from "../class/PoleType";
import PoleZnachObertka from "./PoleZnachObertka";
import InputComponent from "../components/inputComponent";

class PoleTypeLink extends PoleType
{
    static getRedPodpis(pole)
    {
        return "Ссылка";
    }

    /*static getTextValue(pole, db)
    {
        return pole.dataInKart.value.text + " (" + pole.dataInKart.value.link + ")";
    }

    static getHTML(pole, db)
    {
        let rez = pole.dataInKomp
            .map(komp => komp.value);
        rez = rez.filter((objectLink, index, spisok) => spisok.findIndex(o => JSON.stringify(o) === JSON.stringify(objectLink)) === index)
        rez = rez.toSorted((a, b) => a.text.localeCompare(b.text));
        rez = rez.map(link => <a className="link" href={link.link} target="_blank" rel="noreferrer">{link.text}</a>);
        return rez;
    }*/

    static getDefaultValue = () => ({
        text: "",
        link: "",
    });

    static getValidError(pole)
    {
        if (pole.deleted)
        {
            return true;
        }
        if (pole.dataInKart !== undefined && pole.dataInKart !== null)
        {
            if (pole.dataInKart.value.text.trim() === "")
            {
                return true;
            }
            if (pole.dataInKart.value.link.trim() === "")
            {
                return true;
            }
        }
        else
        {
            if (pole.dataInKomp.some(x => x.value.text.trim() === ""))
            {
                return true;
            }
            if (pole.dataInKomp.some(x => x.value.link.trim() === ""))
            {
                return true;
            }
        }
        return false;
    }

    static preobr_object(db, object)
    {
        return super.preobr_object(db, object, (dbPole, objectPole) =>
        {
            if (dbPole.type === "link")
            {
                if (objectPole.type === "text" || objectPole.type === "number")
                {
                    objectPole.value = {
                        text: objectPole.value.toString(),
                        link: "",
                    };
                }
                else if (objectPole.type === "array")
                {
                    objectPole.value = objectPole.value.filter(time => dbPole.array.some(x => x.time === time));
                    let currentVariantsID = objectPole.value;
                    let serverVariants = dbPole.array;
                    function variantToText(variant)
                    {
                        if (variant.type === "text")
                        {
                            return variant.name;
                        }
                        else if (variant.type === "link")
                        {
                            return variant.name + " (" + variant.link + ")";
                        }
                        else if (variant.type === "tovar")
                        {
                            let tovar = db.catalog.find(x => x._id === variant.tovar && x.show);
                            if (tovar === undefined)
                            {
                                return "";
                            }
                            else
                            {
                                return tovar.nazv;
                            }
                        }
                        else
                        {
                            return "";
                        }
                    }
                    let textVariants = currentVariantsID.map(time => variantToText(serverVariants.find(variant => variant.time === time)));
                    objectPole.value = {
                        text: textVariants.join(", "),
                        link: "",
                    };
                }
                else if (objectPole.type === "tovar")
                {
                    let tovar = db.catalog.find(x => x._id === objectPole.value);
                    if (tovar === undefined)
                    {
                        objectPole.value = {
                            text: "",
                            link: "",
                        };
                    }
                    else
                    {
                        objectPole.value = {
                            text: tovar.nazv,
                            link: "/каталог/" + tovar._id,
                        };
                    }
                }
                objectPole.type = "link";
            }
            return object;
        });
    }

    constructor(props)
    {
        super(props);
        //this.changeValue = this.changeValue.bind(this);
    }

    /*changeValue(pole, value)
    {
        let t = this.props.currentValue;
        t[pole] = value;
        this.props.changeValue(t);
    }*/

    redRender()
    {
        let znach = this.constructor.getDefaultValue();
        if (this.props.znach !== undefined)
        {
            znach = this.props.znach;
        }
        return (
            <div className="d-flex flex-column col-12">
                <Obertka
                    text="Ссылка"
                    redStyle={true}
                    validError={znach.link.trim() === "" && znach.text.trim() !== ""}
                >
                    <InputComponent
                        value={znach.link}
                        onChange={(value) =>
                        {
                            znach.link = value;
                            if (znach.link === "" && znach.text === "")
                            {
                                this.props.onChange(undefined);
                            }
                            else
                            {
                                this.props.onChange(znach);
                            }
                        }}
                        mode="text"
                    />
                </Obertka>
                <Obertka
                    text="Подпись"
                    redStyle={true}
                >
                    <InputComponent
                        value={znach.text}
                        onChange={(value) =>
                        {
                            znach.text = value;
                            if (znach.link === "" && znach.text === "")
                            {
                                this.props.onChange(undefined);
                            }
                            else
                            {
                                this.props.onChange(znach);
                            }
                        }}
                        mode="text"
                    />
                </Obertka>
            </div>
        );
    }

    prosmRender()
    {
        let znachArray = this.props.znachArray;
        return (
            <PoleZnachObertka
                poleClass={this.constructor}
            >
                {znachArray.map((znach, index) =>
                    <a
                        className="link"
                        href={znach.value.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {znach.value.text}
                    </a>
                )}
            </PoleZnachObertka>
        );
    }

    /*render()
    {

        return (
            <React.Fragment>
                <Obertka
                    redStyle={true}
                    validError={this.props.currentValue.text.trim() === ""}
                    text="Текст"
                >
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.currentValue.text}
                        onChange={e => this.changeValue("text", e.target.value)}
                    />
                </Obertka>
                <Obertka
                    redStyle={true}
                    validError={this.props.currentValue.link.trim() === ""}
                    text="Ссылка"
                >
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.currentValue.link}
                        onChange={e => this.changeValue("link", e.target.value)}
                    />
                </Obertka>
            </React.Fragment>
        );
    }*/
}

export default PoleTypeLink;