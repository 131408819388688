import React from "react";
import Pole from "../class/Pole";
import OtkrComponent from "../components/otkrComponent";
import SpisokComponent from "../components/spisokComponent";
import SsylkaComponent from "../components/ssylkaComponent";
import Obertka from "./Obertka";
import { CaretDownFill, CaretUpFill, GeoAlt, Link45deg, Tags, ThreeDotsVertical, Trash3 } from "react-bootstrap-icons";
import preobr_url from "../func/preobr_url";
import MenuComponent from "../components/menuComponent";
import MenuItemComponent from "../components/menuItemComponent";
import PoleProizvType from "./PoleProizvType";
import Photo from "../photo";
import BrandComponent from "../components/brandComponent";
import WarningComponent from "../components/warningComponent";

class BrandInSpisok extends React.Component
{
    render()
    {
        if (this.props.redSost)
        {
            let menu = <MenuComponent>
                <MenuItemComponent
                    onClick={this.props.onDelete}
                    className="text-danger"
                >
                    <Trash3 /> Удалить бренд
                </MenuItemComponent>
            </MenuComponent>;
            let zagolovok = this.props.item.nazv;
            let photo = null;
            if (this.props.item.photo !== null)
            {
                photo = <Photo mode="brand" file={this.props.serverFiles[this.props.item.photo]} adress={this.props.adress} />;
            }
            return (
                <OtkrComponent
                    zagolovok={zagolovok}
                    validError={this.props.item.nazv === ""}
                    menu={menu}
                    iconPhoto={photo}
                >
                    <div className="d-flex flex-row gap-0">
                        <div className="d-flex flex-column gap-1 p-1 col-12">
                            <Obertka text="Название" redStyle={true} validError={this.props.item.nazv === ""}>
                                <input type="text" placeholder="Название" value={this.props.item.nazv} className="form-control" onChange={(e) => this.props.onChangeNazv(e.target.value)} />
                            </Obertka>
                        </div>
                    </div>
                </OtkrComponent >
            );
        }
        else
        {
            return <BrandComponent brand_text={this.props.item.nazv} brand_photo={this.props.serverFiles[this.props.item.photo]} {...this.props} className="fw-medium" />;
        }
    }
}

class PoleBrandsProizv extends Pole
{
    static pole = "proizv_brands";

    static defaultValue = [];

    static getValidError(db, object)
    {
        if (object[this.pole].some(x => x.nazv === ""))
        {
            return true;
        }
        return false;
    };

    static preobr_object(db, object)
    {
        let brands = object[this.pole];
        for (let brand_id in brands)
        {
            let brand = brands[brand_id];
            if (brand.photo !== null)
            {
                if (db.serverFiles[brand.photo] === undefined)
                {
                    brand.photo = null;
                }
            }
        }
        return object;
    }

    render()
    {
        let pole = this.constructor.pole;
        if (this.props.redSost)
        {
            let zagolovok = <small className="fst-italic text-muted">Нет брендов</small>;
            if (this.props.object[pole].length > 0)
            {
                zagolovok = <React.Fragment>Брендов: {this.props.object[pole].length}</React.Fragment>;
            }
            if (!this.props.object[PoleProizvType.pole].includes("razr"))
            {
                zagolovok = <small className="fst-italic text-muted">Некорректный тип</small>;
            }
            let warn = null;
            if (this.props.objectIsh[pole].some(x => !this.props.object[pole].some(y => y.time === x.time)))
            {
                warn = <WarningComponent
                    zagolovok="Бренд удалён!"
                    text="Данные будут утеряны"
                />;
            }
            return (
                <Obertka
                    text="Бренды"
                    icon={<Tags />}
                    validError={this.constructor.getValidError(this.props.db, this.props.object)}
                    {...this.props}
                >
                    {warn}
                    <OtkrComponent
                        zagolovok={zagolovok}
                        validError={this.constructor.getValidError(this.props.db, this.props.object)}
                        pusto={this.props.object[pole].length === 0 && !this.props.object[PoleProizvType.pole].includes("razr")}
                    >
                        <SpisokComponent
                            plusClick={() =>
                            {
                                let spisok = this.props.object[pole];
                                spisok.unshift({
                                    time: Date.now(),
                                    nazv: "",
                                    photo: null,
                                });
                                this.props.onChangePole(pole, spisok);
                            }}
                            plusText="Добавить бренд"
                            items={this.props.object[pole]}
                            otrisovka={(item2) =>
                                <BrandInSpisok
                                    item={item2}
                                    key={item2.time}
                                    onChangeNazv={value =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylka = spisok.find(x => x.time === item2.time);
                                        ssylka.nazv = value;
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    onDelete={() =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                        spisok.splice(ssylkaIndex, 1);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    {...this.props}
                                />
                            }
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            if (this.props.object[pole].length === 0)
            {
                return null;
            }
            return (
                <Obertka text="Бренды" {...this.props}>
                    {this.props.object[pole].sort((a, b) => a.nazv.localeCompare(b.nazv)).map((item, index) =>
                        <BrandInSpisok key={index} item={item} {...this.props} />
                    )}
                </Obertka>
            );
        }
    }
}

export default PoleBrandsProizv;