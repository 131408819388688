import React from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';

class MenuComponent extends React.Component
{
    render()
    {
        return (
            <div className="dropdown bg-transparent">
                <button
                    className="rounded btn btn-light d-flex bg-transparent border border-0"
                    id="groupMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <ThreeDotsVertical size="1.2rem" />
                </button>
                <ul className="dropdown-menu" aria-labelledby="groupMenu">
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

export default MenuComponent;