import React from "react";
import Obertka from "./Obertka";
import BrandComponent from "../components/brandComponent";
import SelectComponent from "../components/selectComponent";
import OtkrComponent from "../components/otkrComponent";
import Pole from "../class/Pole";
import PoleBrand from "./PoleBrand";
import { Pencil } from "react-bootstrap-icons";
import Photo from "../photo";
import id_en_to_rus from "../func/id_en_to_rus";

class PoleRazr extends Pole
{
    static pole = "razr";

    static defaultValue = null;

    static preobr_object(db, object)
    {
        if (object[this.pole] !== this.defaultValue)
        {
            let proizv = db.proizv.find(x => x._id === object[this.pole] && x.show === true && x.proizv_type.includes("razr"));
            if (proizv === undefined)
            {
                object[this.pole] = this.defaultValue;
            }
        }
        return object;
    }

    render()
    {
        let pole = PoleRazr.pole;
        let pole_brand = PoleBrand.pole;
        if ((this.props.object[pole] === undefined || this.props.object[pole] === null) && !this.props.redSost)
        {
            return null;
        }
        if (this.props.redSost)
        {
            let razr_array = this.props.db.proizv.filter(x => x.proizv_type.includes("razr") && x.show === true);
            let razr = this.props.db.proizv.find(x => x._id === this.props.object[pole]);
            let photo = null;
            if (razr?.photo !== undefined)
            {
                if (razr.photo.length > 0)
                {
                    photo = <Photo mode="brand" file={this.props.serverFiles[razr.photo[0].file]} adress={this.props.adress} />;
                }
            }
            return (
                <Obertka text="Разработчик" icon={<Pencil />} {...this.props}>
                    <OtkrComponent
                        zagolovok={razr !== undefined ? razr.nazv : <small className="fst-italic text-muted">Не выбран</small>}
                        iconPhoto={photo}
                    >
                        <SelectComponent
                            items={razr_array}
                            getText={(item) => item.nazv}
                            getID={(item) => item._id}
                            isSelected={((item) => this.props.object[pole] === item._id)}
                            onClick={(item) =>
                            {
                                if (this.props.object[pole] === item._id)
                                {
                                    this.props.onChangePole(pole, null);
                                }
                                else
                                {
                                    this.props.onChangePole(pole, item._id);
                                }
                                this.props.onChangePole(pole_brand, null);
                            }}
                            multiSelect={false}
                            validError={false}
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            let razr_object = this.props.db.proizv.find(x => x._id === this.props.object[pole]);
            let razr_photo = null;
            if (razr_object.photo !== undefined)
            {
                if (razr_object.photo.length > 0)
                {
                    razr_photo = razr_object.photo[0].file;
                    razr_photo = this.props.serverFiles[razr_photo];
                }
            }
            return (
                <Obertka text="Разработчик" {...this.props}>
                    <BrandComponent brand_text={razr_object.nazv} brand_photo={razr_photo} link={"/производители/" + id_en_to_rus(razr_object._id)} {...this.props} />
                </Obertka>
            );
        }
    }
}

export default PoleRazr;