import { Calculator } from "react-bootstrap-icons";
import Pole from "../class/Pole";
import Obertka from "./Obertka";
import PoleRoznitsa from "./PoleRoznitsa";

class PolePrice extends Pole
{
    static pole = "price";
    static defaultValue = null;
    static getNewPrice(object,komp)
    {
        let znach = null;
        let shop = object[PoleRoznitsa.pole].shop;
        let avito = object[PoleRoznitsa.pole].avito;
        if(komp !== undefined)
        {
            shop = shop.filter(x => x.komp === komp);
            avito = avito.filter(x => x.komp === komp);
        }
        if (shop.some(x => !x.pod_zakaz))
        {
            znach = shop.filter(x => !x.pod_zakaz).reduce((a, b) => a * 1 + b.price * 1, 0);
            znach = znach / shop.filter(x => !x.pod_zakaz).length;
            znach = Math.round(znach).toString();
        }
        else if (avito.some(x => !x.pod_zakaz))
        {
            znach = avito.filter(x => !x.pod_zakaz).reduce((a, b) => a * 1 + b.price * 1, 0);
            znach = znach / avito.filter(x => !x.pod_zakaz).length;
            znach = Math.round(znach).toString();
        }
        else if (shop.some(x => x.pod_zakaz) || avito.some(x => x.pod_zakaz))
        {
            znach = "pod_zakaz";
        }
        return znach;
    }
    render()
    {
        let price = this.props.object[this.constructor.pole];
        if (this.props.objectState.currentKomp !== -1)
        {
            price = this.constructor.getNewPrice(this.props.object, this.props.objectState.currentKomp);
        }
        if (price === null && !this.props.redSost)
        {
            return null;
        }
        if (price === null)
        {
            price = <small className="text-muted fst-italic">Нет предложений</small>;
        }
        else if (price === "pod_zakaz")
        {
            price = "Под заказ";
        }
        else
        {
            price = (price * 1).toLocaleString('ru-RU') + " ₽";
        }
        if (this.props.redSost)
        {
            return (
                <Obertka text="Примерная цена" icon={<Calculator />} {...this.props}>
                    {price}
                    <small className="text-muted fst-italic">
                        Рассчитывается автоматически
                    </small>
                </Obertka>
            );
        }
        else
        {
            return (
                <Obertka text="Примерная цена" {...this.props}>
                    <b className="fs-5">
                        {price}
                    </b>
                </Obertka>
            );
        }

    }
}

export default PolePrice;