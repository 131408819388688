import bootstrap from "bootstrap";
import React from "react";
import RedCat from "./red_cat";
import { Archive, CaretDownFill, CaretUpFill, CheckLg, ExclamationCircle, PlusLg, XLg } from "react-bootstrap-icons";
import OtkrComponent from "./components/otkrComponent";
import SpisokComponent from "./components/spisokComponent";
import WarningComponent from "./components/warningComponent";
import Obertka from "./polya/Obertka";

class RedCats extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
            oldData: null,
            groups: null,
        };
        this.getData = this.getData.bind(this);
        this.save = this.save.bind(this);
        this.plusClick = this.plusClick.bind(this);
        this.otrisovka = this.otrisovka.bind(this);
    }

    async getData()
    {
        const request = new Request(this.props.adress + '/getcat', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: null,
        });

        let zapros = await fetch(request);
        let spisok = await zapros.json();
        let db = { data: spisok.category, groups: spisok.groups };
        db = RedCat.preobr_db(db);
        db.oldData = JSON.parse(JSON.stringify(db.data));
        this.setState(db);
    }

    async save()
    {
        this.setState({ data: null });
        await this.props.zapros({ "type": "save_cats", "data": this.state.data })
        window.location.reload();
    }

    componentDidMount()
    {
        this.getData();
    }

    plusClick()
    {
        this.setState({
            data: [
                RedCat.getDefaultValue(),
                ...this.state.data,
            ]
        });
    }

    otrisovka(item)
    {
        return (
            <RedCat
                key={item.time}
                category={item}
                category_index={this.state.data.findIndex(x => x.time === item.time)}
                groups={this.state.groups}
                data={this.state.data}
                oldData={this.state.oldData}
                setData={(e) => this.setState({ data: e })}
            />
        );
    }

    render()
    {
        let warnArchive = null;
        let warnDelete = null;
        let data = this.state.data;
        let oldData = this.state.oldData;
        let validError = null;
        if (this.state.data !== null)
        {
            validError = this.state.data.some(x => RedCat.getValidError(x))
        }
        if (data !== undefined && oldData !== undefined && data !== null && oldData !== null)
        {
            data.forEach(group =>
            {
                let oldGroup = oldData.find(x => x.time === group.time);
                if (oldGroup !== undefined)
                {
                    if (group.active === false && oldGroup.active === true)
                    {
                        warnArchive = <WarningComponent
                            zagolovok="Категория архивирована!"
                            text="Выбор категории будет невозможен"
                        />;
                    }
                }
            });
        }
        if (data !== undefined && data !== null && oldData !== undefined && oldData !== null)
        {
            if (this.state.oldData.some(x => !this.state.data.some(y => x.time === y.time)))
            {
                warnDelete = <WarningComponent
                    zagolovok="Категория удалена!"
                    text="Она исчезнет из карточек товаров"
                />;
            }
        }
        return (
            <React.Fragment>
                {this.state.data === null &&
                    <div className="spinner-border container" role="status">
                        <span className="visually-hidden">Загрузка...</span>
                    </div>
                }
                {this.state.data !== null &&
                    <div className="d-flex flex-column gap-2">
                        <button className="btn btn-outline-primary rounded" onClick={this.save} disabled={validError}>
                            <CheckLg size="1.2rem" /> Сохранить
                        </button>
                        {warnArchive}
                        {warnDelete}
                        <Obertka
                            text="Категории"
                            redStyle={true}
                        >
                            <SpisokComponent
                                items={this.state.data.filter(x => x.active)}
                                getID={(item) => item.time}
                                getText={(item) => item.name}
                                plusText="Добавить категорию"
                                plusClick={this.plusClick}
                                otrisovka={this.otrisovka}
                            />
                            <OtkrComponent
                                icon={<Archive />}
                                zagolovok={this.state.data.some(x => !x.active) ? ("Архивные категории: " + this.state.data.filter(x => !x.active).length) : <small className="fst-italic text-muted">Архивные категории отсутствуют</small>}
                                pusto={this.state.data.every(x => x.active)}
                            >
                                <SpisokComponent
                                    items={this.state.data.filter(x => !x.active)}
                                    getID={(item) => item.time}
                                    getText={(item) => item.name}
                                    otrisovka={this.otrisovka}
                                />
                            </OtkrComponent>
                        </Obertka>
                    </div>
                }

            </React.Fragment>
        );
    }
}

export default RedCats;