import React from 'react';
import { ArrowRepeat } from 'react-bootstrap-icons';

class UprServer extends React.Component
{
    constructor(props)
    {
        super(props);
        this.zapros = this.zapros.bind(this);
    }

    async zapros()
    {
        await this.props.zapros({ "type": "restart" });
        window.location.reload();
    }

    render()
    {
        return (
            <div className="d-flex flex-column gap-2">
                <button
                    className="btn btn-danger rounded"
                    onClick={this.zapros}
                >
                    <ArrowRepeat /> Перезагрузка
                </button>
            </div>
        );
    }
}

export default UprServer;