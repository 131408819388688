import React from "react";
import { Paperclip } from "react-bootstrap-icons";

class Photo extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            big: false,
        };
    }
    render()
    {
        let photo = false;
        let formats = ["png", "jpg", "jpeg", "gif", "svg", "webp"];
        if (formats.some(x => this.props.file.split(".")[this.props.file.split(".").length - 1].toLowerCase() === x))
        {
            photo = true;
        }
        let content = <Paperclip size="2rem" className="text-secondary" />
        if (this.props.mode === "files")
        {
            if (photo)
            {
                content = <img src={this.props.adress + "/files/" + this.props.file} className="img-fluid mh-100 mw-100 overflow-hidden" />;
            }
            return (
                <button
                    onClick={e => this.setState({ big: !this.state.big })}
                    className={"d-flex bg-light rounded border align-items-center justify-content-center p-0 overflow-hidden " + (this.state.big ? "col-12" : "col-3")}
                    style={{ "aspectRatio": "1/1" }}
                    disabled={!photo}
                >
                    {content}
                </button>
            );
        }
        else if (this.props.mode === "gallery")
        {
            if (this.props.file === "")
            {
                return (
                    <div
                        className={"d-flex bg-light rounded border align-items-center justify-content-center p-0 col-12"}
                        style={{ "aspectRatio": "1/1" }}
                    >
                        <span className="visually-hidden">1</span>
                    </div>
                );
            }
            if (photo)
            {
                content = <div className="col-12 align-items-center justify-content-center d-flex rounded" style={{ aspectRatio: "1/1", width: "100%" }}>
                    <img src={this.props.adress + "/files/" + this.props.file} className="mh-100 mw-100 rounded" />
                </div>;

            }
            return (
                <a
                    href={"#" + this.props.file}
                    className={"bg-light col-12 align-items-center justify-content-center d-flex border p-1 rounded"}
                    style={{ "aspectRatio": "1/1" }}
                >
                    {content}
                </a>
            );
        }
        else if (this.props.mode === "brand")
        {
            content = <Paperclip size="2rem" className="text-secondary" />
            if (this.props.file === "")
            {
                return (
                    <div
                        className={"d-flex bg-light rounded align-items-center justify-content-center p-0 col-12 overflow-hidden border"}
                        style={{ "aspectRatio": "1/1" }}
                    >
                        <span className="visually-hidden">1</span>
                    </div>
                );
            }
            if (photo)
            {
                if (this.props.full === true)
                {
                    return (
                        <img
                            src={this.props.adress + "/files/" + this.props.file}
                            className="img-fluid rounded p-0 m-0 col-12"
                        />
                    );
                }
                content = <div className="col-12 align-items-center justify-content-center d-flex rounded" style={{ aspectRatio: "1/1", width: "100%" }}>
                    <img src={this.props.adress + "/files/" + this.props.file} className="rounded mw-100 mh-100" />
                </div>;
            }
            return (
                <a
                    className={"d-flex bg-light rounded align-items-center justify-content-center p-0 col-12 overflow-hidden border"}
                    style={{ "aspectRatio": "1/1" }}
                >
                    {content}
                    <span className="visually-hidden">1</span>
                </a>
            );
        }
        else if (this.props.mode === "brand_prosm")
        {
            content = <Paperclip size="2rem" className="text-secondary" />
            if (this.props.file === "")
            {
                return (
                    <div
                        className={"d-flex bg-light rounded align-items-center justify-content-center p-0 col-12 overflow-hidden"}
                        style={{ "aspectRatio": "1/1" }}
                    >
                        <span className="visually-hidden">1</span>
                    </div>
                );
            }
            if (photo)
            {
                content = <img src={this.props.adress + "/files/" + this.props.file} className="img-fluid rounded p-0 m-0" style={{ "objectFit": "cover", "width": "100%", "aspectRatio": "1/1", "objectPosition": "center" }} />;
            }
            return (
                <div
                    className={"d-flex bg-light rounded align-items-center justify-content-center p-0 col-12 overflow-hidden"}
                    style={{ "aspectRatio": "1/1" }}
                >
                    {content}
                    <span className="visually-hidden">1</span>
                </div>
            );
        }
        return (null);
    }
}

export default Photo;