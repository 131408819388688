import React from 'react';

class MenuItemComponent extends React.Component
{
    render()
    {
        return (
            <li>
                <button
                    className={"dropdown-item d-flex align-items-center gap-1 " + this.props.className}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                >
                    {this.props.children}
                </button>
            </li>
        );
    }
}

export default MenuItemComponent;