import React from "react";
import Obertka from "./Obertka";
import PoleType from "../class/PoleType";
import SelectComponent from "../components/selectComponent";
import id_en_to_rus from "../func/id_en_to_rus";
import { Link } from "react-router-dom";
import PoleZnachObertka from "./PoleZnachObertka";
import PoleShow from "./PoleShow";
import PoleNazv from "./PoleNazv";

class PoleTypeTovar extends PoleType
{
    static getRedPodpis(pole)
    {
        return "Товар";
    }

    static getDefaultValue = () => "";

    static getValidError(pole)
    {
        if (pole.deleted)
        {
            return true;
        }
        if (pole.dataInKart !== undefined && pole.dataInKart !== null)
        {
            if (pole.dataInKart.value.trim() === "")
            {
                return true;
            }
        }
        else
        {
            if (pole.dataInKomp.some(x => x.value.trim() === ""))
            {
                return true;
            }
        }
        return false;
    }

    static preobr_object(db, object)
    {
        return super.preobr_object(db, object, (dbPole, objectPole) =>
        {
            if (dbPole.type === "tovar")
            {
                if (objectPole.type === "tovar")
                {
                    let tovar = db.catalog.find(x => x._id === objectPole.value && x.show);
                    if (tovar === undefined)
                    {
                        objectPole.value = "";
                    }
                }
                else if (objectPole.type === "link" || objectPole.type === "number" || objectPole.type === "text")
                {
                    let text = objectPole.value.toString();
                    if (objectPole.type === "link")
                    {
                        text = objectPole.value.link;
                    }
                    let poisk = text.match(/([0-9a-fA-F]{24})/);
                    if (poisk === null)
                    {
                        let tovar = db.catalog.find(x => x._id === text || x.nazv.trim().toLowerCase() === text.trim().toLowerCase());
                        if (tovar === undefined)
                        {
                            objectPole.value = "";
                        }
                        else
                        {
                            objectPole.value = tovar._id;
                        }
                    }
                    else
                    {
                        let tovarID = poisk[1];
                        let tovar = db.catalog.find(x => x._id === tovarID);
                        if (tovar === undefined)
                        {
                            objectPole.value = "";
                        }
                        else
                        {
                            objectPole.value = tovar._id;
                        }
                    }
                }
                else if (objectPole.type === "array")
                {
                    objectPole.value = objectPole.value.filter(time => dbPole.array.some(x => x.time === time));
                    let currentVariantsID = objectPole.value;
                    let serverVariants = dbPole.array;
                    let currentVariants = currentVariantsID.map(time => serverVariants.find(variant => variant.time === time));
                    let tovarOsn = null;
                    currentVariants.forEach(element =>
                    {
                        if (element.type === "tovar")
                        {
                            let tovar = db.catalog.find(x => x._id === element.tovar);
                            if (tovar !== undefined)
                            {
                                tovarOsn = tovar;
                            }
                        }
                        else if (element.type === "text" || element.type === "link")
                        {
                            let tovar = db.catalog.find(x => x._id === element.name.trim().toLowerCase() || x.nazv.trim().toLowerCase() === element.name.trim().toLowerCase());
                            if (tovar !== undefined)
                            {
                                tovarOsn = tovar;
                            }
                        }
                    });
                    if (tovarOsn === null)
                    {
                        objectPole.value = "";
                    }
                    else
                    {
                        objectPole.value = tovarOsn._id;
                    }
                }
                objectPole.type = "tovar"
            }
            return object;
        });
    }

    redRender()
    {
        let znach = this.constructor.getDefaultValue();
        if (this.props.znach !== undefined)
        {
            znach = this.props.znach;
        }
        let pole = this.props.pole;
        let tovarArray = this.props.db.catalog.filter(tovar => tovar[PoleShow.pole]);
        return (
            <div className="d-flex flex-column col-12">
                <SelectComponent
                    items={tovarArray}
                    getText={tovar => tovar[PoleNazv.pole]}
                    getID={variant => variant._id}
                    isSelected={variant => variant._id === znach}
                    multiSelect={false}
                    onClick={tovar =>
                    {
                        if (znach === tovar._id)
                        {
                            this.props.onChange(undefined);
                        }
                        else
                        {
                            this.props.onChange(tovar._id);
                        }
                    }}
                />
            </div>
        );
    }

    prosmRender()
    {
        let znachArray = this.props.znachArray;
        let tovarArray = this.props.db.catalog
            .filter(tovar => znachArray
                .find(znach => znach.value === tovar._id)
            );
        console.log(tovarArray);
        return (
            <PoleZnachObertka
                poleClass={this.constructor}
            >
                {tovarArray.map((tovar, index) =>
                    <a
                        href={"/каталог/" + id_en_to_rus(tovar._id)}
                        key={index}
                    >
                        {tovar.nazv}
                    </a>
                )}
            </PoleZnachObertka>
        );
    }
}

export default PoleTypeTovar;