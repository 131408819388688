import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './header';
import Footer from './footer';
import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useParams, Redirect, Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import Catalog from './catalog';
import Osayte from './osayte';
import Tovar from './tovar';
import Admin from './admin';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import Vhod from './vhod';


class App extends React.Component
{
  constructor(props)
  {
    super(props);
    this.port = document.location.hostname === "localhost" ? ":4000" : "";
    this.state = {
      catalog: null,
      adress: document.location.protocol + "//" + document.location.hostname + this.port,
      sost: Cookies.get("parolHash") === undefined ? "Обычный" : "На подтверждении",
      category: null,
      groups: null,
    }
    this.zaprosTime = null;
    this.zaprosKey = this.zaprosKey.bind(this);
    this.zapros = this.zapros.bind(this);
    this.vhod = this.vhod.bind(this);
    this.vyhod = this.vyhod.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.getCatalogFiles = this.getCatalogFiles.bind(this);
  }

  async getCatalogFiles()
  {
    let z = await fetch(this.state.adress + "/get_catalog");
    z = await z.json()
    return z;
  }

  async uploadFiles(files)
  {
    console.log(files);
    async function readFile(file)
    {
      const fileReader = new FileReader();
      const arrayBuffer = await new Promise((resolve, reject) =>
      {
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = () => reject(new Error('Error reading file'));
        fileReader.readAsArrayBuffer(file);
      });
      const byteString = Array.prototype.map.call(new Uint8Array(arrayBuffer), (item) => ('00' + item.toString(16)).slice(-2)).join('');
      return byteString;
    }
    let byteStrings = [];
    for (const file of files)
    {
      byteStrings.push({ bytes: await readFile(file), name: file.name });
    }
    let otvet = await this.zapros({ type: "upload_files", data: byteStrings });
    return otvet;
  }

  async vhod(parol)
  {
    let parolHash = CryptoJS.SHA256(parol).toString(CryptoJS.enc.Hex);
    Cookies.set("parolHash", parolHash);
    this.zapros(null);
  }
  async vyhod()
  {
    console.log("Выходим");
    Cookies.remove("parolHash");
    this.setState({ sost: "Обычный" });
    window.location.href = "/";
  }
  async zapros(deystv = null)
  {
    let serverKey = await this.zaprosKey();
    let parolHash = Cookies.get("parolHash");
    let klyuch = parolHash + serverKey;
    let klyuchHash = CryptoJS.SHA256(klyuch).toString(CryptoJS.enc.Hex);
    let podpis = CryptoJS.SHA256(klyuchHash + JSON.stringify(deystv)).toString(CryptoJS.enc.Hex);
    const request = new Request(this.state.adress + '/zapros', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ d: deystv, podpis: podpis }),
    });
    let otvet = await fetch(request);
    otvet = await otvet.json();
    if (otvet.auth)
    {
      if (this.state.sost !== "Админ")
      {
        this.setState({ sost: "Админ" });
      }
    }
    else
    {
      if (this.state.sost !== "Не админ")
      {
        this.setState({ sost: "Не админ" });
      };
    }
    return otvet.rez;
  }

  async zaprosKey()
  {
    const request = new Request(this.state.adress + '/keygen', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: null,
    });

    let currentKey = await fetch(request);
    currentKey = await currentKey.text();
    return currentKey;
  }

  componentDidMount()
  {

    let razr = false;
    if (this.zaprosTime === null)
    {
      razr = true;
    }
    else if (Math.floor(Date.now() / 1000) - this.zaprosTime > 1)
    {
      razr = true;
    }
    if (razr)
    {
      this.zaprosTime = Math.floor(Date.now() / 1000);
      if (this.state.sost === "На подтверждении")
      {
        this.zapros(null);
      }
    }
    fetch(this.state.adress + "/getDB")
      .then(response => response.json())
      .then((x) => this.setState(x))
      .catch((e) => console.log(e));
  }
  render()
  {
    let vhod = false;
    if (this.state.sost === "Не админ")
    {
      vhod = true;
    }
    if (this.state.sost === "Обычный" && decodeURIComponent(document.location.pathname.replace(/\//g, '')).includes("админка"))
    {
      vhod = true;
    }
    let adminSost = this.state.sost === "Админ";

    return (
      <div className="d-flex col-12 flex-column mw-100 gap-2">
        <Header admin={adminSost} />
        {vhod
          ? <Vhod vhod={this.vhod} vyhod={this.vyhod} />
          : this.state.catalog !== null
            ? <Routes>
              <Route path='/' element={<Navigate to="/каталог/" />} />
              <Route path='/каталог' element={
                <Catalog
                  kart="tovar"
                  admin={adminSost}
                  adress={this.state.adress}
                  catalog={this.state.catalog}
                  url={this.queryParameters}
                  serverFiles={this.state.serverFiles}
                />
              } end />
              <Route path='/производители' element={
                <Catalog
                  kart="tovar"
                  admin={adminSost}
                  adress={this.state.adress}
                  catalog={this.state.catalog_proizv}
                  url={this.queryParameters}
                  serverFiles={this.state.serverFiles}
                />
              } end />
              <Route path='/o_sayte' Component={Osayte} />
              <Route path='/админка' Component={() =>
                <Admin
                  adress={this.state.adress}
                  vyhod={this.vyhod}
                  zapros={this.zapros}
                  link={{ vkladka: "старт" }}
                  category={this.state.category}
                  groups={this.state.groups}
                  catalog={this.state.catalog}
                  catalog_proizv={this.state.catalog_proizv}
                  uploadFiles={this.uploadFiles}
                  admin={adminSost}
                />
              } />
              <Route path='/админка/:vkladka' Component={() =>
                <Admin
                  adress={this.state.adress}
                  vyhod={this.vyhod}
                  zapros={this.zapros}
                  link={useParams()}
                  category={this.state.category}
                  groups={this.state.groups}
                  catalog={this.state.catalog}
                  catalog_proizv={this.state.catalog_proizv}
                  uploadFiles={this.uploadFiles}
                  admin={adminSost}
                />
              } />
              <Route path='/каталог/:nomer' Component={() =>
              {
                return (<Tovar
                  getCatalogFiles={this.getCatalogFiles}
                  admin={adminSost}
                  props={useParams()}
                  navigate={useNavigate()}
                  catalog={this.state.catalog}
                  catalog_proizv={this.state.catalog_proizv}
                  zapros={this.zapros}
                  uploadFiles={this.uploadFiles}
                  adress={this.state.adress}
                  category={this.state.category}
                  groups={this.state.groups}
                  kart="tovar"
                  nazad="/каталог/"
                  nazadText="Каталог"
                  serverFiles={this.state.serverFiles}
                />)
              }}
              />
              <Route path='/производители/:nomer' Component={() =>
              {
                return (<Tovar
                  getCatalogFiles={this.getCatalogFiles}
                  admin={adminSost}
                  props={useParams()}
                  navigate={useNavigate()}
                  catalog={this.state.catalog_proizv}
                  catalog_proizv={this.state.catalog_proizv}
                  zapros={this.zapros}
                  uploadFiles={this.uploadFiles}
                  adress={this.state.adress}
                  category={this.state.category}
                  groups={this.state.groups}
                  kart="proizv"
                  nazad="/производители/"
                  nazadText="Производители"
                  serverFiles={this.state.serverFiles}
                />)
              }}
              />
            </Routes>
            : <main className="col-12 justify-content-center d-flex">
              Загрузка...
            </main>
        }
        <Footer />
      </div>
    )

  }

}

export default App;
