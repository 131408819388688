import React from "react";
import { Bag, ChevronDown, ChevronUp } from "react-bootstrap-icons";

class OtkrComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            sost: this.props.validError === true ? "otkr" : "zakr",
        }
    }
    componentDidUpdate(prevProps)
    {
        if (this.props.validError === true && this.state.sost !== "otkr")
        {
            this.setState({ sost: "otkr" });
        }
    }
    render()
    {
        let verh = null;
        let iconPhoto = null;
        if(this.props.iconPhoto !== undefined && this.props.iconPhoto !== null && this.props.iconPhoto !== "")
        {
             iconPhoto = <div className="d-flex flex-column col-1">
                {this.props.iconPhoto}
             </div>;
        }
        if (this.props.name !== undefined && this.props.name !== null && this.props.name !== "")
        {
            verh =
                <div className="d-flex flex-row gap-0 align-items-center p-0 border-bottom">
                    <div className="d-flex flex-row gap-1 px-2 py-1 align-items-center flex-grow-1">
                        {this.props.icon !== undefined && this.props.icon !== null && this.props.icon}
                        {this.props.icon2 !== undefined && this.props.icon2 !== null && this.props.icon2}
                        {this.props.icon3 !== undefined && this.props.icon3 !== null && this.props.icon3}
                        {verh === null && this.props.iconPhoto !== undefined && this.props.iconPhoto !== null && iconPhoto}
                        <small className="fw-regular align-items-center">
                            {this.props.name !== undefined && this.props.name}
                        </small>
                    </div>
                    {this.props.menu !== undefined && this.props.menu}
                </div>;
        }
        let niz =
            <div className="d-flex flex-row gap-1 align-items-center rounded">
                <button
                    className={"d-flex flex-grow-1 btn align-items-center p-1 text-truncate gap-1 m-1" + (this.state.sost === "otkr" ? "rounded-bottom-1 " : "rounded-bottom-1 ") + (verh !== null ? "rounded-top-0 " : "rounded-top-1 ") + (this.props.validError ? " bg-warning-subtle disabled border-0" : " bg-white") + (this.props.pusto === true ? " disabled border border-0" : "")}
                    onClick={() => (this.props.validError === true || this.props.pusto === true) ? null : this.setState({ sost: this.state.sost === "otkr" ? "zakr" : "otkr" })}
                >
                    {verh === null && this.props.icon !== undefined && this.props.icon !== null && this.props.icon}
                    {verh === null && this.props.icon2 !== undefined && this.props.icon2 !== null && this.props.icon2}
                    {verh === null && this.props.icon3 !== undefined && this.props.icon3 !== null && this.props.icon3}
                    {verh === null && this.props.iconPhoto !== undefined && this.props.iconPhoto !== null && iconPhoto}
                    <div className="d-flex align-items-center text-truncate flex-row gap-0 px-1 flex-grow-1">
                        <div className="d-inline-block  flex-grow-1 align-items-center text-truncate justify-content-start text-start">
                            {this.props.zagolovok}
                        </div>
                        <div className="d-flex align-items-center px-1">
                            {((this.state.sost === "otkr" || this.props.validError === true) && this.props.pusto !== true) ? <ChevronUp size="1.2rem" /> : <ChevronDown size="1.2rem" />}
                        </div>
                    </div>
                </button>
                {verh === null && this.props.menu}
            </div>;
        let niz_bez_otkr =
            <div className="d-flex flex-row gap-1 align-items-center rounded">
                <div
                    className={"d-flex flex-grow-1 align-items-center rounded p-1 gap-1 m-1" + (this.state.sost === "otkr" ? "rounded-bottom-1 " : "rounded-bottom-1 ") + (verh !== null ? "rounded-top-0 " : "rounded-top-1 ") + (this.props.validError ? " bg-warning-subtle disabled border-0" : " bg-white")}
                >
                    {verh === null && this.props.icon !== undefined && this.props.icon !== null && this.props.icon}
                    {verh === null && this.props.icon2 !== undefined && this.props.icon2 !== null && this.props.icon2}
                    {verh === null && this.props.icon3 !== undefined && this.props.icon3 !== null && this.props.icon3}
                    {verh === null && this.props.iconPhoto !== undefined && this.props.iconPhoto !== null && iconPhoto}
                    <div className="d-flex align-items-center flex-row gap-0 flex-grow-1">
                        <div className="d-inline-block  flex-grow-1 align-items-center text-truncate justify-content-start text-start">
                            {this.props.zagolovok}
                        </div>
                    </div>
                </div>
                {verh === null && this.props.menu}
            </div>;
        return (
            <div className={"d-flex flex-column col-12 rounded border p-0 gap-0"}>
                <div className={"d-flex flex-column rounded bg-warning " + (this.props.validError ? "bg-warning-subtle" : "bg-white")}>
                    {verh}
                    {this.props.bez_otkr === true ? niz_bez_otkr : niz}
                </div>
                {((this.state.sost === "otkr" && this.props.pusto !== true) || (this.props.validError === true && this.props.pusto !== true)) &&
                    <div className="d-flex flex-column rounded-bottom p-1 gap-1 bg-white">
                        {this.props.children}
                    </div>
                }
            </div>
        );
    }
}

export default OtkrComponent;