import React from "react";
import { Bag, Building, Buildings, CheckSquare, Person, Square, ThreeDotsVertical, Trash3 } from "react-bootstrap-icons";
import OtkrComponent from "./otkrComponent";
import Obertka from "../polya/Obertka";
import preobr_url from "../func/preobr_url";
import SelectComponent from "./selectComponent";
import PoleKomp from "../polya/PoleKomp";

class SsylkaComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.handleWheel = this.handleWheel.bind(this);
        this.inputRef = React.createRef();
    }

    componentDidMount()
    {
        if (this.inputRef && this.inputRef.current)
        {
            this.inputRef.current.addEventListener('wheel', this.handleWheel, { passive: false });
        }
    }

    componentWillUnmount()
    {
        if (this.inputRef && this.inputRef.current)
        {
            this.inputRef.current.removeEventListener('wheel', this.handleWheel);
        }
    }

    handleWheel = (e) =>
    {
        e.preventDefault(); // Отключаем изменение значения при прокрутке колесика
    }

    render()
    {
        if (this.props.redSost)
        {
            let menu = <div className="dropdown">
                <button
                    className="rounded btn btn-light d-flex bg-transparent"
                    id="groupMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <ThreeDotsVertical size="1.2rem" />
                </button>
                <ul className="dropdown-menu" aria-labelledby="groupMenu">
                    <li>
                        <button
                            className="dropdown-item text-danger"
                            onClick={this.props.onDelete}
                        >
                            <Trash3 /> Удалить ссылку
                        </button>
                    </li>
                </ul>
            </div>;
            let zagolovok = <small className="fst-italic text-muted">Нет данных</small>
            if (this.props.item.link !== "" && (this.props.item.price !== "" || this.props.item.pod_zakaz === true))
            {
                let price = "";
                if (this.props.item.pod_zakaz)
                {
                    price = <i>Под заказ</i>;
                }
                else
                {
                    price = (this.props.item.price * 1).toLocaleString('ru-RU') + " ₽";
                }
                zagolovok = <React.Fragment>{price} {this.props.item.link}</React.Fragment>
            }
            let icon = null;
            if (this.props.mode === "shop")
            {
                icon = <Bag />;
            }
            else if (this.props.mode === "corp")
            {
                icon = <Building />;
            }
            else if (this.props.mode === "avito")
            {
                icon = <Person />;
            }
            let zagolovok_komp = null;
            let komps = this.props.object[PoleKomp.pole].filter(x => x.time !== -1);
            if (this.props.item.komp === null)
            {
                zagolovok_komp = <small className="fst-italic text-muted">Не выбрана</small>
            }
            else
            {
                zagolovok_komp = komps.find(x => x.time === this.props.item.komp).text;
            }
            return (
                <OtkrComponent
                    zagolovok={zagolovok}
                    validError={this.props.item.link === "" || (this.props.item.price === "" && !this.props.item.pod_zakaz)}
                    menu={menu}
                    icon={icon}
                >
                    <div className="d-flex flex-row gap-0 col-12">
                        <div className="d-flex flex-column gap-2 col-6 p-1">
                            <Obertka text="Ссылка" redStyle={true} validError={this.props.item.link === ""}>
                                <input type="text" placeholder="Ссылка" value={this.props.item.link} className="form-control" onChange={(e) => this.props.onChange("link", e.target.value)} />
                            </Obertka>
                        </div>
                        <div className="d-flex flex-column gap-2 col-6 p-1">
                            <Obertka text="Цена" redStyle={true} validError={this.props.item.price === "" && !this.props.item.pod_zakaz}>
                                <input
                                    type="number"
                                    placeholder="Цена"
                                    value={this.props.item.price}
                                    className="form-control"
                                    onChange={(e) => this.props.onChange("price", e.target.value)}
                                    ref={this.inputRef}
                                />
                                <div className="d-flex flex-row gap-2 align-items-center">
                                    {this.props.item.pod_zakaz ? <CheckSquare /> : <Square />} Под заказ
                                </div>
                            </Obertka>
                        </div>
                    </div>
                    {(this.props.mode === "avito" || this.props.mode === "shop") &&
                        <Obertka text="Комплектация" redStyle={true}>
                            <OtkrComponent
                                zagolovok={zagolovok_komp}
                            >
                                <SelectComponent
                                    items={komps.toSorted((a, b) => a.text.localeCompare(b.text))}
                                    isSelected={x => x.time === this.props.item.komp}
                                    getText={x => x.text}
                                    getID={x => x.time}
                                    multiSelect={false}
                                    validError={false}
                                    onClick={x =>
                                    {
                                        if (this.props.item.komp === x.time)
                                        {
                                            this.props.onChange("komp", null);
                                        }
                                        else
                                        {
                                            this.props.onChange("komp", x.time);
                                        }
                                    }}
                                />
                            </OtkrComponent>
                        </Obertka>
                    }
                </OtkrComponent>
            );
        }
        else
        {
            let icon = null;
            if (this.props.mode === "shop")
            {
                icon = <Bag />;
            }
            else if (this.props.mode === "corp")
            {
                icon = <Buildings />;
            } else if (this.props.mode === "avito")
            {
                icon = <Person />;
            }
            let price = null;
            if (this.props.item.pod_zakaz)
            {
                price = <i className="text-muted">Под заказ</i>;
            }
            else
            {
                price = <b>{(this.props.item.price * 1).toLocaleString('ru-RU')} ₽</b>;
            }
            let komp = null;
            if (this.props.item.komp !== undefined && this.props.item.komp !== null)
            {
                let komps = this.props.object[PoleKomp.pole].filter(x => x.time !== -1);
                if (komps.find(x => x.time === this.props.item.komp) !== undefined)
                {
                    komp = <small>({komps.find(x => x.time === this.props.item.komp).text})</small>;
                }
            }
            let link = <a href={this.props.item.link} className="link" target="_blank" rel="noreferrer">{preobr_url(this.props.item.link)}</a>;
            return (
                <div className="d-flex flex-row align-items-center gap-2">
                    {icon} {price} {link} {komp}
                </div>
            );
        }
    }
}

export default SsylkaComponent;