import React from "react";
import PoleID from "../polya/PoleID";
import PoleNazv from "../polya/PoleNazv";
import PoleTimeAct from "../polya/PoleTimeAct";
import PoleShow from "../polya/PoleShow";
import PoleRazr from "../polya/PoleRazr";
import PoleBrand from "../polya/PoleBrand";
import PoleZavod from "../polya/PoleZavod";
import PolePlosh from "../polya/PolePlosh";
import PoleRoznitsa from "../polya/PoleRoznitsa";
import PoleCorp from "../polya/PoleCorp";
import PoleOpis from "../polya/PoleOpis";
import OsnovaKart from "../class/OsnovaKart";
import PolePrice from "../polya/PolePrice";
import PoleLink from "../polya/PoleLink";
import PoleLinks from "../polya/PoleLinks";
import PoleFiles from "../polya/PoleFiles";
import PoleHar from "../polya/PoleHar";
import PoleCategory from "../polya/PoleCategory";
import PolePhoto from "../polya/PolePhoto";
import PoleFileManager from "../polya/PoleFileManager";
import PoleIst from "../polya/PoleIst";
import PoleKomp from "../polya/PoleKomp";

class TovarComponent extends OsnovaKart
{
    static classArray = [
        PoleID,
        PoleTimeAct,
        PoleShow,
        PoleNazv,
        PoleRazr,
        PoleBrand,
        PolePlosh,
        PoleZavod,
        PoleRoznitsa,
        PoleCorp,
        PoleOpis,
        PolePrice,
        PoleLink,
        PoleLinks,
        PoleFiles,
        PoleHar,
        PoleCategory,
        PolePhoto,
        PoleIst,
        PoleKomp,
    ];

    render()
    {
        let props = {
            ...this.props,
            objectState: this.state,
            setObjectState: this.setObjectState
        }
        return (
            <div className="d-flex col-12 gap-1 flex-column">
                <div className="col-12 d-flex flex-row flex-wrap">
                    <div className={"d-flex col-12 col-sm-6 flex-column gap-1 px-1" + (this.props.redSost ? "" : " col-md-4")}>
                        {this.props.redSost && <PoleFileManager {...this.props} />}
                        <PolePhoto {...this.props} />
                        {this.props.redSost && <PoleFiles {...this.props} />}
                    </div>
                    <div className={"d-flex col-12 col-sm-6 flex-column gap-1 px-1" + (this.props.redSost ? "" : " col-md-8")}>
                        <PoleID {...this.props} />
                        <PoleTimeAct {...this.props} />
                        <PoleShow {...this.props} />
                        <PoleNazv {...this.props} />
                        <div className={"col-12 d-flex " + (this.props.redSost ? "flex-column gap-1" : "flex-row flex-wrap")}>
                            {(this.props.redSost || this.props.object[PoleBrand.pole] !== PoleBrand.defaultValue) &&
                                <div className={"d-flex flex-column " + (this.props.redSost ? "col-12" : "col-12 col-md-6")}>
                                    <PoleBrand {...this.props} />
                                </div>
                            }
                            <div className={"d-flex flex-column " + (this.props.redSost ? "col-12" : "col-12 col-md-6")}>
                                <PoleRazr {...this.props} />
                            </div>
                        </div>
                        <div className={"col-12 d-flex " + (this.props.redSost ? "flex-column gap-1" : "flex-row flex-wrap")}>
                            {(this.props.redSost || this.props.object[PolePlosh.pole] !== PolePlosh.defaultValue) &&
                                <div className={"d-flex flex-column " + (this.props.redSost ? "col-12" : "col-12 col-md-6")}>
                                    <PolePlosh {...this.props} />
                                </div>
                            }
                            <div className={"d-flex flex-column " + (this.props.redSost ? "col-12" : "col-12 col-md-6")}>
                                <PoleZavod {...this.props} />
                            </div>
                        </div>
                        <PolePrice {...props} />
                        <div className={"col-12 d-flex " + (this.props.redSost ? "flex-column gap-1" : "flex-row flex-wrap")}>
                            <div className={"d-flex flex-column " + (this.props.redSost ? "col-12" : "col-12 col-md-6")}>
                                <PoleRoznitsa {...props} />
                            </div>
                            <div className={"d-flex flex-column " + (this.props.redSost ? "col-12" : "col-12 col-md-6")}>
                                <PoleCorp {...this.props} />
                            </div>
                        </div>
                        <PoleLink {...this.props} />
                        <PoleLinks {...this.props} />
                        {!this.props.redSost && <PoleFiles {...this.props} />}
                        <div className="d-none d-xl-block">
                            <PoleOpis {...this.props} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column gap-1 col-12 px-1">
                    <div className="d-xl-none">
                        <PoleOpis {...this.props} />
                    </div>
                    <PoleCategory {...this.props} />
                    <PoleKomp {...props} />
                    <PoleHar {...props} />
                    <PoleIst {...this.props} />
                </div>
            </div>
        );
    }

}

export default TovarComponent;