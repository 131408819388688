let array = {
    'ю': 'q',
    'э': 'w',
    'е': 'e',
    'р': 'r',
    'т': 't',
    'й': 'y',
    'у': 'u',
    'и': 'i',
    'о': 'o',
    'п': 'p',
    'а': 'a',
    'с': 's',
    'д': 'd',
    'ф': 'f',
    'г': 'g',
    'х': 'h',
    'к': 'k',
    'л': 'l',
    'з': 'z',
    'ы': 'x',
    'ц': 'c',
    'в': 'v',
    'б': 'b',
    'н': 'n',
    'м': 'm'
};

const id_rus_to_en = id =>
{
    let rez = "";
    for (let i in id)
    {
        let z = array[id[i]];
        if (z != undefined)
        {
            rez += z;
        }
        else
        {
            rez += id[i];
        }
    }
    return rez;
}

export default id_rus_to_en;