import React from 'react';
import { PlusLg, XLg } from 'react-bootstrap-icons';

class SpisokComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            search: "",
            showItems: 10,
        };
    }

    componentDidUpdate(prevProps, prevState)
    {
        if (prevProps.items.length !== this.props.items.length)
        {
            this.setState({
                search: "",
                showItems: 10,
            });
        }

    }

    render()
    {
        let page = this.props.items;
        let showMore = null;
        let allCount = page.length;
        if (this.state.search.trim() !== "")
        {
            page = page.filter((item) =>
                this.props.getText(item).toLowerCase().includes(this.state.search.toLowerCase())
            );
        }
        let searchCount = page.length;
        if (page.length > this.state.showItems)
        {
            showMore = <button
                className="btn btn-outline-secondary"
                onClick={() =>
                {
                    this.setState({
                        showItems: this.state.showItems + 10,
                    });
                }}
            >
                + {page.length - this.state.showItems > 10 ? 10 : page.length - this.state.showItems}
            </button>
        }
        page = page.slice(0, this.state.showItems);
        return (
            <div className="d-flex flex-column gap-2">
                {this.props.plusClick !== undefined &&
                    <button type="button" className="btn btn-outline-primary d-flex align-items-center justify-content-center" onClick={() =>
                    {
                        if (this.props.plusClick !== undefined)
                        {
                            this.props.plusClick();
                        }
                    }
                    }>
                        <PlusLg size="1.2rem" />
                        {this.props.plusText}
                    </button>
                }
                {allCount > 10 &&
                    <div className="col-12 gap-1 d-flex flex-row">
                        <input
                            type="text"
                            placeholder="Поиск"
                            value={this.state.search}
                            onChange={(e) =>
                            {
                                this.setState({ search: e.target.value });
                            }}
                            className="form-control flex-grow-1"
                        />
                        {this.state.search.trim() !== "" &&
                            <button type="button" className="btn btn-outline-secondary d-flex align-items-center justify-content-center" onClick={() =>
                            {
                                this.setState({
                                    search: "",
                                });
                            }}>
                                <XLg />
                            </button>
                        }
                    </div>
                }
                {allCount > 10 &&
                    <small className="text-muted">
                        Элементов: {allCount}
                    </small>
                }
                {this.state.search.trim() !== "" &&
                    <small className="text-muted">
                        Найдено: {searchCount}
                    </small>
                }
                {page.map(item =>
                    this.props.otrisovka(item)
                )}
                {showMore}
            </div>
        );
    }
}

export default SpisokComponent;