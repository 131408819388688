import React from "react";
import Obertka from "./Obertka";
import PoleType from "../class/PoleType";
import PoleZnachObertka from "./PoleZnachObertka";
import InputComponent from "../components/inputComponent";

class PoleTypeNumber extends PoleType
{

    static getRedPodpis(pole)
    {
        let txt = "Число"
        if (pole.edizm !== "")
        {
            txt += ", " + pole.edizm;
        }
        if (pole.edizm.length > 0)
        {
            for (let t_ei of pole.edizm_array)
            {
                txt += ", x" + t_ei.koef + "=" + t_ei.name;
            }
        }
        if (pole.float_numbers === 0)
        {
            txt += ", целое";
        }
        else
        {
            txt += ", точность: " + Math.pow(0.1, pole.float_numbers).toFixed(pole.float_numbers);
        }
        return txt;
    }

    static getEdizmObject(pole, znach)
    {
        if (pole.edizm_array === undefined)
        {
            return { znach: (znach * 1).toFixed(pole.float_numbers), edizm: pole.edizm };
        }
        let edizm_array = pole.edizm_array;
        edizm_array = edizm_array.filter(x => !isNaN(x.float_numbers * 1) && !isNaN(x.koef * 1));
        edizm_array = edizm_array.map(x => ({
            ...x,
            koef: x.koef * 1,
            float_numbers: x.float_numbers * 1,
        }));
        znach = znach * 1;
        let edizm = pole.edizm;
        if (znach === 0)
        {
            return { znach: znach, edizm: edizm };
        }
        else if (znach <= 1)
        {
            let t_edizm = edizm_array.find(x => znach >= x.koef);
            if (t_edizm !== undefined)
            {
                znach = (znach / t_edizm.koef).toFixed(t_edizm.float_numbers);
                edizm = t_edizm.name;
            }
        }
        else if (znach > 1)
        {
            let t_edizm = edizm_array.filter(x => x.koef >= 1).find(x => znach >= x.koef);
            if (t_edizm !== undefined)
            {
                znach = (znach / t_edizm.koef).toFixed(t_edizm.float_numbers);
                edizm = t_edizm.name;
            }
        }
        return { znach: znach, edizm: edizm };
    }

    static getDefaultValue = () => "";

    static getValidError(pole)
    {
        if (pole.deleted)
        {
            return true;
        }
        if (pole.dataInKart !== undefined && pole.dataInKart !== null)
        {
            if (pole.dataInKart.value.trim() === "")
            {
                return true;
            }
        }
        else
        {
            if (pole.dataInKomp.some(x => x.value.trim() === ""))
            {
                return true;
            }
        }
        return false;
    }

    static preobr_object(db, object)
    {
        return super.preobr_object(db, object, (dbPole, objectPole) =>
        {
            function countDecimals(n)
            {
                const str = n.toString().split('.');
                if (str.length < 2)
                {
                    return 0;
                } else
                {
                    return str[1].length;
                }
            }
            if (dbPole.type === "number")
            {
                if (objectPole.type === "number")
                {
                    let db_numbers = dbPole.float_numbers;
                    let kart_numbers = countDecimals(objectPole.value);
                    if (kart_numbers > db_numbers)
                    {
                        objectPole.value = (objectPole.value * 1).toFixed(db_numbers);
                    }
                }
                else if (objectPole.type === "text" || objectPole.type === "link" || objectPole.type === "tovar")
                {
                    if (objectPole.type === "link")
                    {
                        objectPole.value = objectPole.value.text;
                    }
                    else if (objectPole.type === "tovar")
                    {
                        let tovar = db.catalog.find(tovar => tovar._id === objectPole.value);
                        if (tovar === undefined)
                        {
                            objectPole.value = objectPole.value.text;
                        }
                        else
                        {
                            objectPole.value = tovar.nazv;
                        }
                    }
                    objectPole.value = objectPole.value * 1
                    if (isNaN(objectPole.value))
                    {
                        objectPole.value = this.getDefaultValue();
                    }
                    else
                    {
                        objectPole.value = objectPole.value.toFixed(dbPole.float_numbers);
                    }
                }
                else if (objectPole.type === "array")
                {
                    objectPole.value = objectPole.value.filter(time => dbPole.array.some(x => x.time === time));
                    let currentVariantsID = objectPole.value;
                    let serverVariants = dbPole.array;
                    function variantToText(variant)
                    {
                        if (variant.type === "text")
                        {
                            return variant.name;
                        }
                        else if (variant.type === "link")
                        {
                            return variant.name + " (" + variant.link + ")";
                        }
                        else if (variant.type === "tovar")
                        {
                            let tovar = db.catalog.find(x => x._id === variant.tovar && x.show);
                            if (tovar === undefined)
                            {
                                return "";
                            }
                            else
                            {
                                return tovar.nazv;
                            }
                        }
                        else
                        {
                            return "";
                        }
                    }
                    let textVariants = currentVariantsID.map(time => variantToText(serverVariants.find(variant => variant.time === time)));
                    if (textVariants.some(textVariant => !isNaN(textVariant * 1)))
                    {
                        objectPole.value = (textVariants.find(textVariant => !isNaN(textVariant * 1)) * 1).toFixed(dbPole.float_numbers);
                    }
                    else
                    {
                        objectPole.value = this.getDefaultValue();
                    }
                }
                objectPole.type = "number";
            }
            return object;
        });
    }

    constructor(props)
    {
        super(props);
    }

    redRender()
    {
        let znach = this.constructor.getDefaultValue();
        if (this.props.znach !== undefined)
        {
            znach = this.props.znach;
        }
        return (
            <div className="d-flex flex-column col-12">
                <Obertka
                    redStyle={true}
                >
                    <InputComponent
                        value={znach}
                        onChange={(value) => value === ""
                            ? this.props.onChange(undefined)
                            : this.props.onChange(value)
                        }
                        mode="number"
                        float_numbers={this.props.pole.float_numbers}
                    />
                </Obertka>
            </div>
        );
    }

    prosmRender()
    {

        let znachArray = this.props.znachArray;
        let pole = this.props.pole;
        function renderNumber(znach)
        {
            znach = znach.value;
            if (pole.edizm_array === undefined)
            {
                return (znach * 1).toFixed(pole.float_numbers).toLocaleString('ru-RU') + " " + pole.edizm;
            }
            let edizm_array = pole.edizm_array;
            edizm_array = edizm_array.filter(x => !isNaN(x.float_numbers * 1) && !isNaN(x.koef * 1));
            edizm_array = edizm_array.map(x => ({
                ...x,
                koef: x.koef * 1,
                float_numbers: x.float_numbers * 1,
            }));
            znach = znach * 1;
            let edizm = pole.edizm;
            if (znach === 0)
            {
                return (znach * 1).toFixed(pole.float_numbers).toLocaleString('ru-RU') + " " + pole.edizm;
            }
            else if (znach <= 1)
            {
                let t_edizm = edizm_array.find(x => znach >= x.koef);
                if (t_edizm !== undefined)
                {
                    znach = (znach / t_edizm.koef).toFixed(t_edizm.float_numbers);
                    edizm = t_edizm.name;
                }
            }
            else if (znach > 1)
            {
                let t_edizm = edizm_array.filter(x => x.koef >= 1).find(x => znach >= x.koef);
                if (t_edizm !== undefined)
                {
                    znach = (znach / t_edizm.koef).toFixed(t_edizm.float_numbers);
                    edizm = t_edizm.name;
                }
            }
            return znach.toLocaleString('ru-RU') + " " + edizm;
        }
        znachArray = znachArray.map(znach => renderNumber(znach));
        return (
            <PoleZnachObertka
                poleClass={this.constructor}
            >
                {znachArray}
            </PoleZnachObertka>
        );
    }
}

export default PoleTypeNumber;