import React from "react";
import PhotoComponent from "./photoComponent";
import { Link } from "react-router-dom";

class BrandComponent extends React.Component
{
    render()
    {
        let photo = this.props.redSost
            ? <div className="d-flex" style={{ width: "3rem" }}>
                <PhotoComponent empty={true} />
            </div>
            : null;
        if (this.props.brand_photo !== undefined && this.props.brand_photo !== null && this.props.brand_photo !== "")
        {
            photo = <div className="d-flex" style={{ width: "3rem" }}>
                <PhotoComponent file={this.props.brand_photo} adress={this.props.adress} />
            </div>;
        }
        if (this.props.redSost)
        {
            return (
                <div className="d-flex flex-row gap-1 p-1 align-items-center">
                    {photo}
                    <div className={"d-flex flex-row flex-grow-1 align-items-center" + (this.props.redSost ? " text-muted" : "")}>
                        {this.props.brand_text}
                    </div>
                </div>
            );
        }
        else
        {
            if (this.props.link !== undefined && this.props.link !== "")
            {
                return (
                    <div className="d-inline-flex gap-1 p-0 align-items-center">
                        <Link to={this.props.link} className="d-inline-flex flex-row gap-1 p-1 align-items-center btn btn-light">
                            {photo}
                            <div className={"d-inlne-flex flex-grow-1 align-items-center" + (this.props.redSost ? " text-muted" : "")+" "+this.props.className}>
                                {this.props.brand_text}
                            </div>
                        </Link>
                    </div>
                );
            }
            else
            {
                return (
                    <div className="d-inline-flex gap-1 p-0 align-items-center">
                        <div className="d-inline-flex flex-row gap-1 p-1 align-items-center">
                            {photo}
                            <div className={"d-inlne-flex flex-grow-1 align-items-center" + (this.props.redSost ? " text-muted" : "")+" "+this.props.className}>
                                {this.props.brand_text}
                            </div>
                        </div>
                    </div>
                );
            }

        }
    }
}

export default BrandComponent;