import React from "react";

class PhotoComponent extends React.Component
{
    render()
    {
        if (this.props.empty === true)
        {
            return (
                <div
                    className="col-12 rounded border bg-light d-flex"
                    style={{ "aspectRatio": "1/1" }}
                >

                </div>
            );
        }
        let formats = ["png", "jpg", "jpeg", "gif", "svg", "webp"];
        if (formats.some(x => this.props.file.split(".")[this.props.file.split(".").length - 1].toLowerCase() === x))
        {
            return (
                <div className="col-12 align-items-center justify-content-center d-flex rounded" style={{ aspectRatio: "1/1", width: "100%" }}>
                    <div className="col-12 align-items-center justify-content-center d-flex rounded" style={{ aspectRatio: "1/1", width: "100%" }}>
                        <img
                            src={this.props.adress + "/files/" + this.props.file}
                            className="rounded mw-100 mh-100"
                            alt=""
                        />
                    </div>
                </div>
            )
        }
        else
        {
            return (
                <div
                    className="col-12 rounded border bg-light d-flex"
                    style={{ "aspectRatio": "1/1" }}
                >

                </div>
            );
        }
    }
}

export default PhotoComponent;