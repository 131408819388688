import { ListUl } from 'react-bootstrap-icons';
import Pole from '../class/Pole';
import Obertka from './Obertka';
import OtkrComponent from '../components/otkrComponent';
import PoleCategory from './PoleCategory';
import React from 'react';
import PoleHarGroup from './PoleHarGroup';
import PoleKomp from './PoleKomp';
import PoleHarPole from './PoleHarPole';
import array_join from '../func/array_join';
import { renderToString } from 'react-dom/server';

class PoleHar extends Pole
{
    static pole = "har";

    static defaultValue = {};

    static getValidError(db, object)
    {
        function getCurrentValidError(db, object, harObject)
        {
            let groupsFromServer = db.groups;
            let currentGroups = harObject;
            if (Object.keys(currentGroups).some(x => !groupsFromServer.some(y => y.time === x)))
            {
                return true;
            }
            let currentGroupsObjects = groupsFromServer.filter(x => currentGroups[x.time] !== undefined);
            let f = currentGroupsObjects.some(group =>
            {
                let polya = group.polya;
                let dataInKart = currentGroups[group.time];
                let objectCategory = db.category.find(x => x.time === object.category);
                if (objectCategory === undefined)
                {
                    return true;
                }
                if (!objectCategory.groups.some(cat_group => cat_group.time === group.time))
                {
                    return true;
                }
                let groupInCategory = db.category.some(category => category.groups.some(cat_group => cat_group.time === group.time));
                if (!groupInCategory)
                {
                    return true;
                }
                if (PoleHarGroup.getValidError(polya, dataInKart))
                {
                    return true;
                }
                return false;
            });
            if (f)
            {
                return true;
            }
        }
        if (getCurrentValidError(db, object, object.har))
        {
            return true;
        }
        for (let komp of object[PoleKomp.pole])
        {
            if (komp.time !== -1)
            {
                if (getCurrentValidError(db, object, komp.har))
                {
                    return true;
                }
            }
        }
        return false;
    }

    static preobr_object(db, object)
    {
        object = PoleHarGroup.preobr_object(db, object);
        return object;
    }

    constructor(props)
    {
        super(props);
        this.redRender = this.redRender.bind(this);
        this.prosmRender = this.prosmRender.bind(this);

    }

    redRender()
    {
        let pole = this.constructor.pole;
        let categoryID = this.props.object[PoleCategory.pole];
        let category = this.props.db.category.find(category => category.time === categoryID);
        if (!category)
        {
            return null;
        }
        let groupsInCategoryID = category.groups.map(group => group.time);
        let currentHar = null;
        let komps = this.props.object[PoleKomp.pole].filter(komp => komp.time !== -1);
        if (this.props.objectState.currentKomp !== -1)
        {
            currentHar = komps.find(komp => komp.time === this.props.objectState.currentKomp).har;
        }
        else
        {
            currentHar = this.props.object[pole];
        }
        let groupsInHarID = Object.keys(currentHar);
        let groupsIDArray = [
            ...groupsInHarID
                .filter(groupID => !groupsInCategoryID.includes(groupID))
                .filter(groupID => this.props.db.groups.find(group => group.time === groupID) === undefined),
            ...groupsInHarID
                .filter(groupID => !groupsInCategoryID.includes(groupID))
                .filter(groupID => this.props.db.groups.find(group => group.time === groupID) === undefined),
            ...groupsInCategoryID,
        ];

        let renderArray = groupsIDArray.map(groupID =>
            <PoleHarGroup
                {...this.props}
                key={groupID}
                groupID={groupID}
                categoryID={categoryID}
                currentHar={currentHar}
            />
        );
        if (!renderArray.some(x => renderToString(x).trim() !== ""))
        {
            <Obertka
                text="Характеристики"
                {...this.props}
            >
                <small
                    className="text-muted fst-italic"
                >
                    Нет групп
                </small>
            </Obertka>
        }
        return (
            <Obertka
                text="Характеристики"
                {...this.props}
            >
                {renderArray}
            </Obertka>
        );
    }

    prosmRender()
    {
        let pole = this.constructor.pole;
        let categoryID = this.props.object[PoleCategory.pole];
        let category = this.props.db.category.find(category => category.time === categoryID);
        if (!category)
        {
            return null;
        }
        let groupsInCategoryID = category.groups.map(group => group.time);
        let currentHarArray = null;
        let komps = this.props.object[PoleKomp.pole].filter(komp => komp.time !== -1);
        if (this.props.objectState.currentKomp !== -1)
        {
            let har = [];
            for(let groupID in this.props.object[pole])
            {
                har[groupID]={};
                for(let poleID in this.props.object[pole][groupID])
                {
                    har[groupID][poleID] = JSON.parse(JSON.stringify(this.props.object[pole][groupID][poleID]));
                }
            }
            let komp = komps.find(komp => komp.time === this.props.objectState.currentKomp).har;
            for (let groupID in komp)
            {
                for (let poleID in komp[groupID])
                {
                    if (har[groupID] === undefined)
                    {
                        har[groupID] = {};
                    }
                    har[groupID][poleID] = komp[groupID][poleID];
                }
            }
            currentHarArray = [
                har,
            ];
        }
        else
        {
            currentHarArray = [
                this.props.object[pole],
                ...komps.map(komp => komp.har),
            ];
        }
        /** группы из категории,  */
        let groupsID = groupsInCategoryID
            .filter(groupID => currentHarArray
                .some(har => har[groupID] !== undefined)
            );
        /** список групп для отрисовки */
        let renderArray = groupsID.map(groupID =>
            <PoleHarGroup
                {...this.props}
                key={groupID}
                groupID={groupID}
                categoryID={categoryID}
                currentHarArray={currentHarArray}
            />
        );
        if (!renderArray.some(x => renderToString(x).trim() !== ""))
        {
            return null;
        }
        return (
            <Obertka
                text="Характеристики"
                {...this.props}
            >
                {renderArray}
            </Obertka>
        );
    }

    render()
    {
        if (this.props.redSost)
        {
            return this.redRender();
        }
        else
        {
            return this.prosmRender();
        }
    }
}

export default PoleHar;