import React from "react";
import Pole from "../class/Pole";
import OtkrComponent from "../components/otkrComponent";
import SpisokComponent from "../components/spisokComponent";
import Obertka from "./Obertka";
import { CaretDownFill, CaretUpFill, Link45deg, Trash3 } from "react-bootstrap-icons";
import preobr_url from "../func/preobr_url";
import MenuComponent from "../components/menuComponent";
import MenuItemComponent from "../components/menuItemComponent";

class LinkInSpisok extends React.Component
{
    render()
    {
        if (this.props.redSost)
        {
            let menu = <MenuComponent>
                <MenuItemComponent
                    onClick={this.props.onUp}
                    disabled={this.props.blockUp}
                >
                    <CaretUpFill /> Выше
                </MenuItemComponent>
                <MenuItemComponent
                    onClick={this.props.onDown}
                    disabled={this.props.blockDown}
                >
                    <CaretDownFill /> Ниже
                </MenuItemComponent>
                <MenuItemComponent
                    onClick={this.props.onDelete}
                    className="text-danger"
                >
                    <Trash3 /> Удалить ссылку
                </MenuItemComponent>
            </MenuComponent>;
            let zagolovok = <small className="fst-italic text-muted">Нет данных</small>
            if (this.props.item.link !== "" && this.props.item.text !== "")
            {
                zagolovok = <React.Fragment>{this.props.item.text} ({preobr_url(this.props.item.link)})</React.Fragment>
            }
            return (
                <OtkrComponent
                    zagolovok={zagolovok}
                    validError={this.props.item.link === "" || this.props.item.text === ""}
                    menu={menu}
                    icon={<Link45deg />}
                >
                    <div className="d-flex flex-row gap-0 col-12">
                        <div className="d-flex flex-column gap-2 col-6 p-1">
                            <Obertka text="Ссылка" redStyle={true} validError={this.props.item.link === ""}>
                                <input type="text" placeholder="Ссылка" value={this.props.item.link} className="form-control" onChange={(e) => this.props.onChange("link", e.target.value)} />
                            </Obertka>
                        </div>
                        <div className="d-flex flex-column gap-2 col-6 p-1">
                            <Obertka text="Подпись" redStyle={true} validError={this.props.item.text === ""}>
                                <input type="text" placeholder="Подпись" value={this.props.item.text} className="form-control" onChange={(e) => this.props.onChange("text", e.target.value)} />
                            </Obertka>
                        </div>
                    </div>
                </OtkrComponent>
            );
        }
        else
        {
            return (
                <div className="d-inline-flex">
                    <a className="link" href={this.props.item.link} target="_blank" rel="noreferrer">
                        <Link45deg />{this.props.item.text}
                    </a>
                </div>
            );
        }
    }
}

class PoleLinks extends Pole
{
    static pole = "links";

    static defaultValue = [];

    static getValidError(db, object)
    {
        if (object[this.pole].some(x => x.text === "" || x.link === ""))
        {
            return true;
        }
        return false;
    };

    render()
    {
        let pole = this.constructor.pole;
        if (this.props.redSost)
        {
            let zagolovok = <small className="fst-italic text-muted">Нет ссылок</small>;
            if (this.props.object[pole].length > 0)
            {
                zagolovok = <React.Fragment>Ссылок: {this.props.object[pole].length}</React.Fragment>;
            }
            return (
                <Obertka
                    text="Ссылки"
                    icon={<Link45deg />}
                    validError={this.constructor.getValidError(this.props.db, this.props.object)}
                    {...this.props}
                >
                    <OtkrComponent
                        zagolovok={zagolovok}
                        validError={this.constructor.getValidError(this.props.db, this.props.object)}
                    >
                        <SpisokComponent
                            plusClick={() =>
                            {
                                let spisok = this.props.object[pole];
                                spisok.unshift({
                                    time: Date.now(),
                                    link: "",
                                    text: ""
                                });
                                this.props.onChangePole(pole, spisok);
                            }}
                            plusText="Добавить ссылку"
                            items={this.props.object[pole]}
                            otrisovka={(item2) =>
                                <LinkInSpisok
                                    item={item2}
                                    key={item2.time}
                                    onChange={(pole2, value) =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylka = spisok.find(x => x.time === item2.time);
                                        ssylka[pole2] = value;
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    onDelete={() =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                        spisok.splice(ssylkaIndex, 1);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    blockDown={this.props.object[pole].findIndex(x => x.time === item2.time) === this.props.object[pole].length - 1}
                                    blockUp={this.props.object[pole].findIndex(x => x.time === item2.time) === 0}
                                    onDown={() =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylka = spisok.find(x => x.time === item2.time);
                                        let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                        spisok.splice(ssylkaIndex, 1);
                                        spisok.splice(ssylkaIndex + 1, 0, ssylka);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    onUp={() =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylka = spisok.find(x => x.time === item2.time);
                                        let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                        spisok.splice(ssylkaIndex, 1);
                                        spisok.splice(ssylkaIndex - 1, 0, ssylka);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    {...this.props}
                                />
                            }
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            if (this.props.object[pole].length === 0)
            {
                return null;
            }
            return (
                <Obertka text="Ссылки" {...this.props}>
                    {this.props.object[pole].map((item, index) =>
                        <LinkInSpisok key={index} item={item} {...this.props} />
                    )}
                </Obertka>
            );
        }
    }
}

export default PoleLinks;