import { ListUl, XLg } from "react-bootstrap-icons";
import OtkrComponent from "../components/otkrComponent";
import Obertka from "./Obertka";
import PoleHarPole from "./PoleHarPole";
import MenuComponent from "../components/menuComponent";
import MenuItemComponent from "../components/menuItemComponent";
import React from "react";
import PoleHar from "./PoleHar";
import PoleCategory from "./PoleCategory";
import PoleKomp from "./PoleKomp";
import TableComponent from "../components/tableComponent";
import { renderToString } from "react-dom/server";

class PoleHarGroup extends React.Component
{

    static getValidError(polya, dataInKart)
    {
        let serverPolya = polya;
        let localPolya = dataInKart;
        let currentPolya = [];
        serverPolya.forEach(pole => currentPolya.push(pole));
        currentPolya.forEach(pole => pole.dataInKart = localPolya[pole.time] === undefined ? null : localPolya[pole.time]);
        if (currentPolya.filter(pole => pole.dataInKart !== null).some(x => PoleHarPole.getValidError(x)))
        {
            return true;
        }
        if (currentPolya.every(pole => pole.dataInKart === null))
        {
            return true;
        }
        return false;
    }

    static preobr_object(db, object)
    {
        object = PoleHarPole.preobr_object(db, object);
        return object;
    }

    constructor(props)
    {
        super(props);
        this.addGroup = this.addGroup.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.redRender = this.redRender.bind(this);
        this.prosmRender = this.prosmRender.bind(this);
    }
    addGroup()
    {
        if (this.props.objectState.currentKomp === -1)
        {
            let data = this.props.object[PoleHar.pole];
            data[this.props.itemGroup.group.time] = {};
            this.props.onChangePole(PoleHar.pole, data);
        }
        else
        {
            let data = this.props.object[PoleKomp.pole];
            data.find(x => x.time === this.props.objectState.currentKomp).har[this.props.itemGroup.group.time] = {};
            this.props.onChangePole(PoleKomp.pole, data);
        }
    }
    onDelete()
    {
        if (this.props.objectState.currentKomp === -1)
        {
            let data = this.props.object[PoleHar.pole];
            delete data[this.props.itemGroup.group.time];
            this.props.onChangePole(PoleHar.pole, data);
        }
        else
        {
            let data = this.props.object[PoleKomp.pole];
            delete data.find(x => x.time === this.props.objectState.currentKomp).har[this.props.itemGroup.group.time];
            this.props.onChangePole(PoleKomp.pole, data);
        }
    }

    redRender()
    {
        let groupID = this.props.groupID;
        let group = this.props.db.groups.find(group => group.time === groupID);
        if (!group)
        {
            group = {
                name: "Группа #" + groupID,
                time: groupID,
                active: true,
                visible: true,
                deleted: true,
                polya: [],
            };
        }
        else
        {
            group.deleted = false;
        }
        let currentHarGroup = this.props.currentHar[groupID];
        let polyaInHarIDArray = [];
        if (currentHarGroup)
        {
            polyaInHarIDArray = Object.keys(currentHarGroup);
        }
        let polyaInGroupIDArray = group.polya.map(pole => pole.time);
        let polyaID = [
            ...polyaInHarIDArray.filter(poleID => !polyaInGroupIDArray.includes(poleID)),
            ...polyaInGroupIDArray,
        ];
        let renderArray = polyaID.map(poleID =>
            <PoleHarPole
                {...this.props}
                key={poleID}
                poleID={poleID}
                group={group}
            />
        );
        if (!renderArray.some(x => renderToString(x).trim() !== ""))
        {
            return null;
        }
        return (
            <div className="d-flex col-12 flex-column gap-1 border rounded p-0">
                <div className="d-flex p-1 bg-light rounded-top fw-medium">
                    {group.name}
                </div>
                <div className="d-flex p-0 flex-row gap-0 flex-wrap bg-white rounded-bottom">
                    <TableComponent>
                        {renderArray}
                    </TableComponent>
                </div>
            </div>
        );
    }

    prosmRender()
    {
        let groupID = this.props.groupID;
        let group = this.props.db.groups.find(group => group.time === groupID);
        if (!group)
        {
            return null;
        }
        if (!group.visible)
        {
            return null;
        }
        let categoryID = this.props.categoryID;
        let category = this.props.db.category.find(category => category.time === categoryID);
        if (!category)
        {
            return null;
        }
        let groupInCategory = category.groups.find(group => group.time === groupID);
        if (!groupInCategory)
        {
            return null;
        }
        if (!groupInCategory.visible)
        {
            return null;
        }
        /** ИД полей в текущей группе */
        let polyaInGroupIDArray = group.polya.map(pole => pole.time);
        /** ИД полей в текущих выбранных комплектациях */
        let polyaInCurrentHarArrayIDArray = this.props.currentHarArray
            .map(har => har[groupID] === undefined
                ? []
                : Object.keys(har[groupID])
            );
        /** ИД полей в текущих выбранных комплектациях, но по порядку и присутствию в группе */
        let polyaID = polyaInGroupIDArray.filter(poleID => polyaInCurrentHarArrayIDArray.some(har => har.includes(poleID)));
        if (polyaID.length === 0)
        {
            return null;
        }
        let renderArray = polyaID.map(poleID =>
            <PoleHarPole
                {...this.props}
                key={poleID}
                poleID={poleID}
            />
        );
        if (!renderArray.some(x => renderToString(x).trim() !== ""))
        {
            return null;
        }
        return (
            <div className="d-flex col-12 flex-column gap-1 border rounded p-0">
                <div className="d-flex p-1 bg-light rounded-top fw-medium">
                    {group.name}
                </div>
                <div className="d-flex p-0 flex-row gap-0 flex-wrap">
                    {renderArray}
                </div>
            </div>
        );
    }

    render()
    {
        if (this.props.redSost)
        {
            return this.redRender();
        }
        else
        {
            return this.prosmRender();
        }
    }
}

export default PoleHarGroup;