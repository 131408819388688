import React from "react";
import Pole from "../class/Pole";
import OtkrComponent from "../components/otkrComponent";
import SpisokComponent from "../components/spisokComponent";
import Obertka from "./Obertka";
import { CaretDownFill, CaretUpFill, Circle, CircleFill, Gear, Link45deg, Trash3, Square, SquareFill } from "react-bootstrap-icons";
import MenuComponent from "../components/menuComponent";
import MenuItemComponent from "../components/menuItemComponent";
import SelectComponent from "../components/selectComponent";
import WarningComponent from "../components/warningComponent";

class KompInSpisok extends React.Component
{
    static getDefaultValue(number)
    {
        if (number === 1)
        {
            return {
                time: -1,
                har: {},
                text: "База",
            };
        }
        return {
            time: Date.now(),
            har: {},
            text: number.toString(),
        };
    }

    render()
    {
        if (this.props.redSost)
        {
            let menu = <MenuComponent>
                {this.props.item.time !== -1 &&
                    <React.Fragment>
                        <MenuItemComponent
                            onClick={this.props.onUp}
                            disabled={this.props.blockUp}
                        >
                            <CaretUpFill /> Выше
                        </MenuItemComponent>
                        <MenuItemComponent
                            onClick={this.props.onDown}
                            disabled={this.props.blockDown}
                        >
                            <CaretDownFill /> Ниже
                        </MenuItemComponent>
                        <MenuItemComponent
                            onClick={this.props.onDelete}
                            className="text-danger"
                        >
                            <Trash3 /> Удалить комплектацию
                        </MenuItemComponent>
                    </React.Fragment>
                }
            </MenuComponent>;
            let zagolovok = null
            let icon = null;
            if (this.props.index === 0)
            {
                zagolovok = <span><span className="fw-medium">{this.props.item.text}</span> (скрыто)</span>
                icon = this.props.item.time === this.props.current ? <SquareFill /> : <Square />;
            }
            else
            {
                zagolovok = this.props.item.text
                icon = this.props.item.time === this.props.current ? <CircleFill /> : <Circle />;
            }

            return (
                <OtkrComponent
                    zagolovok={zagolovok}
                    validError={this.props.item.text === ""}
                    menu={this.props.item.time !== -1 && menu}
                    icon={icon}
                    bez_otkr={this.props.item.time === -1}
                >
                    <div className="d-flex flex-column gap-2 p-1">
                        <Obertka text="Название" redStyle={true} validError={this.props.item.text === ""}>
                            <input type="text" placeholder="Название" value={this.props.item.text} className="form-control" onChange={(e) => this.props.onChange("text", e.target.value)} />
                        </Obertka>
                    </div>
                </OtkrComponent>
            );
        }
        else
        {
            return (
                <div className="d-inline-flex">
                    <a className="link" href={this.props.item.link} target="_blank" rel="noreferrer">
                        <Link45deg />{this.props.item.text}
                    </a>
                </div>
            );
        }
    }
}

class PoleKomp extends Pole
{
    static pole = "komp";

    static defaultValue = [
        KompInSpisok.getDefaultValue(1),
    ];

    static getValidError(db, object)
    {
        if (object[this.pole].some(x => x.text === ""))
        {
            return true;
        }
        return false;
    };

    static getDefaultState()
    {
        return { currentKomp: -1 };
    }

    render()
    {
        let pole = this.constructor.pole;
        let komps = this.props.object[pole];
        if (this.props.redSost)
        {
            let zagolovok = <React.Fragment>Комплектаций: {komps.length}</React.Fragment>;
            let warning = null;
            if (this.props.object._id !== null)
            {
                let oldKomp = this.props.objectIsh[pole];
                let newKomp = this.props.object[pole];
                if (oldKomp.some(x => newKomp.every(y => x.time !== y.time)))
                {
                    warning = <WarningComponent
                        zagolovok="Комплектация удалена!"
                        text="Данные будут утеряны"
                    />
                }
            }
            return (
                <Obertka
                    text="Комплектации"
                    icon={<Gear />}
                    {...this.props}
                >
                    {warning}
                    <Obertka
                        text="Текущая комплектация"
                        redStyle={true}
                    >
                        <SelectComponent
                            items={komps.toSorted((a, b) => a.text.localeCompare(b.text))}
                            isSelected={x => x.time === this.props.objectState.currentKomp}
                            getText={x => x.text}
                            getID={x => x.time}
                            multiSelect={false}
                            validError={false}
                            onClick={x => this.props.setObjectState({ currentKomp: x.time })}
                            getIcon={x =>
                            {
                                if (x.time === -1)
                                {
                                    if (x.time === this.props.objectState.currentKomp)
                                    {
                                        return <SquareFill />;
                                    }
                                    else
                                    {
                                        return <Square />;
                                    }
                                }
                                if (x.time === this.props.objectState.currentKomp)
                                {
                                    return <CircleFill />;
                                }
                                else
                                {
                                    return <Circle />;
                                }
                            }}
                        />
                    </Obertka>
                    <Obertka
                        text="Все комплектации"
                        validError={this.constructor.getValidError(this.props.db, this.props.object)}
                        redStyle={true}
                    >
                        <OtkrComponent
                            zagolovok={zagolovok}
                            validError={this.constructor.getValidError(this.props.db, this.props.object)}
                        >
                            <SpisokComponent
                                plusClick={() =>
                                {
                                    let spisok = this.props.object[pole];
                                    spisok.push({
                                        time: Date.now(),
                                        har: {},
                                        text: (spisok.length).toString(),
                                    });
                                    this.props.onChangePole(pole, spisok);
                                }}
                                plusText="Добавить комплектацию"
                                items={komps}
                                otrisovka={(item2) =>
                                    <KompInSpisok
                                        current={this.props.objectState.currentKomp}
                                        item={item2}
                                        key={item2.time}
                                        index={komps.findIndex(x => x.time === item2.time)}
                                        onChange={(pole2, value) =>
                                        {
                                            let spisok = this.props.object[pole];
                                            let ssylka = spisok.find(x => x.time === item2.time);
                                            ssylka[pole2] = value;
                                            this.props.onChangePole(pole, spisok);
                                        }}
                                        onDelete={() =>
                                        {
                                            let spisok = this.props.object[pole];
                                            let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                            spisok.splice(ssylkaIndex, 1);
                                            this.props.onChangePole(pole, spisok);
                                        }}
                                        blockDown={this.props.object[pole].findIndex(x => x.time === item2.time) === this.props.object[pole].length - 1}
                                        blockUp={this.props.object[pole].findIndex(x => x.time === item2.time) === 1}
                                        onDown={() =>
                                        {
                                            let spisok = this.props.object[pole];
                                            let ssylka = spisok.find(x => x.time === item2.time);
                                            let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                            spisok.splice(ssylkaIndex, 1);
                                            spisok.splice(ssylkaIndex + 1, 0, ssylka);
                                            this.props.onChangePole(pole, spisok);
                                        }}
                                        onUp={() =>
                                        {
                                            let spisok = this.props.object[pole];
                                            let ssylka = spisok.find(x => x.time === item2.time);
                                            let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                            spisok.splice(ssylkaIndex, 1);
                                            spisok.splice(ssylkaIndex - 1, 0, ssylka);
                                            this.props.onChangePole(pole, spisok);
                                        }}
                                        {...this.props}
                                    />
                                }
                            />
                        </OtkrComponent>
                    </Obertka>

                </Obertka>
            );
        }
        else
        {
            if (this.props.object[pole].length < 2)
            {
                return null;
            }
            return (
                <Obertka text="Комплектации" {...this.props}>
                    <SelectComponent
                        items={komps.filter(x => x.time !== -1).toSorted((a, b) => a.text.localeCompare(b.text))}
                        isSelected={x => x.time === this.props.objectState.currentKomp}
                        getText={x => x.text}
                        getID={x => x.time}
                        multiSelect={false}
                        validError={false}
                        stableOrder={true}
                        onClick={x =>
                        {
                            if (this.props.objectState.currentKomp !== x.time)
                            {
                                this.props.setObjectState({ currentKomp: x.time })
                            }
                            else
                            {
                                this.props.setObjectState({ currentKomp: -1 })
                            }
                        }}
                        getIcon={x =>
                        {
                            if (x.time === this.props.objectState.currentKomp)
                            {
                                return <CircleFill />;
                            }
                            else
                            {
                                return <Circle />;
                            }
                        }}
                    />
                </Obertka>
            );
        }
    }
}

export default PoleKomp;