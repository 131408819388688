import React from "react";

class OsnovaKart extends React.Component
{
    static classArray = [];

    static getDefaultValues()
    {
        let classArray = this.classArray;
        let obj = {};
        classArray.forEach(c => obj[c.pole] = c.defaultValue);
        return obj;
    }

    static preobr_object(db, object)
    {
        let classArray = this.classArray;
        classArray.forEach(c =>
        {
            object = c.preobr_object(db, object);
        });
        return object;
    }

    static getValidError(db, object)
    {
        if (this.classArray.some(x => x.getValidError(db, object)))
        {
            return true;
        }
        return false;
    }

    constructor(props)
    {
        super(props);
        this.state = {};
        this.constructor.classArray.forEach(c =>
        {
            let ts = c.getDefaultState();
            for (let key in ts)
            {
                this.state[key] = ts[key];
            }
        })
        this.setObjectState = this.setObjectState.bind(this);
    }

    setObjectState(data)
    {
        this.setState(data);
    }
}

export default OsnovaKart;