import React from "react";
import { Archive, CheckLg } from "react-bootstrap-icons";
import RedGroup from "./red_group";
import SpisokComponent from "./components/spisokComponent";
import OtkrComponent from "./components/otkrComponent";
import WarningComponent from "./components/warningComponent";
import Obertka from "./polya/Obertka";

class RedGroups extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
            search: "",
            oldData: null,
            showArchive: false,
        };
        this.getData = this.getData.bind(this);
        this.save = this.save.bind(this);
        this.plusClick = this.plusClick.bind(this);
        this.otrisovka = this.otrisovka.bind(this);
    }

    async getData()
    {
        const request = new Request(this.props.adress + '/getgroups', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: null,
        });

        let zapros = await fetch(request);
        let spisok = await zapros.json();
        for (let i in spisok.groups)
        {
            let group = spisok.groups[i];
            spisok = RedGroup.preobr_db(group, spisok);
        }
        this.setState({ data: spisok.groups, oldData: JSON.parse(JSON.stringify(spisok.groups)), categories: spisok.category, catalog: spisok.catalog });
    }

    async save()
    {
        this.setState({ data: null });
        await this.props.zapros({ "type": "save_groups", "data": this.state.data });
        window.location.reload();
    }

    componentDidMount()
    {
        this.getData();
    }

    plusClick()
    {
        this.setState({
            data: [
                RedGroup.getDefaultValue(),
                ...this.state.data,
            ]
        });
    }

    otrisovka(item)
    {
        return <RedGroup
            key={item.time}
            data={this.state.data}
            setData={(a) => this.setState({ data: a })}
            group_index={this.state.data.findIndex(x => x.time === item.time)}
            group={item}
            oldData={this.state.oldData}
            categories={this.state.categories}
            catalog={this.state.catalog}
        />
    }

    render()
    {
        if (this.state.data === null)
        {
            return <div className="spinner-border container" role="status">
                <span className="visually-hidden">Загрузка...</span>
            </div>;
        }
        let warnArchive = null;
        let data = this.state.data;
        let oldData = this.state.oldData;
        let wardDelete = null;
        if (data !== undefined && oldData !== undefined && data !== null && oldData !== null)
        {
            data.forEach(group =>
            {
                let oldGroup = oldData.find(x => x.time === group.time);
                if (oldGroup !== undefined)
                {
                    if (group.active === false && oldGroup.active === true)
                    {
                        warnArchive = <WarningComponent
                            zagolovok="Группа архивирована!"
                            text="Выбрать её в категориях будет невозможно"
                        />;
                    }
                }
            });
            if (this.state.oldData.some(x => !this.state.data.some(y => x.time === y.time)))
            {
                wardDelete = <WarningComponent
                    zagolovok="Группа удалена!"
                    text="Характеристики будут скрыты, группа будет обезличена при редактировании"
                />;
            }
        }
        let validError = this.state.data.some(x => RedGroup.getValidError(x));
        return (
            <div className="d-flex flex-column gap-2">
                <button
                    className="btn btn-outline-primary rounded"
                    onClick={this.save}
                    disabled={validError}
                >
                    <CheckLg size="1.2rem" /> Сохранить
                </button>
                {warnArchive}
                {wardDelete}
                <Obertka
                    text="Группы характеристик"
                    redStyle={true}
                >
                    <SpisokComponent
                        items={this.state.data.filter(x => x.active)}
                        getID={(item) => item.time}
                        getText={(item) => item.name}
                        plusText="Добавить группу"
                        plusClick={this.plusClick}
                        otrisovka={this.otrisovka}
                    />
                    <OtkrComponent
                        icon={<Archive />}
                        zagolovok={this.state.data.some(x => !x.active) ? ("Архивные группы полей: " + this.state.data.filter(x => !x.active).length) : <small className="fst-italic text-muted">Архивные группы отсутствуют</small>}
                        pusto={this.state.data.every(x => x.active)}
                    >
                        <SpisokComponent
                            items={this.state.data.filter(x => !x.active)}
                            getID={(item) => item.time}
                            getText={(item) => item.name}
                            otrisovka={this.otrisovka}
                        />
                    </OtkrComponent>
                </Obertka>
            </div>
        );
    }
}

export default RedGroups;
