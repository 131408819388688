import React from "react";

class NoPage extends React.Component
{
    render()
    {
        return (
            <div className="container">
                <h1>404</h1>
                <p>Страница не найдена</p>
            </div>
        );
    }
}

export default NoPage;