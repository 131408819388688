import React from "react";
class Vhod extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {

        }
        this.otpravka = this.otpravka.bind(this);
    }
    otpravka(e)
    {
        let formData = new FormData(e.target)
        let parol = formData.get("password");
        this.props.vhod(parol);
    }
    render()
    {
        return (<div className="container">
            <form onSubmit={(e) => { e.preventDefault(); this.otpravka(e)}}>
                <div className="form-group">
                    <label htmlFor="parol">Пароль</label>
                    <input type="password" className="form-control" id="password" name="password" />
                </div>
                <div className="py-1">
                    <button type="submit" className="btn btn-primary rounded-pill">Войти</button>
                </div>
                <div className="py-1">
                    <button className="btn btn-secondary rounded-pill" onClick={(e) => { e.preventDefault(); this.props.vyhod() }}>Обычный просмотр</button>
                </div>

            </form>
        </div>);
    }
}

export default Vhod;