import { TextLeft } from "react-bootstrap-icons";
import Pole from "../class/Pole";
import Obertka from "./Obertka";
import OtkrComponent from "../components/otkrComponent";

class PoleOpis extends Pole
{
    static pole = "opis";
    static defaultValue = "";
    render()
    {
        if (this.props.object[this.constructor.pole] === "" && !this.props.redSost)
        {
            return null;
        }
        return (
            <Obertka text="Описание" icon={<TextLeft />} {...this.props}>
                {
                    this.props.redSost
                        ? <OtkrComponent
                            zagolovok={this.props.object[this.constructor.pole]}
                        >
                            <textarea
                                placeholder="Описание"
                                className="form-control"
                                value={this.props.object[this.constructor.pole]}
                                onChange={(e) => this.props.onChangePole(this.constructor.pole, e.target.value)}
                                rows={10}
                            />
                        </OtkrComponent>
                        : <div className="col-12 flex-wrap word-break text-break">
                            {this.props.object[this.constructor.pole]}
                        </div>
                }
            </Obertka>
        );
    }
}

export default PoleOpis;