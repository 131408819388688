import React from 'react';
import Obertka from './Obertka';
import Pole from '../class/Pole';
import { BoxArrowUpRight, FileEarmark } from 'react-bootstrap-icons';
import preobr_url from '../func/preobr_url';

class PoleLink extends Pole
{
    static pole = "link";
    static defaultValue = "";
    render()
    {
        let pole = this.constructor.pole;
        if (this.props.object[pole] === "" && !this.props.redSost)
        {
            return null;
        }
        if (this.props.redSost)
        {
            let link = this.props.object[pole];
            return (
                <Obertka text="Официальный ресурс" icon={<BoxArrowUpRight />} {...this.props}>
                    <input
                        type="text"
                        className="form-control"
                        value={link}
                        onChange={(e) => (this.props.onChangePole(pole, e.target.value))}
                        placeholder="Официальный ресурс"
                    />
                </Obertka>
            );
        }
        else
        {
            return (
                <Obertka text="Официальный ресурс" {...this.props}>
                    <div className='d-inline-flex'>
                        <a className='btn btn-primary d-flex gap-1 align-items-center' href={this.props.object[pole]} target="_blank">
                            {preobr_url(this.props.object[pole])} <BoxArrowUpRight />
                        </a>
                    </div>
                </Obertka>

            );
        }

    }
}

export default PoleLink;