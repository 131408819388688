import React from 'react';
import Obertka from './Obertka';
import Pole from '../class/Pole';
import { Type } from 'react-bootstrap-icons';

class PoleNazv extends Pole
{
    static pole = "nazv";

    static defaultValue = "";

    static getValidError(db, object)
    {
        if(object[this.pole] === "")
        {
            return true;
        }
        return false;
    };

    render()
    {
        let pole = PoleNazv.pole;
        if (this.props.object[pole] === undefined && !this.props.redSost)
        {
            return null;
        }
        if (this.props.redSost)
        {
            let nazv = this.props.object[pole];
            if (nazv === undefined)
            {
                nazv = "";
            }
            let validError = this.constructor.getValidError(this.props.db, this.props.object);
            return (
                <Obertka text="Наименование" icon={<Type />} validError={validError} {...this.props}>
                    <input
                        type="text"
                        className="form-control"
                        value={nazv}
                        onChange={(e) => (this.props.onChangePole(pole, e.target.value))}
                        placeholder="Наименование"
                    />
                </Obertka>
            );
        }
        else
        {
            return (
                <Obertka {...this.props}>
                    <b>{this.props.object[pole]}</b>
                </Obertka>
            );
        }

    }
}

export default PoleNazv;