import React from "react";
import OtkrComponent from "./components/otkrComponent";
import { Archive, Bag, CaretDownFill, CaretUpFill, Eye, EyeSlash, Fonts, Link45deg, Trash3 } from "react-bootstrap-icons";
import Obertka from "./polya/Obertka";
import MenuComponent from "./components/menuComponent";
import MenuItemComponent from "./components/menuItemComponent";
import MenuItemRazdComponent from "./components/menuItemRazdComponent";
import SelectComponent from "./components/selectComponent";
import WarningComponent from "./components/warningComponent";

class RedVariant extends React.Component
{
    static preobr_db(variant, pole, group, db)
    {
        if (variant.type === undefined)
        {
            variant.type = this.getDefaultValue().type;
        }
        if (variant.link === undefined)
        {
            variant.link = this.getDefaultValue().link;
        }
        if (variant.tovar === undefined)
        {
            variant.tovar = this.getDefaultValue().tovar;
        }
        else if (variant.tovar !== this.getDefaultValue().tovar)
        {
            let tovar = db.catalog.filter(x => x.show).find(x => x._id === variant.tovar)
            if (tovar === undefined)
            {
                variant.tovar = this.getDefaultValue().tovar;
            }
        }
        return db;
    }

    static variantTypes = [
        {
            name: "text",
            text: "Текст",
            icon: <Fonts />,
            text_field: true,
            link_field: false,
            tovar_field: false,
            getValidError: (item) =>
            {
                if (item.name.trim() === "")
                {
                    return true;
                }
                return false
            },
        },
        {
            name: "link",
            text: "Ссылка",
            icon: <Link45deg />,
            text_field: true,
            link_field: true,
            tovar_field: false,
            getValidError: (item) =>
            {
                if (item.name.trim() === "")
                {
                    return true;
                }
                if (item.link.trim() === "")
                {
                    return true;
                }
                return false
            },
        },
        {
            name: "tovar",
            text: "Товар",
            icon: <Bag />,
            text_field: false,
            link_field: false,
            tovar_field: true,
            getValidError: (item) =>
            {
                if (item.tovar === null)
                {
                    return true;
                }
                return false
            },
        },
    ];

    static getVariantTypeIcon(typeName)
    {
        let typeObject = this.variantTypes.find(x => x.name === typeName);
        if (typeObject === undefined)
        {
            return null;
        }
        return typeObject.icon;
    }

    static getDefaultValue()
    {
        return {
            name: "",
            time: Date.now().toString(),
            active: true,
            visible: true,
            type: "text",
            link: "",
            tovar: null,
        }
    }

    static getValidError(item)
    {
        if (item.type === undefined)
        {
            return true;
        }
        let variantType = this.variantTypes.find(x => x.name === item.type);
        if (variantType.getValidError(item))
        {
            return true;
        }
        return false;
    }

    constructor(props)
    {
        super(props);
        this.onDelele = this.onDelele.bind(this);
        this.onUp = this.onUp.bind(this);
        this.onDown = this.onDown.bind(this);
        this.onArchive = this.onArchive.bind(this);
        this.onUnArchive = this.onUnArchive.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
    }

    onDelele()
    {
        let data = this.props.data;
        data[this.props.group_index].polya[this.props.pole_index].array.splice(this.props.array_index, 1);
        this.props.setData(data);
    }

    onUp()
    {
        let data = this.props.data;
        let v = data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index];
        data[this.props.group_index].polya[this.props.pole_index].array.splice(this.props.array_index, 1);
        data[this.props.group_index].polya[this.props.pole_index].array.splice(this.props.array_index - 1, 0, v);
        this.props.setData(data);
    }

    onDown()
    {
        let data = this.props.data;
        let v = data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index];
        data[this.props.group_index].polya[this.props.pole_index].array.splice(this.props.array_index, 1);
        data[this.props.group_index].polya[this.props.pole_index].array.splice(this.props.array_index + 1, 0, v);
        this.props.setData(data);
    }

    onArchive()
    {
        let data = this.props.data;
        data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].active = false;
        data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].visible = true;
        this.props.setData(data);
    }

    onUnArchive()
    {
        let data = this.props.data;
        data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].active = true;
        data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].visible = true;
        this.props.setData(data);
    }

    toggleVisibility()
    {
        let data = this.props.data;
        data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].visible = !data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].visible;
        this.props.setData(data);
    }

    render()
    {
        let variant = this.props.data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index];
        let blockUp = this.props.array_index === 0;
        let blockDown = this.props.array_index === this.props.data[this.props.group_index].polya[this.props.pole_index].array.filter(x => x.active).length - 1;
        let menu_active = <MenuComponent>
            <MenuItemComponent
                onClick={this.onUp}
                disabled={blockUp}
            >
                <CaretUpFill /> Выше
            </MenuItemComponent>
            <MenuItemComponent
                onClick={this.onDown}
                disabled={blockDown}
            >
                <CaretDownFill /> Ниже
            </MenuItemComponent>
            <MenuItemRazdComponent />
            <MenuItemComponent
                className="text-danger"
                onClick={this.onArchive}
            >
                <Archive /> Архивировать вариант
            </MenuItemComponent>
        </MenuComponent>;
        let menu_archive = <MenuComponent>
            <MenuItemComponent
                onClick={this.toggleVisibility}
            >
                {variant.visible
                    ? <React.Fragment>
                        <EyeSlash /> Скрыть
                    </React.Fragment>
                    : <React.Fragment>
                        <Eye /> Показать
                    </React.Fragment>
                }
            </MenuItemComponent>
            <MenuItemComponent
                className="text-primary"
                onClick={this.onUnArchive}
            >
                <Archive /> Восстановить вариант
            </MenuItemComponent>
            <MenuItemComponent
                className="text-danger"
                onClick={this.onDelele}
            >
                <Trash3 /> Удалить вариант
            </MenuItemComponent>
        </MenuComponent>
        let variantType = this.constructor.variantTypes.find(x => x.name === this.props.item.type);
        let warnChangeType = null;
        let oldGroup = null;
        let oldPole = null;
        let oldVariant = null;
        if (this.props.oldData.find(x => x.time === this.props.group.time) !== undefined)
        {
            oldGroup = this.props.oldData.find(x => x.time === this.props.group.time);
            if (oldGroup !== undefined)
            {
                oldPole = oldGroup.polya.find(x => x.time === this.props.pole.time);
                if (oldPole !== undefined)
                {
                    if (oldPole.array.find(x => x.time === this.props.item.time))
                    {
                        oldVariant = oldPole.array.find(x => x.time === this.props.item.time);
                    }
                }
            }
        }
        if (oldVariant !== null)
        {
            if (this.props.item.type !== oldVariant.type)
            {
                let newType = this.props.item.type;
                let oldType = oldVariant.type;
                let text = null;
                if (newType === "text")
                {
                    if (oldType === "link")
                    {
                        text = "Ссылка указана в скобках";
                    }
                    else if (oldType === "tovar")
                    {
                        text = "Название товара указано в качестве текста";
                    }
                }
                else if (newType === "link")
                {
                    if (oldType === "text")
                    {
                        text = "Необходимо указать адрес";
                    }
                    else if (oldType === "tovar")
                    {
                        text = "Связь с товаром преобразована в ссылку";
                    }
                }
                else if (newType === "tovar")
                {
                    if (oldType === "text")
                    {
                        text = "Необходимо указать название товара";
                    }
                    else if (oldType === "link")
                    {
                        text = "Может требоваться корректировка ссылки";
                    }
                }
                warnChangeType = <WarningComponent
                    zagolovok="Тип изменён"
                    text={text}
                />;
            }
        }
        let zagolovok = null;
        if (variantType.name === "text" || variantType.name === "link")
        {
            zagolovok = this.props.item.name;
        }
        let tovar_zagolovok = <small className="fst-italic text-muted">Товар не выбран</small>;
        if (this.props.item.tovar !== null)
        {
            let tovar_nazv = this.props.catalog.find(x => x._id === this.props.item.tovar).nazv
            tovar_zagolovok = tovar_nazv;
            zagolovok = tovar_nazv;
        }
        return (
            <OtkrComponent
                zagolovok={zagolovok}
                icon={this.constructor.getVariantTypeIcon(variantType.name)}
                menu={variant.active ? menu_active : menu_archive}
                icon2={variant.active ? null : (variant.visible ? <Eye /> : <EyeSlash />)}
                validError={this.constructor.getValidError(this.props.item)}
            >
                {warnChangeType}
                <Obertka
                    text="Тип"
                    redStyle={true}
                >
                    <OtkrComponent
                        zagolovok={variantType.text}
                        icon={this.constructor.getVariantTypeIcon(variantType.name)}
                    >
                        <SelectComponent
                            items={this.constructor.variantTypes}
                            getText={(x) => x.text}
                            getID={(x) => x.name}
                            isSelected={(x) => x.name === this.props.item.type}
                            multiSelect={false}
                            onClick={(item) =>
                            {
                                let data = this.props.data;
                                data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].type = item.name;
                                this.props.setData(data);
                            }}
                            napr="column"
                            getIcon={(item) => this.constructor.getVariantTypeIcon(item.name)}
                        />
                    </OtkrComponent>
                </Obertka>
                {variantType.text_field &&
                    <Obertka
                        text="Текст"
                        redStyle={true}
                        validError={this.props.item.name === ""}
                    >
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Текст"
                            value={this.props.item.name}
                            onChange={(e) =>
                            {
                                let data = this.props.data;
                                data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].name = e.target.value;
                                this.props.setData(data);
                            }}
                        />
                    </Obertka>
                }
                {variantType.link_field &&
                    <Obertka
                        text="Ссылка"
                        redStyle={true}
                        validError={this.props.item.link === ""}
                    >
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Ссылка"
                            value={this.props.item.link}
                            onChange={(e) =>
                            {
                                let data = this.props.data;
                                data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].link = e.target.value;
                                this.props.setData(data);
                            }}
                        />
                    </Obertka>
                }
                {variantType.tovar_field &&
                    <Obertka
                        text="Товар"
                        redStyle={true}
                        validError={this.props.item.tovar === null}
                    >
                        <OtkrComponent
                            zagolovok={tovar_zagolovok}
                            validError={this.props.item.tovar === null}
                        >
                            <SelectComponent
                                items={this.props.catalog.filter(x => x.show)}
                                getID={x => x._id}
                                getText={x => x.nazv}
                                isSelected={x => this.props.item.tovar === x._id}
                                onClick={tovar =>
                                {
                                    let data = this.props.data;
                                    data[this.props.group_index].polya[this.props.pole_index].array[this.props.array_index].tovar = tovar._id;
                                    this.props.setData(data);
                                }}
                            />
                        </OtkrComponent>
                    </Obertka>
                }
            </OtkrComponent>
        );
    }
}

export default RedVariant;