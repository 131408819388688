import React from 'react';

class InputComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(newValue)
    {
        if (this.props.mode === "number")
        {
            newValue = newValue
                .replace(/,/g, '.')
                .replace(/[^0-9.]/g, '');
            if (/^\d+$/.test(newValue))
            {
                this.props.onChange(newValue);
            } else if (/^\d+\.\d+$/.test(newValue))
            {
                newValue = newValue.slice(0, newValue.lastIndexOf('.') + this.props.float_numbers + 1);
                this.props.onChange(newValue);
            } else if (/^\d+\.\d*$/.test(newValue))
            {
                this.props.onChange(newValue);
            }


            /*if (newValue === "")
            {
                this.props.onChange("");
                return;
            }
            let match = newValue.match(/\d+\.\d+/);
            if (match)
            {
                let textNumber = match[0];
                textNumber = textNumber.slice(0, textNumber.lastIndexOf('.') + this.props.float_numbers + 1);
                this.props.onChange(textNumber);
            }
            else
            {
                let match2 = newValue.match(/\d+/);
                if (match2)
                {
                    this.props.onChange(match2[0]);
                }
                else
                {
                    let match3 = newValue.match(/(\d+\.)/);
                    if (match3)
                    {
                        if (this.props.float_numbers > 0)
                        {
                            this.props.onChange(match3[0]);
                        }
                    }
                }
            }*/
        }
        else if (this.props.mode === "text")
        {
            this.props.onChange(newValue);
        }
    }

    render()
    {
        return (
            <input
                {...this.props}
                type="text"
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={(e) => this.onChange(e.target.value)}
                className={"form-control col-12 " + this.props.className}
            />
        );
    }
}

export default InputComponent;