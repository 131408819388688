import React from "react";

class PoleZnachObertka extends React.Component
{
    render()
    {
        let spisok = this.props.children;
        let poleClass = this.props.poleClass;
        if (spisok.length === 0)
        {
            return null;
        }
        spisok = spisok.map((znach, index) =>
            <div
                className={"d-inline-flex gap-"+poleClass.gap}
                key={index}
            >
                {znach}{index < spisok.length - 1 && poleClass.razd}
            </div>
        );
        return (
            <div className="d-flex flex-row flex-wrap col-6 p-1 gap-1">
                {spisok}
            </div>
        );
    }
}

export default PoleZnachObertka;