import React from 'react';
import Pole from '../class/Pole';
import { Eye } from 'react-bootstrap-icons';
import SelectComponent from '../components/selectComponent';
import OtkrComponent from '../components/otkrComponent';
import Obertka from './Obertka';
import WarningComponent from '../components/warningComponent';

class PoleShow extends Pole
{
    static pole = "show";
    static defaultValue = false;
    render()
    {
        if (!this.props.redSost)
        {
            return null;
        }
        let spisok_znach = [
            [false, "Не опубликовано"],
            [true, "Опубликовано"],
        ];
        let pole = this.constructor.pole;
        let warn = null;
        if (this.props.object._id !== null)
        {
            let oldShow = this.props.objectIsh[pole];
            let newShow = this.props.object[pole];
            if (oldShow && !newShow)
            {
                warn = <WarningComponent
                    zagolovok="Снято с публикации"
                    text="Ссылки на карточку будут потеряны"
                />
            }
        }
        return (
            <Obertka text="Публикация" icon={<Eye />} {...this.props}>
                {warn}
                <OtkrComponent
                    zagolovok={spisok_znach.find(x => x[0] === this.props.object[pole])[1]}
                >
                    <SelectComponent
                        items={spisok_znach}
                        getText={x => x[1]}
                        getID={x => x[0]}
                        isSelected={x => this.props.object[pole] === x[0]}
                        onClick={x =>
                        {
                            let spisok = this.props.object[pole];
                            spisok = x[0];
                            this.props.onChangePole(pole, spisok);
                            return "zakr";
                        }}
                        multiSelect={false}
                        validError={false}
                        napr="column"
                    />
                </OtkrComponent>
            </Obertka>
        );
    }
}

export default PoleShow;