import { Collection, FileEarmarkArrowDown, Folder, ListColumns } from 'react-bootstrap-icons';
import Pole from '../class/Pole';
import Obertka from './Obertka';
import SelectComponent from '../components/selectComponent';
import OtkrComponent from '../components/otkrComponent';

class PoleCategory extends Pole
{
    static pole = "category";

    static defaultValue = null;

    static preobr_object(db, object)
    {
        if (object[this.pole] !== null)
        {
            if (db.category.find(x => x.time === object[this.pole]) === undefined)
            {
                object[this.pole] = null;
            }
        }
        return object;
    }

    render()
    {
        let pole = this.constructor.pole;
        if (this.props.redSost)
        {
            let zagolovok = <small className="fst-italic text-muted">Не выбрана</small>;
            if (this.props.object[pole] !== null)
            {
                zagolovok = this.props.db.category.find(x => x.time === this.props.object[pole]).name;
            }
            return (
                <Obertka text="Категория" icon={<Folder />} {...this.props}>
                    <OtkrComponent
                        zagolovok={zagolovok}
                    >
                        <SelectComponent
                            items={this.props.db.category}
                            getText={x => x.name}
                            getID={x => x.time}
                            isSelected={x => this.props.object[pole] === x.time}
                            isArchive={x =>
                            {
                                return !x.active
                            }}
                            onClick={x =>
                            {
                                let spisok = this.props.object[pole];
                                if (this.props.object[pole] === x.time)
                                {
                                    spisok = null;
                                }
                                else
                                {
                                    spisok = x.time;
                                }
                                this.props.onChangePole(pole, spisok);
                            }}
                            multiSelect={false}
                            validError={false}
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            if (this.props.object[pole] == null)
            {
                return null;
            }
            if (this.props.db.category.find(x => x.time === this.props.object[pole]).visible === false)
            {
                return null;
            }
            return (
                <Obertka text="Категория" {...this.props}>
                    {this.props.db.category.find(x => x.time === this.props.object[pole]).name}
                </Obertka>
            );
        }
    }
}

export default PoleCategory;