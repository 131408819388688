import React from "react";
import { ArrowDown, ArrowRepeat, ArrowUp, CardImage, CardText, CaretUpFill, ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft, ChevronRight, Clock, Database, Download, Pencil, Trash3, Type, XLg } from "react-bootstrap-icons";
import Photo from "./photo";
import MenuComponent from "./components/menuComponent";
import MenuItemComponent from "./components/menuItemComponent";
import OtkrComponent from "./components/otkrComponent";

class FileInSpisok extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            sost: "zakr",
            newName: null,
            new_file: null,
        };
        this.rename = this.rename.bind(this);
        this.delete = this.delete.bind(this);
        this.change = this.change.bind(this);
    }

    async change()
    {
        async function readFile(file)
        {
            const fileReader = new FileReader();
            const arrayBuffer = await new Promise((resolve, reject) =>
            {
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.onerror = () => reject(new Error('Error reading file'));
                fileReader.readAsArrayBuffer(file);
            });
            const byteString = Array.prototype.map.call(new Uint8Array(arrayBuffer), (item) => ('00' + item.toString(16)).slice(-2)).join('');
            return byteString;
        }
        await this.props.zapros({ "type": "change_file", "file_id": this.props.file.name.split(".")[0], "newFileBytes": await readFile(this.state.new_file), "newFileName": this.state.new_file.name });
        window.location.reload();
    }

    async rename()
    {
        await this.props.zapros({ "type": "rename_file", "new_name": this.state.newName, "file_id": this.props.file.name.split(".")[0] });
        window.location.reload();
    }

    async delete()
    {
        await this.props.zapros({ "type": "delete_file", "file_id": this.props.file.name.split(".")[0] });
        window.location.reload();
    }

    render()
    {
        let icon = <CardText />
        let formats = ["png", "jpg", "jpeg", "gif", "svg", "webp"];
        if (formats.some(x => this.props.file.name.split(".")[this.props.file.name.split(".").length - 1].toLowerCase() === x))
        {
            icon = <CardImage />
        }


        let errorName = false;
        if (this.state.newName !== null)
        {
            let newName = this.state.newName.trim();
            if (this.state.newName.length < 3)
            {
                errorName = true;
            }
            if (newName[newName.length - 1] === ".")
            {
                errorName = true;
            }
            if (newName.indexOf(".") === -1)
            {
                errorName = true;
            }
            if (newName.split(".")[0].trim() === "")
            {
                errorName = true;
            }
            if (newName.split(".")[newName.split(".").length - 1].trim() === "")
            {
                errorName = true;
            }
            if (newName.split(".")[newName.split(".").length - 1].indexOf(" ") !== -1)
            {
                errorName = true;
            }
        }
        let menu = <MenuComponent>
            <li>
                <a
                    className="dropdown-item text-primary"
                    href={this.props.adress + "/download/" + this.props.file.name.split(".")[0]}
                >
                    <Download /> Скачать
                </a>
            </li>
            <MenuItemComponent
                onClick={() => this.setState({ sost: "rename", newName: this.props.file.name.split(".").slice(1).join(".") })}
            >
                <Pencil /> Переименовать
            </MenuItemComponent>
            <MenuItemComponent
                onClick={() => this.setState({ sost: "change", new_file: null })}
            >
                <ArrowRepeat /> Заменить
            </MenuItemComponent>
            <MenuItemComponent
                className="text-danger"
                onClick={() => this.setState({ sost: "delete" })}
            >
                <Trash3 /> Удалить
            </MenuItemComponent>
        </MenuComponent>

        if (this.state.sost === "zakr" || this.state.sost === "otkr")
        {
            return (
                <OtkrComponent
                    zagolovok={this.props.file.name.split(".").slice(1).join(".")}
                    icon={icon}
                    menu={menu}
                >
                    <div className="d-flex flex-row gap-0 p-2 flex-wrap">
                        <Photo mode="files" file={this.props.file.name} adress={this.props.adress} />
                        <div className="d-flex flex-column col-9 px-1">
                            <small className="text-muted">
                                ID {this.props.file.name.split(".")[0]}
                            </small>
                            <span>
                                {(this.props.file.size / 1024).toFixed(1)} КБ
                            </span>
                            <span>
                                {String(new Date(this.props.file.lastModified).getDate()).padStart(2, '0') +
                                    "." +
                                    String(new Date(this.props.file.lastModified).getMonth() + 1).padStart(2, '0') +
                                    "." +
                                    String(new Date(this.props.file.lastModified).getFullYear()) +
                                    " " +
                                    String(new Date(this.props.file.lastModified).getHours()).padStart(2, '0') +
                                    ":" +
                                    String(new Date(this.props.file.lastModified).getMinutes()).padStart(2, '0') +
                                    ":" +
                                    String(new Date(this.props.file.lastModified).getSeconds()).padStart(2, '0')
                                }
                            </span>
                        </div>
                    </div>
                </OtkrComponent>
            );
        }
        else if (this.state.sost === "otkr")
        {
            return (
                <div className="d-flex flex-column bg-light py-1 px-2 rounded-3 gap-2">
                    <div className="d-flex flex-row gap-1 align-items-center px-2">
                        {icon}
                        <span className="flex-grow-1">
                            {this.props.file.name.split(".").slice(1).join(".")}
                        </span>
                        <button className="btn btn-light rounded-pill" onClick={() => this.setState({ sost: "zakr" })}>
                            <CaretUpFill size="1.2rem" />
                        </button>
                        {menu}
                    </div>

                    <div className="d-flex flex-row gap-0 p-2 flex-wrap">
                        <Photo mode="files" file={this.props.file.name} adress={this.props.adress} />
                        <div className="d-flex flex-column col-9 px-1">
                            <small className="text-muted">
                                ID {this.props.file.name.split(".")[0]}
                            </small>
                            <span>
                                {(this.props.file.size / 1024).toFixed(1)} КБ
                            </span>
                            <span>
                                {String(new Date(this.props.file.lastModified).getDate()).padStart(2, '0') +
                                    "." +
                                    String(new Date(this.props.file.lastModified).getMonth() + 1).padStart(2, '0') +
                                    "." +
                                    String(new Date(this.props.file.lastModified).getFullYear()) +
                                    " " +
                                    String(new Date(this.props.file.lastModified).getHours()).padStart(2, '0') +
                                    ":" +
                                    String(new Date(this.props.file.lastModified).getMinutes()).padStart(2, '0') +
                                    ":" +
                                    String(new Date(this.props.file.lastModified).getSeconds()).padStart(2, '0')
                                }
                            </span>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.state.sost === "rename")
        {
            return (
                <div className="d-flex flex-row bg-light py-1 pe-3 ps-1 rounded-3 align-items-center gap-1">
                    <input className={"form-control" + (errorName ? " bg-warning" : "")} type="text" value={this.state.newName} placeholder="Наименование" onChange={(e) =>
                    {
                        this.setState({ newName: e.target.value });
                    }
                    } />
                    <button className="btn btn-warning rounded-pill flex-nowrap d-flex align-items-center gap-1" onClick={this.rename} disabled={errorName}>
                        <Pencil size="1.2rem" /> Переименовать
                    </button>
                    <button className="btn btn-outline-primary rounded-pill" onClick={() => this.setState({ sost: "zakr" })}>
                        Отмена
                    </button>
                </div>
            );
        }
        else if (this.state.sost === "delete")
        {
            return (
                <div className="d-flex flex-row bg-light py-1 px-3 rounded-3 align-items-center gap-1">
                    <span className="flex-grow-1">
                        {this.props.file.name.split(".").slice(1).join(".")}
                    </span>
                    <button className="btn btn-danger rounded-pill" onClick={this.delete}>
                        <Trash3 size="1.2rem" /> Удалить
                    </button>
                    <button className="btn btn-outline-primary rounded-pill" onClick={() => this.setState({ sost: "zakr" })}>
                        Отмена
                    </button>
                </div>
            );
        }
        else if (this.state.sost === "change")
        {
            return (
                <div className="d-flex flex-row bg-light py-1 px-3 rounded-3 align-items-center gap-1">
                    <input type="file" onChange={(e) => this.setState({ new_file: e.target.files[0] })} className="form-control" placeholder="Файл для замены" />
                    <button className="btn btn-warning rounded-pill flex-nowrap d-flex align-items-center gap-1" onClick={this.change} disabled={this.state.new_file === null}>
                        <ArrowRepeat size="1.2rem" /> Заменить
                    </button>
                    <button className="btn btn-outline-primary rounded-pill" onClick={() => this.setState({ sost: "zakr" })}>
                        Отмена
                    </button>
                </div>
            );
        }

    }
}

class SpisokFiles extends React.Component
{
    constructor(props)
    {
        super(props);
        this.sortArray =
            [
                ["name", <Type size="1.2rem" />, (a, b) => a.name.split(".").slice(1).join(".").localeCompare(b.name.split(".").slice(1).join("."))],
                ["time", <Clock size="1.2rem" />, (a, b) => b.lastModified - a.lastModified],
                ["size", <Database size="1.2rem" />, (a, b) => b.size - a.size]
            ];
        this.state =
        {
            page: 1,
            search: "",
            sort: "time",
            sortNapr: true,
            sortFunction: this.sortArray[1][2],
        };
    }

    render()
    {
        if ((this.props.files === undefined) || (this.props.files.length === 0))
        {
            return (
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-center text-muted">
                    Нет файлов
                </div>
            )
        }
        let currentFiles = this.props.files;
        currentFiles = currentFiles.sort((a, b) => this.state.sortNapr ? this.state.sortFunction(a, b) : this.state.sortFunction(b, a));
        if (this.state.search.trim() !== "")
        {
            currentFiles = currentFiles.filter(x => x.name.split(".").slice(1).join(".").toLowerCase().includes(this.state.search.toLowerCase()));
        }
        let maxPage = Math.ceil(currentFiles.length / this.props.pageSize);
        if (maxPage === 0)
        {
            maxPage = 1;
        }
        currentFiles = currentFiles.slice((this.state.page - 1) * this.props.pageSize, this.state.page * this.props.pageSize);
        return (
            <React.Fragment>
                Файлы на сервере: {this.props.files.length}
                <div className="d-flex flex-row gap-2">
                    <input type="text" className="form-control" placeholder="Поиск" onChange={(e) => this.setState({ search: e.target.value, page: 1 })} value={this.state.search} />
                    {
                        this.state.search !== "" &&
                        <button className="btn btn-light border" onClick={() => this.setState({ search: "", page: 1 })}>
                            <XLg size="1.2rem" />
                        </button>
                    }
                </div>
                <div className="d-flex flex-row gap-0">
                    {this.sortArray.map((item, index) =>
                        <div key={index} className="col-4 d-flex px-1">
                            <button
                                className={"btn d-flex btn-light col-12 justify-content-center " + (this.state.sort === item[0] ? "active" : "")}
                                onClick={() => this.setState({ sort: item[0], sortNapr: this.state.sort === item[0] ? !this.state.sortNapr : true, sortFunction: item[2] })}
                            >
                                {item[1]}
                                {this.state.sort === item[0] && (this.state.sortNapr ? <ArrowDown size="1.2rem" /> : <ArrowUp size="1.2rem" />)}
                            </button>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center gap-2">
                    <button disabled={this.state.page === 1} onClick={() => this.setState({ page: 1 })} className="d-flex flex-row align-items-center justify-content-center btn btn-light">
                        <ChevronDoubleLeft size="1.2rem" />
                    </button>
                    <button disabled={this.state.page === 1} onClick={() => this.setState({ page: this.state.page - 1 })} className="d-flex flex-row align-items-center justify-content-center btn btn-light">
                        <ChevronLeft size="1.2rem" />
                    </button>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        {this.state.page} из {maxPage}
                    </div>
                    <button disabled={this.state.page === maxPage} onClick={() => this.setState({ page: this.state.page + 1 })} className="d-flex flex-row align-items-center justify-content-center btn btn-light">
                        <ChevronRight size="1.2rem" />
                    </button>
                    <button disabled={this.state.page === maxPage} onClick={() => this.setState({ page: maxPage })} className="d-flex flex-row align-items-center justify-content-center btn btn-light">
                        <ChevronDoubleRight size="1.2rem" />
                    </button>
                </div>
                {currentFiles.length > 0
                    ? currentFiles.map((file, index) =>
                        <FileInSpisok
                            key={index}
                            file={file}
                            zapros={this.props.zapros}
                            adress={this.props.adress}
                        />)
                    : "Файлы не найдены"
                }
            </React.Fragment>
        );
    }
}

class RedFiles extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            files: null,
            new_files: null,
            search: "",
            sort: "time",
            sortNapr: true,
            sortFunction: (a, b) => b.lastModified - a.lastModified,
        }
        this.zagr_files = this.zagr_files.bind(this);
        this.zagr_new_files = this.zagr_new_files.bind(this);
    }

    componentDidMount()
    {
        this.zagr_files();
    }

    async zagr_new_files(e)
    {
        e.preventDefault();
        this.setState({ files: null });
        await this.props.uploadFiles(this.state.new_files);
        window.location.reload();
    }

    async zagr_files()
    {
        const request = new Request(this.props.adress + '/spisok_files', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: null,
        });

        let a = await fetch(request);
        let spisok = await a.json();
        this.setState({ files: spisok });
    }

    render()
    {
        if (this.state.files === null)
        {
            return (
                <div className="spinner-border container" role="status">
                    <span className="visually-hidden">Загрузка...</span>
                </div>
            );
        }
        return (
            <div className="d-flex flex-column gap-2">
                Загрузка файлов
                <form onSubmit={this.zagr_new_files} className="d-flex flex-row gap-2">
                    <input type="file" className="form-control" onChange={(e) => this.setState({ new_files: e.target.files })} multiple />
                    <button type="submit" className="btn btn-primary" disabled={this.state.new_files === null}>Загрузить</button>
                </form>
                <SpisokFiles
                    pageSize={10}
                    files={this.state.files}
                    zapros={this.props.zapros}
                    adress={this.props.adress}
                />
            </div>
        );
    }
}

export default RedFiles;