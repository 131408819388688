import React from "react"

import { NavLink } from "react-router-dom";
import RedCats from "./red_cats";
import RedGroups from "./red_groups";
import RedDB from "./red_db";
import RedFiles from "./red_files";
import UprServer from "./upr_server";

class Admin extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            f: null,
            zagruzka: false,
            oshibka: false,
            spisok_photo: null,
            new_izobr: null,
            vkladka: null,
        }
        this.otpr = this.otpr.bind(this);
        this.otpr_izobr = this.otpr_izobr.bind(this);
        this.spisok_photo = this.spisok_photo.bind(this);
    }
    async spisok_photo()
    {
        const request = new Request(this.props.adress + '/spisok_files', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: null,
        });

        let a = await fetch(request);
        let spisok = await a.json();
        this.setState({ spisok_photo: spisok });
    }
    async componentDidMount()
    {
        this.spisok_photo();
    }
    otpr(e)
    {
        e.preventDefault();
        const file = this.state.f;

        const reader = new FileReader();
        reader.onloadend = () =>
        {
            let fileContent = reader.result;
            const byteString = Array.prototype.map.call(new Uint8Array(fileContent), (item) => ('00' + item.toString(16)).slice(-2)).join('');
            this.props.zapros({ "type": "file", "file": byteString });
        };

        reader.readAsArrayBuffer(file);
    }
    async otpr_izobr(e)
    {
        e.preventDefault();
        async function readFile(file)
        {
            const fileReader = new FileReader();
            const arrayBuffer = await new Promise((resolve, reject) =>
            {
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.onerror = () => reject(new Error('Error reading file'));
                fileReader.readAsArrayBuffer(file);
            });
            const byteString = Array.prototype.map.call(new Uint8Array(arrayBuffer), (item) => ('00' + item.toString(16)).slice(-2)).join('');
            return byteString;
        }
        const files = this.state.new_izobr;
        let byteStrings = [];
        console.log(files);
        for (const file of files)
        {
            byteStrings.push({ bytes: await readFile(file), name: file.name });
        }
        console.log(byteStrings);
        this.zapros({ "type": "new_files", "files": byteStrings });
    }
    render()
    {
        if (this.state.zagruzka)
            return (
                <div>
                    Загрузка...
                </div>
            );
        return (
            <div className="col-xl-8 d-flex flex-column gap-1 container justify-content-center">
                <div className="d-flex flex-sm-row flex-column gap-2">
                    <div className="d-flex flex-column col-sm-4 col-12 gap-1 p-0">
                        <div className="d-flex flex-column gap-1 p-2 rounded-3 border">
                            <button className="btn btn-outline-danger rounded col-12 text-start" onClick={this.props.vyhod}>
                                Выйти
                            </button>
                            {["Категории", "Характеристики", "Файлы", "База данных", "Управление сервером"].map((item, index) =>
                                <NavLink key={index} className={({ isActive }) => isActive ? "btn btn-secondary rounded col-12 text-start" : "btn btn-outline-secondary rounded col-12 text-start"} to={"/админка/" + item.toLowerCase() + "/"}>
                                    {item}
                                </NavLink>
                            )}
                        </div>
                    </div>
                    <div className="d-flex flex-column col-sm-8 col-12 col-3 gap-1 p-2 rounded-3 border">
                        {this.props.link.vkladka === "категории" && <RedCats adress={this.props.adress} zapros={this.props.zapros} />}
                        {this.props.link.vkladka === "характеристики" && <RedGroups adress={this.props.adress} zapros={this.props.zapros} />}
                        {this.props.link.vkladka === "база данных" &&
                            <RedDB
                                adress={this.props.adress}
                                zapros={this.props.zapros}
                                category={this.props.category}
                                groups={this.props.groups}
                                catalog={this.props.catalog}
                                catalog_proizv={this.props.catalog_proizv}
                            />
                        }
                        {this.props.link.vkladka === "файлы" &&
                            <RedFiles
                                adress={this.props.adress}
                                zapros={this.props.zapros}
                                uploadFiles={this.props.uploadFiles}
                            />
                        }
                        {this.props.link.vkladka === "управление сервером" &&
                            <UprServer
                                {...this.props}
                            />
                        }
                    </div>
                </div>
                <small className="text-muted col-xl-8 d-flex">
                    Версия 1.0.2
                </small>
            </div>

        );
    }
}

export default Admin;