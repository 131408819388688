import React from 'react';
import PoleHar from '../polya/PoleHar';
import PoleKomp from '../polya/PoleKomp';

class PoleType extends React.Component
{
    static razd = " / ";
    static gap = "1";

    static getRedPodpis(pole)
    {
        return "";
    }

    static preobr_object(db, object, preobr_function)
    {
        function current_preobr_function(db, object, preobr_function, harObject)
        {
            let objectGroups = harObject;
            for (let groupID in objectGroups)
            {
                let dbGroup = db.groups.find(group => group.time === groupID);
                if (dbGroup === undefined)
                {
                    return object;
                }
                let objectGroup = objectGroups[groupID];
                for (let poleID in objectGroups[groupID])
                {
                    let dbPole = dbGroup.polya.find(pole => pole.time === poleID);
                    if (dbPole === undefined)
                    {
                        return object;
                    }
                    let objectPole = objectGroup[poleID];
                    object = preobr_function(dbPole, objectPole);
                }
            }
            return object;
        }
        object = current_preobr_function(db, object, preobr_function, object[PoleHar.pole]);
        if (object[PoleKomp.pole] !== undefined)
        {
            for (let komp of object[PoleKomp.pole])
            {
                if (komp.time !== -1)
                {
                    object = current_preobr_function(db, object, preobr_function, komp.har);
                }
            }
        }
        return object;
    }

    constructor(props)
    {
        super(props);
        this.redRender = this.redRender.bind(this);
        this.prosmRender = this.prosmRender.bind(this);
    }

    redRender()
    {
        return null;
    }

    prosmRender()
    {
        return null;
    }

    render()
    {
        if (this.props.redSost)
        {
            return this.redRender();
        }
        else
        {
            return this.prosmRender();
        }
    }
}

export default PoleType;