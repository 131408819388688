import React from 'react';
import Pole from '../class/Pole';
import { Building, Buildings, Eye } from 'react-bootstrap-icons';
import SelectComponent from '../components/selectComponent';
import OtkrComponent from '../components/otkrComponent';
import Obertka from './Obertka';

class PoleProizvType extends Pole
{
    static pole = "proizv_type";

    static defaultValue = [];

    static getValidError(db, object)
    {
        if (object[this.pole].length === 0)
        {
            return true;
        }
        return false;
    };

    render()
    {
        let spisok_znach = [
            ["razr", "Разработчик"],
            ["zavod", "Производитель"],
        ];
        let pole = this.constructor.pole;
        let validError = this.constructor.getValidError(this.props.db, this.props.object);
        let zagolovok = this.props.object[pole].map(y => spisok_znach.find(x => x[0] === y)[1]).sort().join(", ")
        if (this.props.object[pole].length === 0)
        {
            zagolovok = <small className="fst-italic text-muted">Тип не выбран</small>;
        }
        if (this.props.redSost)
        {
            return (
                <Obertka
                    text="Тип компании"
                    icon={<Buildings />}
                    validError={validError}
                    {...this.props}
                >
                    <OtkrComponent
                        zagolovok={zagolovok}
                        validError={validError}
                    >
                        <SelectComponent
                            items={spisok_znach}
                            getText={x => x[1]}
                            getID={x => x[0]}
                            isSelected={x => this.props.object[pole].includes(x[0])}
                            onClick={x =>
                            {
                                let spisok = this.props.object[pole];
                                if (spisok.includes(x[0]))
                                {
                                    spisok.splice(spisok.indexOf(x[0]), 1);
                                }
                                else
                                {
                                    spisok.push(x[0]);
                                }
                                this.props.onChangePole(pole, spisok);
                            }}
                            multiSelect={true}
                            validError={validError}
                            napr="column"
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            if (this.props.object[pole].length === 0)
            {
                return null;
            }
            return (
                <Obertka
                    text="Тип компании"
                    {...this.props}
                >
                    {zagolovok}
                </Obertka>
            );
        }
    }
}

export default PoleProizvType;