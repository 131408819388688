import React from 'react';
import Obertka from './Obertka';
import OtkrComponent from '../components/otkrComponent';
import SpisokComponent from '../components/spisokComponent';
import { Bag, Person } from 'react-bootstrap-icons';
import SsylkaComponent from '../components/ssylkaComponent';
import preobr_url from '../func/preobr_url';
import Pole from '../class/Pole';
import PolePrice from './PolePrice';
import PoleKomp from './PoleKomp';

class Plashka extends React.Component
{
    render()
    {
        return (
            <div className="d-inline-flex">
                <div className={"d-inline-flex p-1 px-2 rounded-pill bg-" + this.props.bg + (this.props.text !== undefined ? " text-" + this.props.text : "")}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

class PoleRoznitsa extends Pole
{
    static pole = "rozn";

    static defaultValue = {
        shop: [],
        avito: [],
    };

    static preobr_object(db, object)
    {
        object[this.pole].shop = object[this.pole].shop.map(x => (
            {
                ...x,
                komp: x.komp === undefined ? null : x.komp,
            }
        ));
        object[this.pole].shop = object[this.pole].shop.map(x => (
            {
                ...x,
                komp: object[PoleKomp.pole].find(y => y.time === x.komp) === undefined ? null : x.komp,
            }
        ));
        object[this.pole].avito = object[this.pole].avito.map(x => (
            {
                ...x,
                komp: x.komp === undefined ? null : x.komp,
            }
        ));
        object[this.pole].avito = object[this.pole].avito.map(x => (
            {
                ...x,
                komp: object[PoleKomp.pole].find(y => y.time === x.komp) === undefined ? null : x.komp,
            }
        ));
        return object;
    }

    static getValidError(db, object)
    {
        if (object[this.pole].shop.some(x => x.link.trim() === ""))
        {
            return true;
        }
        if (object[this.pole].avito.some(x => x.link.trim() === ""))
        {
            return true;
        }
        return false;
    }

    render()
    {
        let pole = PoleRoznitsa.pole;
        if (this.props.redSost)
        {
            let zagolovok_shop = <small className="fst-italic text-muted">Нет ссылок</small>;
            if (this.props.object[pole].shop.length > 0)
            {
                zagolovok_shop = <React.Fragment>Ссылок: {this.props.object[pole].shop.length}</React.Fragment>;
            }
            let zagolovok_avito = <small className="fst-italic text-muted">Нет ссылок</small>;
            if (this.props.object[pole].avito.length > 0)
            {
                zagolovok_avito = <React.Fragment>Ссылок: {this.props.object[pole].avito.length}</React.Fragment>;
            }
            let types = [["Розничные предложения", "shop", zagolovok_shop, "Добавить ссылку", <Bag />], ["Объявления", "avito", zagolovok_avito, "Добавить объявление", <Person />]];
            return (
                <Obertka text="Розничная доступность" icon={<Bag />} {...this.props}>
                    {types.map((item, index) =>
                        <Obertka
                            key={index}
                            text={item[0]}
                            redStyle={true}
                            icon={item[4]}
                        >
                            <OtkrComponent
                                zagolovok={item[2]}
                                validError={this.props.object[pole][item[1]].some(x => x.link === "" || (x.price === "" && !x.pod_zakaz))}
                                key={index}
                            >
                                <SpisokComponent
                                    plusClick={() =>
                                    {
                                        let spisok = this.props.object[pole];
                                        spisok[item[1]].unshift({
                                            time: Date.now(),
                                            link: "",
                                            price: "",
                                            pod_zakaz: true,
                                            komp: null,
                                        });
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    plusText={item[3]}
                                    items={this.props.object[pole][item[1]]}
                                    otrisovka={(item2) =>
                                        <SsylkaComponent
                                            item={item2}
                                            key={item2.time}
                                            mode={item[1]}
                                            onChange={(pole2, value) =>
                                            {
                                                let spisok = this.props.object[pole];
                                                let ssylka = spisok[item[1]].find(x => x.time === item2.time);
                                                ssylka[pole2] = value;
                                                if (pole2 === "price")
                                                {
                                                    if (value === "")
                                                    {
                                                        ssylka["pod_zakaz"] = true;
                                                    }
                                                    else
                                                    {
                                                        ssylka["pod_zakaz"] = false;
                                                    }
                                                }
                                                this.props.onChangePole(pole, spisok);
                                                this.props.onChangePole(PolePrice.pole, PolePrice.getNewPrice(this.props.object));
                                            }}
                                            onDelete={() =>
                                            {
                                                let spisok = this.props.object[pole];
                                                let ssylkaIndex = spisok[item[1]].findIndex(x => x.time === item2.time);
                                                spisok[item[1]].splice(ssylkaIndex, 1);
                                                this.props.onChangePole(pole, spisok);
                                                this.props.onChangePole(PolePrice.pole, PolePrice.getNewPrice(this.props.object));
                                            }}
                                            {...this.props}
                                        />
                                    }
                                    getID={(item) => item.time}
                                    getText={(item) => item.link}
                                />
                            </OtkrComponent>
                        </Obertka>
                    )}
                </Obertka>
            );
        }
        else
        {
            let sortLinks = (array) => array.sort((a, b) => preobr_url(a.link).localeCompare(preobr_url(b.link)));
            let sortPrice = (array) => array.sort((a, b) => a.price - b.price);
            let sortFunction = (array) =>
            {
                return [...sortPrice(sortLinks(array.filter(x => !x.pod_zakaz))), ...sortLinks(array.filter(x => x.pod_zakaz))];
            }
            let shop_array = null;
            if (this.props.object[pole].shop.length > 0)
            {
                shop_array = this.props.object[pole].shop;
                shop_array = sortFunction(shop_array);
                if (this.props.objectState.currentKomp !== -1)
                {
                    shop_array = shop_array.filter(x => x.komp === this.props.objectState.currentKomp);
                }
                shop_array = shop_array.map((item, index) =>
                    <SsylkaComponent
                        item={item}
                        key={item.time}
                        mode={"shop"}
                        {...this.props}
                    />
                );
                if (shop_array.length === 0)
                {
                    shop_array = null;
                }
            }
            let avito_array = null;
            if (this.props.object[pole].avito.length > 0)
            {
                avito_array = this.props.object[pole].avito;
                avito_array = sortFunction(avito_array);
                if (this.props.objectState.currentKomp !== -1)
                {
                    avito_array = avito_array.filter(x => x.komp === this.props.objectState.currentKomp);
                }
                avito_array = avito_array.map((item, index) =>
                    <SsylkaComponent
                        item={item}
                        key={item.time}
                        mode={"avito"}
                        {...this.props}
                    />
                );
                if (avito_array.length === 0)
                {
                    avito_array = null;
                }
            }
            let plashka = <Plashka bg="secondary" text="white">Нет в рознице</Plashka>;
            if (shop_array !== null && this.props.object[pole].shop.some(x => !x.pod_zakaz))
            {
                plashka = <Plashka bg="primary" text="white">В рознице</Plashka>;
            }
            else if (avito_array !== null && this.props.object[pole].avito.some(x => !x.pod_zakaz))
            {
                plashka = <Plashka bg="warning" text="dark">В объявлениях</Plashka>;
            }
            else if (shop_array !== null || avito_array !== null)
            {
                plashka = <Plashka bg="info" text="white">Под заказ</Plashka>;
            }
            return (
                <Obertka text="Розничная доступность" {...this.props}>
                    {plashka}
                    {shop_array}
                    {avito_array}
                </Obertka>
            );
        }
    }
}

export default PoleRoznitsa;