let array = {
    q: "ю",
    w: "э",
    e: "е",
    r: "р",
    t: "т",
    y: "й",
    u: "у",
    i: "и",
    o: "о",
    p: "п",
    a: "а",
    s: "с",
    d: "д",
    f: "ф",
    g: "г",
    h: "х",
    k: "к",
    l: "л",
    z: "з",
    x: "ы",
    c: "ц",
    v: "в",
    b: "б",
    n: "н",
    m: "м",
};

const id_en_to_rus = id =>
{
    let rez = "";
    for (let i in id)
    {
        let z = array[id[i]];
        if (z != undefined)
        {
            rez += z;
        }
        else
        {
            rez += id[i];
        }
    }
    return rez;
}

export default id_en_to_rus;