import React from "react";
import { Archive, CaretDownFill, CaretUpFill, Eye, EyeSlash, Folder, ListUl, PlusLg, Trash3, XLg } from "react-bootstrap-icons";
import MenuComponent from "./components/menuComponent";
import MenuItemComponent from "./components/menuItemComponent";
import MenuItemRazdComponent from "./components/menuItemRazdComponent";
import OtkrComponent from "./components/otkrComponent";
import Obertka from "./polya/Obertka";
import SelectComponent from "./components/selectComponent";
import SpisokComponent from "./components/spisokComponent";
import WarningComponent from "./components/warningComponent";
import RedPole from "./red_pole";

class RedGroupInCat extends React.Component
{
    static getDefaultValue(group_time)
    {
        return {
            time: group_time,
            active: true,
            visible: true
        }
    }

    static preobr_db(db)
    {
        let groups = db.groups;
        let groups_times = groups.map(group => group.time);
        let categories = db.data;
        categories.forEach(category=>{
            let currentGroups = category.groups;
            let currentGroups_times = currentGroups.map(group => group.time);
            currentGroups_times.forEach(group_time => {
                if(!groups_times.includes(group_time))
                {
                    let t_cats = db.data.find(x=>x.time === category.time);
                    t_cats.groups = t_cats.groups.filter(group => group.time !== group_time);
                }
            });
        })
        return db;
    }

    constructor(props)
    {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.onUp = this.onUp.bind(this);
        this.onDown = this.onDown.bind(this);
        this.onArchive = this.onArchive.bind(this);
        this.toggleVisible = this.toggleVisible.bind(this);
    }

    onDelete()
    {
        let item = this.props.itemGroup;
        let group_index = this.props.category.groups.findIndex(x => x.time === item.time);
        let data = this.props.data;
        data[this.props.category_index].groups.splice(group_index, 1);
        this.props.setData(data);
    }

    onUp()
    {
        let item = this.props.itemGroup;
        let group_index = this.props.category.groups.findIndex(x => x.time === item.time);
        let data = this.props.data;
        let g = data[this.props.category_index].groups[group_index];
        data[this.props.category_index].groups.splice(group_index, 1);
        data[this.props.category_index].groups.splice(group_index - 1, 0, g);
        this.props.setData(data);
    }

    onDown()
    {
        let item = this.props.itemGroup;
        let group_index = this.props.category.groups.findIndex(x => x.time === item.time);
        let data = this.props.data;
        let g = data[this.props.category_index].groups[group_index];
        data[this.props.category_index].groups.splice(group_index, 1);
        data[this.props.category_index].groups.splice(group_index + 1, 0, g);
        this.props.setData(data);
    }

    onArchive()
    {
        let item = this.props.itemGroup;
        let group_index = this.props.category.groups.findIndex(x => x.time === item.time);
        let data = this.props.data;
        let g = data[this.props.category_index].groups[group_index];
        if (g.active)
        {
            g.active = false;
            g.visible = true;
            data[this.props.category_index].groups.splice(group_index, 1);
            data[this.props.category_index].groups.push(g);
        }
        else
        {
            g.active = true;
            g.visible = true;
            data[this.props.category_index].groups.splice(group_index, 1);
            data[this.props.category_index].groups.unshift(g);
        }
        this.props.setData(data);
    }

    toggleVisible()
    {
        let item = this.props.itemGroup;
        let group_index = this.props.category.groups.findIndex(x => x.time === item.time);
        let data = this.props.data;
        let g = data[this.props.category_index].groups[group_index];
        g.visible = !g.visible;
        this.props.setData(data);
    }
    
    render()
    {
        let item = this.props.itemGroup;
        let blockUp = this.props.category.groups.findIndex(x => x.time === item.time) === 0;
        let blockDown = this.props.category.groups.filter(x => x.active).findIndex(x => x.time === item.time) === this.props.category.groups.filter(x => x.active).length - 1;
        let itemInCategory = this.props.category.groups.find(x => x.time === item.time);
        let menu = <MenuComponent>
            <MenuItemComponent
                onClick={this.onUp}
                disabled={blockUp}
            >
                <CaretUpFill /> Выше
            </MenuItemComponent>
            <MenuItemComponent
                onClick={this.onDown}
                disabled={blockDown}
            >
                <CaretDownFill /> Ниже
            </MenuItemComponent>
            <MenuItemRazdComponent />
            <MenuItemComponent
                className="text-danger"
                onClick={this.onArchive}
            >
                <Archive /> Архивировать группу
            </MenuItemComponent>
        </MenuComponent>;
        let menu_archive = <MenuComponent>
            {item.visible && <MenuItemComponent
                onClick={this.toggleVisible}
            >
                {itemInCategory.visible
                    ? <React.Fragment>
                        <EyeSlash /> Скрыть
                    </React.Fragment>
                    : <React.Fragment>
                        <Eye /> Показать
                    </React.Fragment>
                }
            </MenuItemComponent>
            }
            {item.visible === false && <MenuItemComponent
                disabled={true}
                className="fst-italic"
            >
                Группа скрыта
            </MenuItemComponent>
            }
            <MenuItemComponent
                className="text-primary"
                onClick={this.onArchive}
            >
                <Archive /> Восстановить группу
            </MenuItemComponent>
            <MenuItemRazdComponent />
            <MenuItemComponent
                className="text-danger"
                onClick={this.onDelete}
            >
                <XLg /> Исключить группу
            </MenuItemComponent>
        </MenuComponent>;
        let zagolovok = this.props.itemGroup.name;
        if (!item.active)
        {
            zagolovok = <i>{zagolovok} (архив{!item.visible && ", скрыто"})</i>;
        }
        let icon3 = null;
        if (!itemInCategory.active)
        {
            if (!item.visible)
            {
                icon3 = <EyeSlash />;
            }
            else if (itemInCategory.visible)
            {
                icon3 = <Eye />;
            }
            else
            {
                icon3 = <EyeSlash />;
            }
        }
        return (
            <OtkrComponent
                zagolovok={zagolovok}
                icon={<ListUl />}
                icon2={!itemInCategory.active ? <Archive /> : null}
                icon3={icon3}
                key={this.props.itemGroup.time}
                menu={itemInCategory.active ? menu : menu_archive}
            >
                <SpisokComponent
                    items={item.polya}
                    getText={(item2) => item2.name}
                    getID={(item2) => item2.time}
                    otrisovka={(item2) =>
                        <OtkrComponent
                            bez_otkr={true}
                            key={item2.time}
                            zagolovok={item2.active ? item2.name : <i>{item2.name} (архив{!item2.visible && ", скрыто"})</i>}
                            icon={RedPole.getPoleTypeIcon(item2.type)}
                        />
                    }
                />
            </OtkrComponent >
        );
    }
}

class RedCat extends React.Component
{
    static getDefaultValue()
    {
        return {
            name: "",
            groups: [],
            time: Date.now().toString(),
            active: true,
            visible: true
        }
    }

    static getValidError(item)
    {
        if (item.name === "")
        {
            return true;
        }
        if (item.groups.filter(x => x.active).length === 0)
        {
            return true;
        }
        return false;
    }

    static preobr_db(db)
    {
        db = RedGroupInCat.preobr_db(db);
        return db;
    }

    constructor(props)
    {
        super(props);
        this.state = {
            search: "",
            otkr: this.props.category.name === "",
            dobMode: this.props.category.name === "",
        };
        this.onArchive = this.onArchive.bind(this);
        this.visibleToggle = this.visibleToggle.bind(this);
        this.onUnArchive = this.onUnArchive.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.getWarnArchive = this.getWarnArchive.bind(this);
    }

    onArchive()
    {
        let data = this.props.data;
        let v = data[this.props.category_index];
        v.active = false;
        v.visible = true;
        data.splice(this.props.category_index, 1);
        data.push(v);
        this.props.setData(data);
    }

    visibleToggle()
    {
        let data = this.props.data;
        data[this.props.category_index].visible = !data[this.props.category_index].visible;
        this.props.setData(data);
    }

    onUnArchive()
    {
        let data = this.props.data;
        let v = data[this.props.category_index];
        v.active = true;
        v.visible = true;
        data.splice(this.props.category_index, 1);
        data.splice(0, 0, v);
        this.props.setData(data);
    }

    onDelete()
    {
        let data = this.props.data;
        data.splice(this.props.category_index, 1);
        this.props.setData(data);
    }

    getWarnArchive()
    {
        let category = this.props.category;
        let oldCategory = this.props.oldData.find(x => x.time === this.props.category.time);
        if (oldCategory === undefined)
        {
            return null;
        }
        let oldGroups = oldCategory.groups;
        let currentGroups = category.groups;
        let oldArchiveGroups = oldGroups.filter(x => !x.active);
        let currentArchiveGroups = currentGroups.filter(x => !x.active);
        if (currentArchiveGroups.some(x => oldArchiveGroups.find(y => x.time === y.time) === undefined))
        {
            return <WarningComponent
                zagolovok="Группа архивирована!"
                text="Добавление группы в карточку объекта будет невозможно"
            />;
        }
        return null;
    }

    getWarnDelete()
    {
        let category = this.props.category;
        let oldCategory = this.props.oldData.find(x => x.time === this.props.category.time);
        if (oldCategory === undefined)
        {
            return null;
        }
        let oldGroups = oldCategory.groups;
        let currentGroups = category.groups;
        if (oldGroups.some(x => currentGroups.find(y => x.time === y.time) === undefined))
        {
            return <WarningComponent
                zagolovok="Группа исключена!"
                text="Введённые данные будут недоступны при просмотре"
            />;
        }
        return null;
    }

    render()
    {
        let menu_active = <MenuComponent>
            <MenuItemComponent
                className="text-danger"
                onClick={this.onArchive}
            >
                <Archive /> Архивировать категорию
            </MenuItemComponent>
        </MenuComponent>;
        let menu_archive = <MenuComponent>
            <MenuItemComponent
                onClick={this.visibleToggle}
            >
                {this.props.data[this.props.category_index].visible === true &&
                    <React.Fragment>
                        <EyeSlash /> Скрыть категорию
                    </React.Fragment>
                }
                {this.props.data[this.props.category_index].visible === false &&
                    <React.Fragment>
                        <Eye /> Показать категорию
                    </React.Fragment>
                }
            </MenuItemComponent>
            <MenuItemComponent
                className="text-primary"
                onClick={this.onUnArchive}
            >
                <Archive /> Восстановить категорию
            </MenuItemComponent>
            <MenuItemRazdComponent />
            <MenuItemComponent
                className="text-danger"
                onClick={this.onDelete}
            >
                <Trash3 /> Удалить категорию
            </MenuItemComponent>
        </MenuComponent>;
        let currentGroups = this.props.category.groups.filter(x => x.active).map(x => this.props.groups.find(y => y.time === x.time));
        let dostGroups = this.props.groups.filter(item => !this.props.category.groups.some(y => item.time === y.time) && item.active)
        let archiveGroups = this.props.category.groups.filter(x => !x.active).map(x => this.props.groups.find(y => y.time === x.time));
        let zagolovok = <small className="text-muted fst-italic">Группы не добавлены</small>;
        if (currentGroups.length > 0)
        {
            zagolovok = "Выбранные группы: " + currentGroups.length;
        }
        let zagolovok_dost = <small className="text-muted fst-italic">Все группы уже добавлены</small>;
        if (dostGroups.length > 0)
        {
            zagolovok_dost = "Доступные группы: " + dostGroups.length;
        }
        let warnDelete = this.getWarnDelete();
        let warnArchive = this.getWarnArchive();
        return (
            <OtkrComponent
                icon={<Folder />}
                icon2={this.props.category.active ? null : (this.props.category.visible === true ? <Eye /> : <EyeSlash />)}
                zagolovok={this.props.category.name}
                menu={this.props.category.active ? menu_active : menu_archive}
                validError={this.constructor.getValidError(this.props.category)}
            >
                {warnDelete}
                {warnArchive}
                <Obertka
                    text="Название"
                    redStyle={true}
                    validError={this.props.category.name === ""}
                >
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Название"
                        value={this.props.category.name}
                        onChange={(e) =>
                        {
                            let data = this.props.data;
                            data[this.props.category_index].name = e.target.value;
                            this.props.setData(data);
                        }}
                    />
                </Obertka>
                <Obertka
                    text="Группы"
                    redStyle={true}
                >
                    <OtkrComponent
                        zagolovok={zagolovok}
                        icon={<ListUl />}
                        pusto={currentGroups.length === 0}
                    >
                        <SpisokComponent
                            items={currentGroups}
                            getText={(item) => item.name}
                            getID={(item) => item.time}
                            otrisovka={(item) =>
                                <RedGroupInCat
                                    key={item.time}
                                    itemGroup={item}
                                    {...this.props}
                                />
                            }
                        />
                    </OtkrComponent>
                </Obertka>
                <Obertka
                    text="Добавление групп"
                    redStyle={true}
                    validError={currentGroups.length === 0}
                >
                    <OtkrComponent
                        zagolovok={zagolovok_dost}
                        icon={<PlusLg />}
                        pusto={dostGroups.length === 0}
                    >
                        <SelectComponent
                            items={dostGroups}
                            getText={(item) => item.name}
                            getID={(item) => item.time}
                            isSelected={(item) => this.props.category.groups.some(y => item.time === y)}
                            onClick={(item) =>
                            {
                                let data = this.props.data;
                                data[this.props.category_index].groups.unshift(RedGroupInCat.getDefaultValue(item.time));
                                this.props.setData(data);
                            }}
                            multiSelect={true}
                        />
                    </OtkrComponent>
                </Obertka>
                <OtkrComponent
                    icon={<Archive />}
                    zagolovok={archiveGroups.length > 0 ? ("Архивные группы: " + archiveGroups.length) : <small className="fst-italic text-muted">Архивные группы отсутствуют</small>}
                    pusto={archiveGroups.length === 0}
                >
                    <SpisokComponent
                        items={archiveGroups}
                        getText={(item) => item.name}
                        getID={(item) => item.time}
                        otrisovka={(item) =>
                            <RedGroupInCat
                                key={item.time}
                                itemGroup={item}
                                {...this.props}
                            />
                        }
                    />
                </OtkrComponent>
            </OtkrComponent>
        );
    }
}

export default RedCat;