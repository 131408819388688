import React from "react";
import { Link } from "react-router-dom";
import { CheckLg, ChevronLeft, ChevronRight, ExclamationLg, Pencil, Trash3, XLg } from "react-bootstrap-icons";
import TovarComponent from "./components/tovarComponent";
import ProizvComponent from "./components/proizvComponent";
import id_rus_to_en from "./func/id_rus_to_en";
import id_en_to_rus from "./func/id_en_to_rus";
import NoPage from "./noPage";
import WarningComponent from "./components/warningComponent";
import preobr_url from "./func/preobr_url";
class Tovar extends React.Component
{
    constructor(props)
    {
        super(props);
        let kartClass = null;
        switch (this.props.kart)
        {
            case "tovar":
                kartClass = TovarComponent;
                break;
            case "proizv":
                kartClass = ProizvComponent;
                break;
            default:
                return null;
        }
        let tovar_default = kartClass.getDefaultValues();
        let tovar = tovar_default;
        if (this.props.catalog !== null)
        {
            if (this.props.catalog.find(obj => obj._id === id_rus_to_en(this.props.props.nomer)) !== undefined)
            {
                let tovar_from_db = this.props.catalog.find(obj => obj._id === id_rus_to_en(this.props.props.nomer));
                Object.keys(tovar_from_db).map(a => tovar[a] = tovar_from_db[a]);
            }
        }
        let db = {
            catalog: this.props.catalog,
            proizv: this.props.catalog_proizv,
            groups: this.props.groups,
            category: this.props.category,
            serverFiles: this.props.serverFiles,
        };
        tovar = kartClass.preobr_object(db, tovar);
        tovar = JSON.parse(JSON.stringify(tovar));
        let tovar_ish = JSON.parse(JSON.stringify(tovar));
        this.state = {
            tovar: tovar,
            kartClass: kartClass,
            redSost: this.props.props.nomer === "новый",
            otmena: false,
            redFlag: false, // Были ли изменения
            otmenaTimer: null,
            delete: false,
            deleteTimer: null,
            tovar_ish: tovar_ish, //товар исходный
            catalog_files: null,
            validError: null,
            category: this.props.category,
            groups: this.props.groups,
        }
        if (tovar.photo !== undefined)
        {
            this.state.gallerySost = this.props.props.nomer === "новый" ? "red" : (tovar.photo.length > 4 ? "not_all" : "all");
            this.state.galleryPage = 0;
            this.state.gallerySearch = "";
            this.state.tovar.photo = this.state.tovar.photo.filter(a => this.props.serverFiles[a.file] !== undefined);
        }
        if (tovar.files !== undefined)
        {
            this.state.tovar.files = this.state.tovar.files.filter(a => this.props.serverFiles[a.file] !== undefined);
        }
        this.onChangePole = this.onChangePole.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.startRed = this.startRed.bind(this);
        this.endRed = this.endRed.bind(this);
        this.galleryToggle = this.galleryToggle.bind(this);
        this.gallerySearchChange = this.gallerySearchChange.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.valid = this.valid.bind(this);
        this.pageSize = 4;
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.props.nomer !== prevProps.props.nomer)
        {
            let kartClass = null;
            switch (this.props.kart)
            {
                case "tovar":
                    kartClass = TovarComponent;
                    break;
                case "proizv":
                    kartClass = ProizvComponent;
                    break;
                default:
                    return null;
            }
            let tovar_default = kartClass.getDefaultValues();
            let tovar = tovar_default;
            if (this.props.catalog !== null)
            {
                if (this.props.catalog.find(obj => obj._id === id_rus_to_en(this.props.props.nomer)) !== undefined)
                {
                    let tovar_from_db = this.props.catalog.find(obj => obj._id === id_rus_to_en(this.props.props.nomer));
                    Object.keys(tovar_from_db).map(a => tovar[a] = tovar_from_db[a]);
                }
            }
            let db = {
                catalog: this.props.catalog,
                proizv: this.props.catalog_proizv,
                groups: this.props.groups,
                category: this.props.category,
                serverFiles: this.props.serverFiles,
            };
            tovar = kartClass.preobr_object(db, tovar);
            tovar = JSON.parse(JSON.stringify(tovar));
            let tovar_ish = JSON.parse(JSON.stringify(tovar));
            let newState = {
                tovar: tovar,
                kartClass: kartClass,
                redSost: this.props.props.nomer === "новый",
                otmena: false,
                redFlag: false, // Были ли изменения
                otmenaTimer: null,
                delete: false,
                deleteTimer: null,
                tovar_ish: tovar_ish, //товар исходный
                catalog_files: null,
                validError: null,
                category: this.props.category,
                groups: this.props.groups,
            }
            if (tovar.photo !== undefined)
            {
                newState.gallerySost = this.props.props.nomer === "новый" ? "red" : (tovar.photo.length > 4 ? "not_all" : "all");
                newState.galleryPage = 0;
                newState.gallerySearch = "";
                newState.tovar.photo = newState.tovar.photo.filter(a => this.props.serverFiles[a.file] !== undefined);
            }
            if (tovar.files !== undefined)
            {
                newState.tovar.files = newState.tovar.files.filter(a => this.props.serverFiles[a.file] !== undefined);
            }
            this.setState(newState);
        }
    }

    async uploadFiles(files)
    {
        let files_otvet = await this.props.uploadFiles(files);
        this.setState({ gallerySost: this.state.gallerySost === "red_server" ? "red" : "red_server", "gallerySearch": "", galleryPage: 0, catalog_files: files_otvet });
        return true;
    }
    async gallerySearchChange(search)
    {
        this.setState({ gallerySearch: search, galleryPage: 0 });
    }
    async galleryToggle(param = null)
    {
        if (param === null)
        {
            this.setState({ gallerySost: this.state.gallerySost === "all" ? "not_all" : "all" });
        }
        else if (param === "red_upload")
        {
            this.setState({ gallerySost: this.state.gallerySost === "red_upload" ? "red" : "red_upload", "gallerySearch": "", galleryPage: 0 });
        }
        else if (param === "red_server")
        {
            this.setState({ gallerySost: this.state.gallerySost === "red_server" ? "red" : "red_server", "gallerySearch": "", galleryPage: 0 });
        }
        else if (param === "next_page")
        {
            this.setState({ galleryPage: this.state.galleryPage + 1 });
        }
        else if (param === "prev_page")
        {
            this.setState({ galleryPage: this.state.galleryPage - 1 });
        }
    }

    async startRed()
    {
        this.setState({ redSost: true, gallerySost: "red", redFlag: false, galleryPage: 0, gallerySearch: "", catalog_files: null });
        let otvet = await this.props.getCatalogFiles();
        this.setState({ catalog_files: otvet.files, category: otvet.category, groups: otvet.groups });
        this.valid();
    }
    async endRed()
    {
        if (this.state.tovar.photo !== undefined)
        {
            this.setState({ redSost: false, gallerySost: this.state.tovar.photo.length > 4 ? "not_all" : "all" });
        }
        else
        {
            this.setState({ redSost: false });
        }
    }
    valid()
    {
        let db = { catalog: this.props.catalog, proizv: this.props.catalog_proizv, groups: this.props.groups, category: this.props.category }
        let object = this.state.tovar;
        let validError = this.state.kartClass.getValidError(db, object);
        this.setState({ validError: validError });
    }
    onChangePole(pole, value)
    {
        let tovar = this.state.tovar;
        tovar[pole] = value;
        if (pole === "rozn")
        {
            let rozn_price = tovar[pole].shop.filter(x => x.price !== "" && x.price !== "pod_zakaz").length > 0
            let rozn_pod_zakaz = tovar[pole].shop.filter(x => x.price === "pod_zakaz").length > 0;
            let avito = tovar[pole].avito.filter(x => x.price !== "").length > 0;
            if (rozn_price)
            {
                let sred = tovar[pole].shop.filter(x => x.price !== "" && x.price !== "pod_zakaz").reduce((total, item) => total * 1 + item.price * 1, 0) / tovar[pole].shop.filter(x => x.price !== "").length;
                if (!isNaN(sred))
                {
                    tovar["price"] = Math.round(sred);
                }
                else
                {
                    tovar["price"] = "";
                }
            } else if (avito)
            {
                let sred = tovar[pole].avito.filter(x => x.price !== "").reduce((total, item) => total * 1 + item.price * 1, 0) / tovar[pole].avito.filter(x => x.price !== "").length;
                if (!isNaN(sred))
                {
                    tovar["price"] = Math.round(sred);
                }
                else
                {
                    tovar["price"] = "";
                }
            }
            else if (rozn_pod_zakaz)
            {
                tovar["price"] = "pod_zakaz";
            }
            else
            {
                tovar["price"] = "";
            }
        }
        this.valid();
        this.setState({ tovar: tovar, redFlag: true });
    }

    async save()
    {
        let tovar = this.state.tovar;
        tovar.timeAct = Math.floor(Date.now() / 1000);
        let tovar_id = await this.props.zapros({ "type": "save_" + this.props.kart, "data": tovar });
        switch (this.props.kart)
        {
            case "tovar":
                window.location.href = "/каталог/" + id_en_to_rus(tovar_id);
                break;
            case "proizv":
                window.location.href = "/производители/" + id_en_to_rus(tovar_id);
                break;
            default:
                return null;
        }
        this.endRed();
    }

    async delete()
    {
        await this.props.zapros({ "type": "delete_" + this.props.kart, "data": this.state.tovar._id });
        switch (this.props.kart)
        {
            case "tovar":
                window.location.href = "/каталог";
                break;
            case "proizv":
                window.location.href = "/производители";
                break;
            default:
                return null;
        }
    }
    componentDidMount()
    {
        this.valid();
        if (this.props.props.nomer === "новый")
        {
            this.startRed();
        }
    }
    render()
    {
        if (this.props.catalog.filter(x => x.show || this.props.admin).find(x => x._id === id_rus_to_en(this.props.props.nomer)) === undefined)
        {
            if (!this.props.admin)
            {
                return <NoPage />;
            }
            else
            {
                if (this.props.props.nomer !== "новый")
                {
                    return <NoPage />;
                }
            }
        }
        let photoFull = null;
        if (window.location.hash.length >= 2)
        {
            console.log("Искомое", decodeURIComponent(window.location.hash.slice(1)).split(".")[0]);
            console.log("Фото товара", this.state.tovar.photo);
            let indexPhoto = this.state.tovar.photo.findIndex(x => x.file === decodeURIComponent(window.location.hash.slice(1)).split(".")[0]);
            console.log("Индекс", indexPhoto);
            let pages = true;
            if (indexPhoto === -1)
            {
                pages = false;
            }

            photoFull =
                <div className="container p-1">
                    <div>
                        <a href="#" className="py-1 px-2 rounded-pill mx-1 my-2 btn btn-outline-secondary">
                            <ChevronLeft size="1.2rem" className="me-1" />
                            Назад
                        </a>
                    </div>
                    {pages &&
                        <React.Fragment>
                            <div className="col-12 justify-content-center align-items-center container d-flex gap-2 my-1">
                                <a href={"#" + (indexPhoto !== 0 ? this.props.serverFiles[this.state.tovar.photo[indexPhoto - 1].file] : "")} className={"btn btn-light border rounded-pill" + (indexPhoto === 0 ? " disabled" : "")}>
                                    <ChevronLeft size="1.2rem" />
                                </a>
                                {indexPhoto + 1} / {this.state.tovar.photo.length}
                                <a href={"#" + (indexPhoto < this.state.tovar.photo.length - 1 ? this.props.serverFiles[this.state.tovar.photo[indexPhoto + 1].file] : "")} className={"btn btn-light border rounded-pill" + (indexPhoto === this.state.tovar.photo.length - 1 ? " disabled" : "")}>
                                    <ChevronRight size="1.2rem" />
                                </a>
                            </div>
                            {this.state.tovar.photo[indexPhoto].podpis !== "" &&
                                <div className="col-12 justify-content-center container d-flex my-1">
                                    {this.state.tovar.photo[indexPhoto].podpis}
                                </div>
                            }
                            {this.state.tovar.photo[indexPhoto].ist !== "" &&
                                <small className="col-12 justify-content-center container d-flex my-1 text-muted d-flex flex-row gap-1">
                                    Источник: 
                                    <a href={this.state.tovar.photo[indexPhoto].ist} target="_blank" rel="noreferrer">
                                        {preobr_url(this.state.tovar.photo[indexPhoto].ist)}
                                    </a>
                                </small>
                            }
                        </React.Fragment>
                    }
                    <div className="col-12 justify-content-center container d-flex">
                        <a href="#">
                            <img src={this.props.adress + "/files/" + window.location.hash.slice(1)} className="img-fluid rounded" style={{ "maxHeight": "40rem", "maxWidth": "100%" }} />
                        </a>
                    </div>
                </div>;
        }
        let panel = null;
        let nazadButton =
            <Link to={this.props.nazad} className="py-1 px-2 rounded-pill mx-1 my-2 btn btn-outline-secondary">
                <ChevronLeft size="1.2rem" className="me-1" />
                {this.props.nazadText}
            </Link>;
        let nazadNewButton = <Link to={this.props.nazad} className="py-1 px-2 rounded-pill mx-1 my-2 btn btn-outline-secondary">
            <ChevronLeft size="1.2rem" className="me-1" />
            {this.props.nazadText}
        </Link>;
        let nazadNewRedButton = <button
            className="btn py-1 px-2 rounded-pill mx-1 my-2 btn-outline-danger"
            onClick={() =>
            {
                let timer = setTimeout(() =>
                {
                    this.setState({ otmena: false });
                }, 2000);
                this.setState({ otmenaTimer: timer, otmena: true });
            }
            }>
            <XLg size="1.2rem" className="me-1" />
            Отмена
        </button>;
        let nazadNewRedConfirmButton =
            <Link to={this.props.nazad} className="py-1 px-2 rounded-pill mx-1 my-2 btn btn-danger">
                <XLg size="1.2rem" className="me-1" />
                Отменить изменения?
            </Link>;
        let redactButton =
            <button className="btn btn-outline-primary py-1 px-2 rounded-pill mx-1 my-2" onClick={this.startRed}>
                <Pencil size="1.2rem" className="me-1" />
                Редактировать
            </button>;
        let sohrButton = null;
        if (this.state.validError !== null)
        {
            sohrButton = <button disabled={this.state.validError} className="btn btn-outline-primary py-1 px-2 rounded-pill mx-1 my-2" onClick={this.save}>
                {this.state.validError
                    ? <React.Fragment>
                        <ExclamationLg size="1.2rem" className="me-1" />
                        Ошибка заполнения
                    </React.Fragment>
                    : <React.Fragment>
                        <CheckLg size="1.2rem" className="me-1" />
                        Сохранить
                    </React.Fragment>}
            </button>;
        }

        let deleteButton =
            <button
                className={"btn py-1 px-2 rounded-pill mx-1 my-2 " + (this.state.delete ? "btn-danger" : "btn-outline-danger")}
                onClick={() =>
                {
                    if (this.state.delete)
                    {
                        this.delete();
                        if (this.state.deleteTimer !== null)
                        {
                            clearTimeout(this.state.deleteTimer);
                        }
                        this.setState({
                            deleteTimer: null,
                        });
                    }
                    else
                    {
                        let timer = setTimeout(() =>
                        {
                            this.setState({ delete: false });
                        }, 2000);
                        this.setState({ deleteTimer: timer, delete: true });
                    }
                }
                }>
                <Trash3 size="1.2rem" className="me-1" />
                {this.state.delete ? "Точно удалить?" : "Удалить"}
            </button>;
        let otmenaButton =
            <button className="btn btn-outline-secondary py-1 px-2 rounded-pill mx-1 my-2" onClick={this.endRed}>
                <ChevronLeft size="1.2rem" className="me-1" />
                Отмена
            </button>;
        let otmenaRedButton =
            <button
                className={"btn py-1 px-2 rounded-pill mx-1 my-2 " + (this.state.otmena ? "btn-danger" : "btn-outline-danger")}
                onClick={() =>
                {
                    if (this.state.otmena)
                    {
                        if (this.state.otmenaTimer !== null)
                        {
                            clearTimeout(this.state.otmenaTimer);
                        }
                        this.setState({
                            tovar: JSON.parse(JSON.stringify(this.state.tovar_ish)),
                            otmenaTimer: null,
                            otmena: false,
                        });
                        this.endRed();
                    }
                    else
                    {
                        let timer = setTimeout(() =>
                        {
                            this.setState({ otmena: false });
                        }, 2000);
                        this.setState({ otmenaTimer: timer, otmena: true });
                    }
                }
                }>
                {this.state.otmena
                    ? <React.Fragment>
                        <XLg size="1.2rem" className="me-1" />
                        Отменить изменения?
                    </React.Fragment>
                    : <React.Fragment>
                        <XLg size="1.2rem" className="me-1" />
                        Отмена
                    </React.Fragment>
                }
            </button>;
        if (this.state.redSost)
        {
            if (this.props.props.nomer === "новый")
            {
                if (this.state.redFlag)
                {
                    panel = <React.Fragment>
                        {this.state.otmena ? nazadNewRedConfirmButton : nazadNewRedButton}
                        {sohrButton}
                    </React.Fragment>;
                }
                else
                {
                    panel = <React.Fragment>
                        {nazadNewButton}
                        {sohrButton}
                    </React.Fragment>;
                }
            }
            else
            {

                panel = <React.Fragment>
                    {this.state.redFlag ? otmenaRedButton : otmenaButton}
                    {sohrButton}
                    {deleteButton}
                </React.Fragment>;
            }

        }
        else
        {
            panel = <React.Fragment>
                {nazadButton}
                {redactButton}
            </React.Fragment>;

        }
        let currentServerFiles = this.state.catalog_files;
        if (currentServerFiles !== null)
        {
            let t = {};
            currentServerFiles = currentServerFiles.forEach(item => t[item.split(".")[0]] = item);
            currentServerFiles = t;
        }
        else
        {
            currentServerFiles = this.props.serverFiles;
        }
        return (
            <React.Fragment>
                {!this.state.redSost && !this.state.tovar.show &&
                    <div className="container">
                        <WarningComponent
                            zagolovok="Карточка не опубликована!"
                            text="Её видят только администраторы"
                        />
                    </div>
                }
                {window.location.hash.length >= 2 && photoFull}
                {window.location.hash.length <= 1 &&
                    <div className="container">
                        {this.props.admin && panel}
                        {this.state.tovar !== null &&
                            <div className="d-flex flex-wrap justify-content-center flex-column container px-0">
                                <this.state.kartClass
                                    db={{
                                        catalog: this.props.catalog,
                                        proizv: this.props.catalog_proizv,
                                        groups: this.props.groups,
                                        category: this.props.category
                                    }}
                                    object={this.state.tovar}
                                    objectIsh={this.state.tovar_ish}
                                    onChangePole={this.onChangePole}
                                    redSost={this.state.redSost}
                                    adress={this.props.adress}
                                    serverFiles={currentServerFiles}
                                    uploadFiles={this.uploadFiles}
                                    galleryToggle={this.galleryToggle}
                                    gallerySost={this.state.gallerySost}
                                />
                            </div>
                        }
                    </div>}
            </React.Fragment>);
    }
}

export default Tovar;