import React from "react";

class TableComponent extends React.Component
{
    render()
    {
        return (
            <div className="d-flex flex-column col-12">
                {this.props.children.map((stroka, index) =>
                    <div
                        key={index}
                        className={"d-flex flex-row gap-0 p-0" + (index !== this.props.children.length - 1 ? " border-bottom" : "")}
                    >
                        {stroka}
                    </div>
                )}
            </div>
        );
    }
}

export default TableComponent;
