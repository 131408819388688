import React from "react";
import Pole from "../class/Pole";
import OtkrComponent from "../components/otkrComponent";
import SpisokComponent from "../components/spisokComponent";
import SsylkaComponent from "../components/ssylkaComponent";
import Obertka from "./Obertka";
import { Building, CaretDownFill, CaretUpFill, GeoAlt, Link45deg, ThreeDotsVertical, Trash3 } from "react-bootstrap-icons";
import preobr_url from "../func/preobr_url";
import MenuComponent from "../components/menuComponent";
import MenuItemComponent from "../components/menuItemComponent";
import PoleProizvType from "./PoleProizvType";
import WarningComponent from "../components/warningComponent";

class PloshInSpisok extends React.Component
{
    render()
    {
        if (this.props.redSost)
        {
            let menu = <MenuComponent>
                <MenuItemComponent
                    onClick={this.props.onDelete}
                    className="text-danger"
                >
                    <Trash3 /> Удалить площадку
                </MenuItemComponent>
            </MenuComponent>;
            let zagolovok = this.props.item.nazv;
            return (
                <OtkrComponent
                    zagolovok={zagolovok}
                    validError={this.props.item.nazv === ""}
                    menu={menu}
                    icon={<Building />}
                >
                    <div className="d-flex flex-row gap-0">
                        <div className="d-flex flex-column gap-1 p-1 col-12">
                            <Obertka text="Название" redStyle={true} validError={this.props.item.nazv === ""}>
                                <input type="text" placeholder="Название" value={this.props.item.nazv} className="form-control" onChange={(e) => this.props.onChange("nazv", e.target.value)} />
                            </Obertka>
                            <Obertka text="Адрес" redStyle={true} validError={this.props.item.address === ""}>
                                <input type="text" placeholder="Адрес" value={this.props.item.address} className="form-control" onChange={(e) => this.props.onChange("address", e.target.value)} />
                            </Obertka>
                            <Obertka text="Координаты (Яндекс)" redStyle={true} validError={this.props.item.koord === ""}>
                                <input type="text" placeholder="Координаты (Яндекс)" value={this.props.item.koord} className="form-control" onChange={(e) => this.props.onChange("koord", e.target.value)} />
                            </Obertka>
                        </div>
                    </div>
                </OtkrComponent >
            );
        }
        else
        {
            return <div className="d-flex flex-column gap-1">
                <div className="fw-medium">
                    {this.props.item.nazv}
                </div>
                <small>
                    {this.props.item.address}
                </small>
            </div>;
        }
    }
}

class PolePloshProizv extends Pole
{
    static pole = "proizv_plosh";

    static defaultValue = [];

    static getValidError(db, object)
    {
        if (object[this.pole].some(x => x.nazv === "" || x.address === "" || x.koord === ""))
        {
            return true;
        }
        if (!object[PoleProizvType.pole].includes("zavod") && object[this.pole].length > 0)
        {
            return true;
        }
        return false;
    };

    render()
    {
        let pole = this.constructor.pole;
        if (this.props.redSost)
        {
            let zagolovok = <small className="fst-italic text-muted">Нет площадок</small>;
            if (this.props.object[pole].length > 0)
            {
                zagolovok = <React.Fragment>Площадок: {this.props.object[pole].length}</React.Fragment>;
            }
            if (!this.props.object[PoleProizvType.pole].includes("zavod"))
            {
                zagolovok = <small className="fst-italic text-muted">Некорректный тип</small>;
            }
            let warn = null;
            if (this.props.objectIsh[pole].some(x => !this.props.object[pole].some(y => y.time === x.time)))
            {
                warn = <WarningComponent
                    zagolovok="Площадка удалена!"
                    text="Данные будут утеряны"
                />;
            }
            return (
                <Obertka
                    text="Площадки"
                    icon={<GeoAlt />}
                    validError={this.constructor.getValidError(this.props.db, this.props.object)}
                    {...this.props}
                >
                    {warn}
                    <OtkrComponent
                        zagolovok={zagolovok}
                        validError={this.constructor.getValidError(this.props.db, this.props.object)}
                        pusto={this.props.object[pole].length === 0 && !this.props.object[PoleProizvType.pole].includes("zavod")}
                    >
                        <SpisokComponent
                            plusClick={() =>
                            {
                                let spisok = this.props.object[pole];
                                spisok.unshift({
                                    time: Date.now(),
                                    nazv: "",
                                    address: "",
                                    koord: "",
                                });
                                this.props.onChangePole(pole, spisok);
                            }}
                            plusText="Добавить площадку"
                            items={this.props.object[pole]}
                            otrisovka={(item2) =>
                                <PloshInSpisok
                                    item={item2}
                                    key={item2.time}
                                    onChange={(pole2, value) =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylka = spisok.find(x => x.time === item2.time);
                                        ssylka[pole2] = value;
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    onDelete={() =>
                                    {
                                        let spisok = this.props.object[pole];
                                        let ssylkaIndex = spisok.findIndex(x => x.time === item2.time);
                                        spisok.splice(ssylkaIndex, 1);
                                        this.props.onChangePole(pole, spisok);
                                    }}
                                    {...this.props}
                                />
                            }
                        />
                    </OtkrComponent>
                </Obertka>
            );
        }
        else
        {
            if (this.props.object[pole].length === 0)
            {
                return null;
            }
            return (
                <Obertka text="Площадки" {...this.props}>
                    {this.props.object[pole].sort((a, b) => a.nazv.localeCompare(b.nazv)).map((item, index) =>
                        <PloshInSpisok key={index} item={item} {...this.props} />
                    )}
                </Obertka>
            );
        }
    }
}

export default PolePloshProizv;