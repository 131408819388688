import React from "react";
import Obertka from "./Obertka";
import SelectComponent from "../components/selectComponent";
import PoleType from "../class/PoleType";
import id_en_to_rus from "../func/id_en_to_rus";
import { Link } from "react-router-dom";
import RedVariant from "../red_variant";
import PoleZnachObertka from "./PoleZnachObertka";

class PoleTypeArray extends PoleType
{
    static razd = ", ";
    static gap = "0";

    static getRedPodpis(pole)
    {
        if (pole.multiSelect)
        {
            return "Несколько вариантов";
        }
        else
        {
            return "Один вариант";
        }
    }

    /*static getTextValue(pole, db)
    {
        //console.log("поле", pole);
        if (pole.deleted)
        {
            return "Было выбрано: " + pole.dataInKart.value.length;
        }
        let serverVariants = pole.array;
        let currentVariantsID = pole.dataInKart.value;
        //console.log("варианты поля", serverVariants);
        //console.log("выбранные варианты (ИД)", currentVariantsID);
        let currentVariants = currentVariantsID.map(time => serverVariants.find(variant => variant.time === time));

        //console.log("выбранные варианты", currentVariants);
        function getText(item)
        {
            switch (item.type)
            {
                case "link":
                    return item.name;
                case "tovar":
                    let tovar = db.catalog.find(x => x._id === item.tovar);
                    return tovar.nazv;
                case "text":
                    return item.name;
                default:
                    return "";
            }
        }
        return currentVariants.filter(v => v !== undefined).filter(v => v.visible).map(v => getText(v)).join(", ");
    }*/

    /*static getHTML(pole, db)
    {
        function variantToHTML(variant, db)
        {
            switch (variant.type)
            {
                case "link":
                    return <a href={variant.link}>{variant.name}</a>;
                case "tovar":
                    let tovar = db.catalog.find(x => x._id === variant.tovar);
                    let tovar_id = variant.tovar;
                    let tovar_nazv = tovar.nazv
                    return <Link to={"/каталог/" + id_en_to_rus(tovar_id)}>{tovar_nazv}</Link>;
                case "text":
                    return variant.name;
                default:
                    return "";
            }
        }
        let rez = pole.array
            .filter(variant => pole.dataInKomp
                .some(komp => komp.value
                    .some(variantInKomp => variantInKomp === variant.time)
                )
            );
        rez = rez
            .map(variant => variantToHTML(variant, db));
        return rez;
    }*/

    static getDefaultValue = () => [];

    static getValidError(pole)
    {
        if (pole.deleted)
        {
            return true;
        }
        if (pole.dataInKart !== undefined && pole.dataInKart !== null)
        {
            if (pole.dataInKart.value.length === 0)
            {
                return true;
            }
        }
        else
        {
            if (pole.dataInKomp.some(x => x.value.length === 0))
            {
                return true;
            }
        }
        return false;
    }

    static preobr_object(db, object)
    {
        return super.preobr_object(db, object, (dbPole, objectPole) =>
        {
            if (dbPole.type === "array")
            {
                if (objectPole.type === "array")
                {
                    let db_multiSelect = dbPole.multiSelect;
                    objectPole.value = objectPole.value.filter(time => dbPole.array.some(x => x.time === time));
                    let currentVariantsID = objectPole.value;
                    if (currentVariantsID.length > 1 && db_multiSelect === false)
                    {
                        objectPole.value = [objectPole.value[0]];
                    }
                    let currentVariants = objectPole.value.map(x => dbPole.array.find(y => y.time === x));
                    for (let i in currentVariants)
                    {
                        let variant = currentVariants[i];
                        if (variant.type === "tovar")
                        {
                            let tovar = db.catalog.filter(x => x.show).find(tovar => tovar._id === variant.tovar);
                            if (tovar === undefined)
                            {
                                objectPole.value = objectPole.value.filter(x => x !== variant.time);
                            }
                        }
                    }
                }
                else if (objectPole.type === "text" || objectPole.type === "number" || objectPole.type === "link" || objectPole.type === "tovar")
                {
                    let db_multiSelect = dbPole.multiSelect;
                    let serverVariants = dbPole.array;
                    function findVariant(text, serverVariants)
                    {
                        text = text.toString();
                        function sravn(variant, text)
                        {
                            if (variant.type === "text" || variant.type === "link")
                            {
                                return variant.name.trim().toLowerCase() === text.trim().toLowerCase();
                            }
                            else if (variant.type === "tovar")
                            {
                                if (variant.tovar === text.trim().toLowerCase())
                                {
                                    return true;
                                }
                                else
                                {
                                    let tovar = db.catalog.find(x => x._id === variant.tovar && x.show);
                                    if (tovar === undefined)
                                    {
                                        return false;
                                    }
                                    else
                                    {
                                        return tovar.nazv.trim().toLowerCase() === text.trim().toLowerCase();
                                    }
                                }
                            }
                        }
                        let variant = serverVariants.filter(x => x.active).find(variant => sravn(variant, text));
                        if (variant)
                        {
                            return variant.time;
                        }
                        else
                        {
                            return null;
                        }
                    }
                    if (objectPole.type === "link")
                    {
                        objectPole.value = objectPole.value.text;
                    }
                    else if (objectPole.type === "tovar")
                    {
                        let tovar = db.catalog.find(tovar => tovar._id === objectPole.value);
                        if (tovar === undefined)
                        {
                            objectPole.value = "";
                        }
                        else
                        {
                            objectPole.value = tovar.nazv;
                        }
                    }
                    if (findVariant(objectPole.value, serverVariants) !== null)
                    {
                        objectPole.value = [findVariant(objectPole.value, serverVariants)];
                    }
                    else
                    {
                        let text_array = objectPole.value.split(",");
                        objectPole.value = text_array.map(text => findVariant(text, serverVariants)).filter(time => time !== null);
                        if (objectPole.value.length > 1 && db_multiSelect === false)
                        {
                            objectPole.value = [objectPole.value[0]];
                        }
                    }
                }
                objectPole.type = "array";
            }
            return object;
        });
    }

    constructor(props)
    {
        super(props);
        this.getText = this.getText.bind(this);
    }

    getText(item)
    {
        switch (item.type)
        {
            case "link":
                return item.name;
            case "tovar":
                let tovar = this.props.db.catalog.find(x => x._id === item.tovar);
                return tovar.nazv;
            case "text":
                return item.name;
            default:
                return "";
        }
    }

    redRender()
    {
        let currentVariantsID = this.constructor.getDefaultValue();
        if (this.props.znach !== undefined)
        {
            currentVariantsID = this.props.znach;
        }
        let pole = this.props.pole;
        let variantsArray = pole.array;
        variantsArray = variantsArray
            .map(variant => ({
                ...variant,
                selected: currentVariantsID.includes(variant.time),
            }));
        variantsArray = [
            ...currentVariantsID
                .filter(variantID => !variantsArray
                    .find(variant => variant.time === variantID)
                )
                .map(variantID => ({
                    time: variantID,
                    name: "Удалённый вариант #" + variantID,
                    type: "text",
                    active: true,
                    visible: true,
                })),
            ...variantsArray,
        ];
        variantsArray = variantsArray
            .filter(variant => variant.active || variant.selected);
        return (
            <div className="d-flex flex-column col-12">
                <SelectComponent
                    items={variantsArray}
                    getText={this.getText}
                    getID={variant => variant.time}
                    isSelected={variant => variant.selected}
                    isArchive={variant => !variant.active}
                    multiSelect={this.props.pole.multiSelect}
                    getIcon={variant => RedVariant.getVariantTypeIcon(variant.type)}
                    onClick={variant =>
                    {
                        if (currentVariantsID.includes(variant.time))
                        {
                            currentVariantsID = currentVariantsID.filter(id => id !== variant.time);
                        }
                        else
                        {
                            if (this.props.pole.multiSelect)
                            {
                                currentVariantsID.push(variant.time);
                            }
                            else
                            {
                                currentVariantsID = [variant.time];
                            }
                        }
                        if(currentVariantsID.length > 0)
                        {
                            this.props.onChange(currentVariantsID);
                        }
                        else
                        {
                            this.props.onChange(undefined);
                        }
                    }}
                />
            </div>
        );
    }

    prosmRender()
    {
        let znachArray = this.props.znachArray;
        let pole = this.props.pole;
        let db = this.props.db;
        function renderVariant(variant)
        {
            switch (variant.type)
            {
                case "link":
                    return (
                        <a
                            href={variant.link}
                            target="_blank"
                            className="link"
                            rel="noreferrer"
                        >
                            {variant.name}
                        </a>
                    );
                case "tovar":
                    let tovar = db.catalog.find(x => x._id === variant.tovar);
                    let tovar_id = variant.tovar;
                    let tovar_nazv = tovar.nazv
                    return (
                        <a
                            href={"/каталог/" + id_en_to_rus(tovar_id)}
                            className="link"
                        >
                            {tovar_nazv}
                        </a>
                    );
                case "text":
                    return variant.name;
                default:
                    return "";
            }
        };
        znachArray = znachArray.map(znach => znach.value);
        let currentVariants = pole.array
            .filter(variant => variant.visible)
            .filter(variant => znachArray
                .some(znach => znach.includes(variant.time))
            )
            .map((variant, index) =>
                <React.Fragment
                    key={index}
                >
                    {renderVariant(variant)}
                </React.Fragment>
            );
        return (
            <PoleZnachObertka
                poleClass={this.constructor}
            >
                {currentVariants}
            </PoleZnachObertka>
        );
    }

    /*render()
    {
        let serverVariants = this.props.pole.array;
        let currentVariantsID = []
        if (this.props.pole.dataInKart !== null)
        {
            currentVariantsID = this.props.pole.dataInKart.value;
        }
        return (
            <Obertka
                redStyle={true}
                validError={this.constructor.getValidError(this.props.pole)}
                text={this.props.pole.multiSelect ? "Несколько вариантов" : "Один вариант"}
            >
                <SelectComponent
                    items={serverVariants}
                    getText={this.getText}
                    getID={item => item.time}
                    isSelected={item => currentVariantsID.includes(item.time)}
                    isArchive={item => !item.active}
                    multiSelect={this.props.pole.multiSelect}
                    validError={currentVariantsID.length === 0}
                    onClick={item =>
                    {
                        if (currentVariantsID.includes(item.time))
                        {
                            currentVariantsID = currentVariantsID.filter(id => id !== item.time);
                        }
                        else
                        {
                            if (this.props.pole.multiSelect)
                            {
                                currentVariantsID.push(item.time);
                            }
                            else
                            {
                                currentVariantsID = [item.time];
                            }
                        }
                        this.props.changeValue(currentVariantsID);
                    }}
                    getIcon={item => RedVariant.getVariantTypeIcon(item.type)}
                />
            </Obertka>
        );
    }*/
}

export default PoleTypeArray;