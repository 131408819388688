import React from 'react';
import { ExclamationCircle } from 'react-bootstrap-icons';

class WarningComponent extends React.Component
{
    render()
    {
        return (
            <div className="d-flex gap-2 flex-row">
                <div className="p-1">
                    <ExclamationCircle size="1.2rem" className="text-danger" />
                </div>
                <small className="text-danger d-flex flex-column gap-1">
                    <div className='fw-bold'>{this.props.zagolovok}</div>
                    <div>{this.props.text}</div>
                </small>
            </div>
        );
    }
}

export default WarningComponent;