import React from 'react';
import Obertka from './Obertka';
import Pole from '../class/Pole';
import { ArrowRepeat, CaretDownFill, CaretUpFill, Cloud, CloudArrowUp, Hdd, Image, Images, PlusLg, Repeat, Type, XLg } from 'react-bootstrap-icons';
import OtkrComponent from '../components/otkrComponent';
import SpisokComponent from '../components/spisokComponent';
import Photo from '../photo';
import MenuItemComponent from '../components/menuItemComponent';
import MenuComponent from '../components/menuComponent';
import PolePhoto from './PolePhoto';
import PoleFiles from './PoleFiles';
import PoleBrandsProizv from './PoleBrandsProizv';

class FileInFileManager extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            otkr: false
        };
    }
    render()
    {
        let zagolovok = this.props.item.split(".").slice(1).join(".");
        let fileID = this.props.item.split(".")[0];
        let inFiles = this.props.object[PoleFiles.pole].some(item => item.file === fileID);
        let inPhoto = this.props.object[PolePhoto.pole].some(item => item.file === fileID);
        if (this.props.object[PolePhoto.pole].some(item => item.file === fileID))
        {
            inPhoto = true;
        }
        return (
            <OtkrComponent
                zagolovok={zagolovok}
                iconPhoto={<Photo mode="brand" file={this.props.item} adress={this.props.adress} />}
            >
                <div className="d-flex flex-row col-12 flex-wrap">
                    <button
                        className={"d-flex btn p-0 " + (this.state.otkr ? "col-12" : "col-3")}
                        onClick={() => this.setState({ otkr: !this.state.otkr })}
                    >
                        <Photo
                            mode="brand"
                            file={this.props.item}
                            adress={this.props.adress}
                            full={this.state.otkr === true}
                        />
                    </button>
                    <div className={"d-flex flex-column" + (this.state.otkr ? " col-12" : " col-9")}>
                        <Obertka
                            text="Файл"
                            redStyle={true}
                        >
                            {zagolovok}
                        </Obertka>
                        <Obertka
                            text="Использование"
                            redStyle={true}
                        >
                            <button
                                type="button"
                                className={"btn d-flex flex-row gap-1 align-items-center justify-content-center " + (inPhoto ? "btn-outline-danger" : "btn-outline-primary")}
                                onClick={() =>
                                {
                                    let photo_object = this.props.object[PolePhoto.pole];
                                    if (inPhoto)
                                    {
                                        photo_object = photo_object.filter(item => item.file !== fileID);
                                    }
                                    else
                                    {
                                        photo_object.push(PolePhoto.getDefaultPhotoValue(fileID));
                                    }
                                    this.props.onChangePole(PolePhoto.pole, photo_object);
                                }}
                            >
                                {inPhoto ? <XLg /> : <PlusLg />} Фото
                            </button>
                            <button
                                type="button"
                                className={"btn d-flex flex-row gap-1 align-items-center justify-content-center " + (inFiles ? "btn-outline-danger" : "btn-outline-primary")}
                                onClick={() =>
                                {
                                    let files_object = this.props.object[PoleFiles.pole];
                                    if (inFiles)
                                    {
                                        files_object = files_object.filter(item => item.file !== fileID);
                                    }
                                    else
                                    {
                                        files_object.push({ file: fileID, podpis: zagolovok });
                                    }
                                    this.props.onChangePole(PoleFiles.pole, files_object);
                                }}
                            >
                                {inFiles ? <XLg /> : <PlusLg />} Файл
                            </button>
                        </Obertka>
                        {this.props.proizvMode === true &&
                            <Obertka
                                text="Логотипы брендов"
                                redStyle={true}
                            >
                                <OtkrComponent
                                    zagolovok={"Используется: " + this.props.object[PoleBrandsProizv.pole].filter(brand => brand.photo === fileID).length}
                                >
                                    {this.props.object[PoleBrandsProizv.pole].map(brand =>
                                        <button
                                            key={brand.time}
                                            type="button"
                                            className={"btn d-flex flex-row gap-1 align-items-center justify-content-center " + (brand.photo === fileID ? "btn-outline-danger" : (brand.photo === null ? "btn-outline-primary" : "btn-outline-warning text-dark"))}
                                            onClick={() =>
                                            {
                                                let brands = this.props.object[PoleBrandsProizv.pole];
                                                let t_brand = brands.find(item => item.time === brand.time);
                                                if (t_brand.photo === fileID)
                                                {
                                                    t_brand.photo = null;
                                                }
                                                else
                                                {
                                                    t_brand.photo = fileID;
                                                }
                                                this.props.onChangePole(PoleBrandsProizv.pole, brands);
                                            }}
                                        >
                                            {brand.photo === fileID ? <XLg /> : (brand.photo === null ? <PlusLg /> : <ArrowRepeat />)} {brand.nazv}
                                        </button>
                                    )}
                                </OtkrComponent>
                            </Obertka>
                        }
                    </div>
                </div>
            </OtkrComponent>
        );
    }
}

class PoleFileManager extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            files: [],
            zagr: false,
        };
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    async uploadFiles()
    {
        this.setState({ zagr: true });
        let f = await this.props.uploadFiles(this.state.files);
        this.setState({ zagr: false, files: [] });

    }

    render()
    {
        if (!this.props.redSost)
        {
            return null;
        }
        let zagolovok = <small className="fst-italic">Нет файлов</small>;
        let files = Object.values(this.props.serverFiles);
        if (files.length > 0)
        {
            zagolovok = "Файлов: " + files.length;
        }
        return (
            <Obertka text="Файлы" icon={<Hdd />} validError={false} {...this.props}>
                <Obertka redStyle={true}>
                    <OtkrComponent
                        zagolovok="Загрузка файлов"
                        icon={<CloudArrowUp />}
                    >
                        <input className="form-control" type="file" onChange={(e) => this.setState({ files: e.target.files })} files={this.state.files} multiple />
                        {this.state.zagr === false &&
                            <button className="btn col-12 btn-primary" onClick={this.uploadFiles} disabled={this.state.files.length === 0}>
                                Загрузить
                            </button>
                        }
                        {this.state.zagr === true &&
                            <button type="button" className="btn col-12 btn-primary" disabled={true}>
                                <div className="spinner-border container" role="status">
                                    <span className="visually-hidden">Загрузка...</span>
                                </div>
                            </button>
                        }
                    </OtkrComponent>
                </Obertka>
                <Obertka redStyle={true} text="Файлы на сервере">
                    <OtkrComponent
                        zagolovok={zagolovok}
                        pusto={files.length === 0}
                        icon={<Cloud />}
                    >
                        <SpisokComponent
                            items={files}
                            otrisovka={fileItem =>
                                <FileInFileManager
                                    item={fileItem}
                                    key={fileItem}
                                    {...this.props}
                                />
                            }
                            getText={fileItem => fileItem.split(".").slice(1).join(".")}
                        />
                    </OtkrComponent>
                </Obertka>
            </Obertka>
        );
    }
}

export default PoleFileManager;