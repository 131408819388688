import React from 'react';
import { Archive, Eye, EyeSlash, Folder, ListUl, Trash3 } from 'react-bootstrap-icons';
import RedPole from './red_pole';
import MenuComponent from './components/menuComponent';
import MenuItemComponent from './components/menuItemComponent';
import WarningComponent from './components/warningComponent';
import OtkrComponent from './components/otkrComponent';
import Obertka from './polya/Obertka';
import SpisokComponent from './components/spisokComponent';

class RedGroup extends React.Component
{
    static preobr_db(group, db)
    {
        for(let i in group.polya)
        {
            let pole = group.polya[i];
            db = RedPole.preobr_db(pole,group,db);
        }
        return db;
    }

    static getDefaultValue()
    {
        return {
            name: "",
            time: Date.now().toString(),
            polya: [],
            active: true,
            visible: true,
        }
    }

    static getValidError(item)
    {
        if (item.name === "")
        {
            return true;
        }
        if (item.polya.every(x => !x.active))
        {
            return true;
        }
        if (item.polya.some(x => RedPole.getValidError(x)))
        {
            return true;
        }
        return false;
    }

    constructor(props)
    {
        super(props);
        this.onArchive = this.onArchive.bind(this);
        this.onUnArchive = this.onUnArchive.bind(this);
        this.toggleVisible = this.toggleVisible.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.plusClick = this.plusClick.bind(this);
        this.otrisovka = this.otrisovka.bind(this);
    }

    onArchive()
    {
        let data = this.props.data;
        let v = data[this.props.group_index];
        v.active = false;
        v.visible = true;
        data.splice(this.props.group_index, 1);
        data.push(v);
        this.props.setData(data);
    }

    toggleVisible()
    {
        let data = this.props.data;
        data[this.props.group_index].visible = !data[this.props.group_index].visible;
        this.props.setData(data);
    }

    onUnArchive()
    {
        let data = this.props.data;
        let v = data[this.props.group_index];
        v.active = true;
        v.visible = true;
        data.splice(this.props.group_index, 1);
        data.splice(0, 0, v);
        this.props.setData(data);
    }

    onDelete()
    {
        let data = this.props.data;
        data.splice(this.props.group_index, 1);
        this.props.setData(data);
    }

    plusClick()
    {
        let data = this.props.data;
        data[this.props.group_index].polya.unshift(RedPole.getDefaultValue())
        this.props.setData(data);
    }

    otrisovka(item)
    {
        return <RedPole
            key={item.time}
            pole={item}
            data={this.props.data}
            setData={this.props.setData}
            pole_index={this.props.group.polya.findIndex(x => x.time === item.time)}
            group_index={this.props.group_index}
            oldData={this.props.oldData}
            group={this.props.group}
            catalog={this.props.catalog}
        />;
    }

    render()
    {
        let warnArchive = null;
        let warnDelete = null;
        let group = this.props.group;
        let oldGroup = this.props.oldData.find(x => x.time === this.props.group.time);
        if (oldGroup !== undefined)
        {
            group.polya.forEach(pole =>
            {
                let oldPole = oldGroup.polya.find(x => x.time === pole.time);
                if (oldPole !== undefined)
                {
                    if (pole.active === false && oldPole.active === true)
                    {
                        warnArchive = <WarningComponent
                            zagolovok="Поле архивировано!"
                            text="Выбор поля в категориях будет невозможен"
                        />;
                    }
                }
            });
            if (oldGroup.polya.some(x => !group.polya.some(y => x.time === y.time)))
            {
                warnDelete = <WarningComponent
                    zagolovok="Поле удалено!"
                    text="Введённые данные будут недоступны при просмотре"
                />;
            }
        }

        let menu_active = <MenuComponent>
            <MenuItemComponent
                className="text-danger"
                onClick={this.onArchive}
            >
                <Archive /> Архивировать группу
            </MenuItemComponent>
        </MenuComponent>;
        let menu_archive = <MenuComponent>
            <MenuItemComponent
                onClick={this.toggleVisible}
            >
                {this.props.data[this.props.group_index].visible === true &&
                    <React.Fragment>
                        <EyeSlash /> Скрыть группу
                    </React.Fragment>
                }
                {this.props.data[this.props.group_index].visible === false &&
                    <React.Fragment>
                        <Eye /> Показать группу
                    </React.Fragment>
                }
            </MenuItemComponent>
            <MenuItemComponent
                className="text-primary"
                onClick={this.onUnArchive}
            >
                <Archive /> Восстановить группу
            </MenuItemComponent>
            <MenuItemComponent
                className="text-danger"
                onClick={this.onDelete}
            >
                <Trash3 /> Удалить группу
            </MenuItemComponent>
        </MenuComponent>;
        let currentCategoies = this.props.categories.filter(x => x.groups.some(y => y.time === this.props.group.time));
        return (
            <OtkrComponent
                icon={<ListUl />}
                zagolovok={this.props.group.name}
                menu={this.props.group.active ? menu_active : menu_archive}
                icon2={this.props.group.active ? null : (this.props.group.visible ? <Eye /> : <EyeSlash />)}
                validError={this.constructor.getValidError(this.props.group)}
            >
                {warnArchive}
                {warnDelete}
                <Obertka
                    text="Название"
                    redStyle={true}
                    validError={this.props.group.name === ""}
                >
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Название"
                        value={this.props.group.name}
                        onChange={(e) =>
                        {
                            let data = this.props.data;
                            data[this.props.group_index].name = e.target.value;
                            this.props.setData(data);
                        }}
                    />
                </Obertka>
                <Obertka
                    text="Поля"
                    redStyle={true}
                    validError={this.props.group.polya.filter(x => x.active).length === 0}
                >
                    <SpisokComponent
                        items={this.props.group.polya.filter(x => x.active)}
                        getID={(item) => item.time}
                        getText={(item) => item.name}
                        plusText="Добавить поле"
                        plusClick={this.plusClick}
                        otrisovka={this.otrisovka}
                    />
                </Obertka>
                <OtkrComponent
                    icon={<Archive />}
                    zagolovok={this.props.group.polya.some(x => !x.active) ? ("Архивные поля: " + this.props.group.polya.filter(x => !x.active).length) : <small className="fst-italic text-muted">Архивные поля отсутствуют</small>}
                    pusto={this.props.group.polya.every(x => x.active)}
                >
                    <SpisokComponent
                        items={this.props.group.polya.filter(x => !x.active)}
                        getID={(item) => item.time}
                        getText={(item) => item.name}
                        otrisovka={this.otrisovka}
                    />
                </OtkrComponent>
                <Obertka
                    text="В категориях"
                    redStyle={true}
                >
                    <OtkrComponent
                        icon={<Folder />}
                        zagolovok={currentCategoies.length === 0 ? <small className="fst-italic text-muted">Группа не включена в категории</small> : ("В категориях: " + currentCategoies.length)}
                        pusto={currentCategoies.length === 0}
                    >
                        <SpisokComponent
                            items={currentCategoies}
                            getID={(item) => item.time}
                            getText={(item) => item.name}
                            otrisovka={(item) =>
                                <OtkrComponent
                                    icon={<Folder />}
                                    zagolovok={item.name}
                                    bez_otkr={true}
                                    key={item.time}
                                />
                            }
                        />
                    </OtkrComponent>
                </Obertka>
            </OtkrComponent>
        );
    }
}

export default RedGroup;